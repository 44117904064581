import { useState, useEffect } from "react";
import TeacherProfile from "../components/TeacherProfile";

const TeacherPage = () => {
  const [tutorId, setTutorId] = useState();

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    console.log(params);
    setTutorId(params.get("id"));
  }, []);
  if (!tutorId) {
    return "Cargando...";
  }
  return <TeacherProfile tutorId={tutorId} />;
};
export default TeacherPage;
