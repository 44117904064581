import React, { useState } from "react";
// import { CalendarModal, ReportClassModal } from "../../components";
import CalendarModal from "../../components/CalendarModal/CalendarModal";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import {
  Button,
  Text,
  useDisclosure,
  Stack,
  Tooltip,
  Box,
  Tag,
  VStack,
  Flex,
} from "@chakra-ui/react";
import RateClassModal from "./RateClassModal";
import { useSelector } from "react-redux";
import StarRating from "../../components/StarRating";
import ClassFeedbackModal from "./ClassFeedbackModal";
import SendTutorFeedbackModal from "./SendTutorFeedbackModal";
import ViewTutorFeedbackModal from "./ViewTutorFeedbackModal";
const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Box p={1}>
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  </Box>
));

const PastClassModalTutor = ({
  event,
  subjectInfo,
  tutorInfo,
  classInfo,
  subjectDescription,
  timeDescription,
}) => {
  const { userInfo } = useSelector((state) => state.user);
  const [submittedRating, setSubmittedRating] = useState(0);
  const [tempSentFeedback, setTempSentFeedback] = useState(false);

  const {
    isOpen: isOpenStudentFeedback,
    onOpen: onOpenStudentFeedback,
    onClose: onCloseStudentFeedback,
  } = useDisclosure();

  const {
    isOpen: isOpenSendTutorFeedback,
    onOpen: onOpenSendTutorFeedback,
    onClose: onCloseSendTutorFeedback,
  } = useDisclosure();

  const {
    isOpen: isOpenViewTutorFeedback,
    onOpen: onOpenViewTutorFeedback,
    onClose: onCloseViewTutorFeedback,
  } = useDisclosure();

  if (!userInfo) {
    return "Cargando...";
  }

  // Each feedback has sender, text and rating
  const getClassRating = () => {
    //Gets the average of all feedbacks.
    let feedbacks = classInfo.individualStudentFeedback;
    if (!feedbacks || feedbacks.length === 0) {
      return null;
    }

    let ratings = feedbacks.map((feedback) => feedback.rating);
    let sum = ratings.reduce((partialSum, a) => partialSum + a, 0);
    let avg = sum / ratings.length;
    return Math.round(avg); //Since #starts should be an integer
  };

  return (
    <Flex style={{ margin: 20 }} flexDirection="column" alignItems="center">
      <ClassFeedbackModal
        foundClass={classInfo}
        isOpen={isOpenStudentFeedback}
        onOpen={onOpenStudentFeedback}
        onClose={onCloseStudentFeedback}
      />
      <SendTutorFeedbackModal
        isOpen={isOpenSendTutorFeedback}
        onOpen={onOpenSendTutorFeedback}
        onClose={onCloseSendTutorFeedback}
        foundClass={classInfo}
        onFeedbackSent={() => {
          setTempSentFeedback(true);
        }}
      />
      <ViewTutorFeedbackModal
        isOpen={isOpenViewTutorFeedback}
        onOpen={onOpenViewTutorFeedback}
        onClose={onCloseViewTutorFeedback}
        tutorFeedback={classInfo.tutorFeedback}
      />
      {/* <Stack direction gap={3}>
        <Button backgroundColor={"#0ED37B"} color={"white"} onClick={onOpen}>
          <Text>Cuéntanos cómo te fue!</Text>
        </Button>

      </Stack> */}
      <Flex alignItems={"center"}>
        {classInfo.tutorFeedback ? (
          <Button color="white" bg="#07A58E" onClick={onOpenViewTutorFeedback}>
            Ver reporte
          </Button>
        ) : tempSentFeedback ? (
          <Text color="#07A58E" alignSelf={{ base: "none", md: "center" }}>
            Gracias por mandar tu feedback. Para verlo puede refrescar la
            página.
          </Text>
        ) : (
          <Button color="white" bg="#07A58E" onClick={onOpenSendTutorFeedback}>
            Mandar feedback para el estudiante
          </Button>
        )}
      </Flex>
      {getClassRating() ? (
        <VStack padding={5} margin={10}>
          <Tooltip label="Puntuación que recibiste">
            <CustomCard colorScheme="whiteAlpha">
              <StarRating
                size={{ base: 4, md: 4, lg: 5 }}
                icon="star"
                scale={5}
                fillColor="gold"
                strokeColor="grey"
                rating={submittedRating || getClassRating()}
                setRating={(val) => {}} //static
              />
            </CustomCard>
          </Tooltip>
          <Button onClick={onOpenStudentFeedback}>
            Ver feedback completo de los estudiantes.
          </Button>
        </VStack>
      ) : (
        <Text>
          Todavía no ha recibido feedback de los estudiantes. Este al tanto!
        </Text>
      )}
    </Flex>
  );
};

export default PastClassModalTutor;
