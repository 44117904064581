import React, { useState, useEffect } from "react";
import { Center, Flex, Text } from "@chakra-ui/react";
import { getClass, getStudents } from "../../utils/ApiConfig";
import { extractMoreInfoFromClass } from "../../utils/functions";
import RateClassModal from "../Clases/RateClassModal";
import SelectStudent from "../Clases/SelectStudent";
// import { SelectComponent } from "../../components";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
// import StarRating from "../../components/StarRating";

const StudentFeedbackPage = () => {
  const [loading, setLoading] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [foundClass, setFoundClass] = useState({});
  const [students, setStudents] = useState([]);
  const [submittedRating, setSubmittedRating] = useState(0);

  useEffect(() => {
    async function get_info() {
      let params = new URLSearchParams(window.location.search);
      let id = params.get("id");
      let cls = await getClass(id);
      console.log(cls);
      if (cls?._id) {
        let fullClass = await extractMoreInfoFromClass(cls);
        setFoundClass(fullClass);
        let students = await getStudents({
          _id: { $in: fullClass.class.studentIds },
        });
        setStudents(students);
        setLoading(false);

        if (students.length === 1) {
          setSelectedStudent(students[0]);
        }
      }
    }
    get_info();
  }, []);
  if (loading) {
    return "Cargando...";
  }
  const getUserFeedback = (user) => {
    console.log(foundClass.class.individualStudentFeedback);
    let feedback = foundClass.class.individualStudentFeedback.filter(
      (feedback) => feedback.sender._id === user._id
    );
    console.log("feedbac");
    console.log(feedback);
    if (feedback && feedback.length > 0) {
      return feedback[0];
    }
    return null;
  };
  return (
    <Flex
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
      backgroundColor={"#fff"}
      height={"100%"}
    >
      {students.length > 1 && (
        <SelectComponent
          dataOptions={students.map((student) => ({
            label: student.name + " " + student.surname,
            value: student._id,
          }))}
          placeholder={"Seleccione estudiante quien está dando el feedback"}
          //   value={selectedStudent._id}
          setSelectedOptions={(opt) => {
            let foundStudent = students.filter(
              (student) => student._id === opt.value
            );
            if (foundStudent.length > 0) {
              setSubmittedRating(0); //reset
              setSelectedStudent(foundStudent[0]);
            }
          }}
        />
      )}
      {selectedStudent._id &&
        (submittedRating || getUserFeedback(selectedStudent) ? (
          <>
            <Text textAlign={"center"}>
              Muchas gracias por proveer tu valioso feedback! Gracias a ti
              podemos mejorar las clases en IBMasters.
            </Text>

            {/* <StarRating
              size={{ base: 4, md: 4, lg: 5 }}
              icon="star"
              scale={5}
              fillColor="gold"
              strokeColor="grey"
              rating={
                submittedRating || getUserFeedback(selectedStudent).rating
              }
              setRating={(val) => {}} //static
            /> */}
          </>
        ) : (
          <RateClassModal
            startOpen={students.length === 1}
            subjectInfo={foundClass.info.subjectInfo}
            tutorInfo={foundClass.info.tutorInfo}
            classInfo={foundClass.class}
            subjectDescription={foundClass.info.subjectDescription}
            timeDescription={foundClass.info.timeDescription}
            senderInfo={selectedStudent}
            onSubmitFeedback={({ rating }) => {
              setSubmittedRating(rating);
            }}
          />
        ))}
    </Flex>
  );
};
export default StudentFeedbackPage;
