import {
  Spinner,
  Stack,
  Text,
  Divider,
  Link,
  Wrap,
  Avatar,
  AvatarBadge,
  Button,
  HStack,
  Box,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUpIcon, ChevronDownIcon, StarIcon } from "@chakra-ui/icons";
import { getTutorDetail } from "../../../utils/ApiConfig";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getValidDriveLink } from "../../../utils";
import { MAIN_COLOR } from "../../../utils/constants";
function TutorCard({ id, status, hideStar }) {
  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tutorDetail, setTutorDetail] = useState(null);

  const onReserveClick = useCallback(() => {
    navigate(`/reserveclasses?id=${id}`);
  }, [navigate]);
  const onProfileClick = useCallback(() => {
    navigate(`/tutor?id=${id}`);
  }, [navigate]);

  useEffect(() => {
    async function getTutor() {
      setLoading(true);
      const response = await getTutorDetail(id);
      setTutorDetail(response?.data);
      setLoading(false);
    }
    getTutor();
  }, [id]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <Stack
        minH={{ base: "30vh", sm: "27vh", md: "19vh" }}
        justify={"start"}
        direction={"row"}
        p={6}
        rounded={"lg"}
        boxShadow={"lg"}
        bgColor={"white"}
        border={`3px solid ${MAIN_COLOR}`}
      >
        <Stack alignSelf={"center"}>
          <Avatar
            name={`${tutorDetail?.name} ${tutorDetail?.surname}`}
            src={getValidDriveLink(tutorDetail?.imageDriveLink)}
            size={{ base: "lg", md: "lg", lg: "xl" }}
          >
            {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
          </Avatar>
        </Stack>
        <Divider orientation="vertical" />
        <Stack w={"100%"}>
          <Stack
            justify={"space-between"}
            direction={{ base: "column", md: "row" }}
          >
            <Stack w={"85%"} flexWrap={"wrap"}>
              <Stack
                justify={"space-between"}
                direction={{ base: "column", md: "row" }}
                gap={{ base: 0, sm: 1, md: 3, lg: 10 }}
              >
                <Text
                  fontWeight={500}
                  fontSize={{
                    base: "80%",
                    md: "90%",
                    lg: "100%",
                    xl: "110%",
                  }}
                >
                  {`${tutorDetail?.name} ${tutorDetail?.surname}`}{" "}
                  {!hideStar && <StarIcon color="#FFD800" />}
                </Text>
                <Text
                  fontSize={{
                    base: "60%",
                    md: "65%",
                    lg: "70%",
                    xl: "80%",
                  }}
                >
                  {status}
                </Text>
              </Stack>
              {/* <Flex flex={1} flexWrap="wrap" maxW={"50%"}> */}
              <Text
                fontSize={{
                  base: "60%",
                  md: "65%",
                  lg: "70%",
                  xl: "80%",
                }}
                color={MAIN_COLOR}
              >
                {tutorDetail?.tutorDescriptionList[0]}
              </Text>
              {/* </Flex> */}
              {/* <Text
                fontSize={{
                  base: "70%",
                  md: "75%",
                  lg: "80%",
                  xl: "90%",
                }}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {tutorDetail?.tutorDescriptionList[1]}
              </Text> */}

              <Box>
                <Text
                  fontSize={{
                    base: "90%",
                    md: "85%",
                    lg: "90%",
                    xl: "90%",
                  }}
                >
                  {tutorDetail?.tutorDescriptionList[1]}
                </Text>
              </Box>
            </Stack>
            {/* <ModalRating /> */}
            <Stack
              alignSelf={{ base: "end", md: "none" }}
              direction={{ base: "row", md: "column" }}
            >
              <Button
                onClick={onReserveClick}
                color={"white"}
                bgColor={MAIN_COLOR}
              >
                Reservar
              </Button>
              <Button onClick={onProfileClick}>Ver perfil</Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default TutorCard;
