import React, { useState, useEffect } from "react";
import {
  Stack,
  Link,
  FormLabel,
  Input,
  Text,
  Textarea,
  Avatar,
  AvatarBadge,
  WrapItem,
  Wrap,
  Button,
  HStack,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import PhoneNumberInput from "../../components/UserInfoModal/PhoneNumberInput";
import { COUNTRIES } from "../../components/UserInfoModal/countries";
import {
  updateStudent,
  updateParent,
  updateTutor,
} from "../../utils/ApiConfig";
import { MAIN_COLOR } from "../../utils/constants";

const countryOptions = COUNTRIES.map(({ name, iso }) => ({
  label: name,
  value: iso,
}));

const MyAccount = () => {
  const [controlState, setControlState] = useState(true);
  const { userInfo } = useSelector((state) => state.user);
  const onClickHandler = () => {
    setControlState(!controlState);
  };
  const [getCountryCode, setGetCountryCode] = useState("");

  /// Account state
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [extraInfo, setExtraInfo] = useState("");

  useEffect(() => {
    setName(userInfo?.name || "");
    setSurname(userInfo?.surname || "");
    setEmail(userInfo?.email || "");
    setPhone(userInfo?.phone || "");
    setExtraInfo(userInfo?.extraInfo || "");
  }, [userInfo]);

  const [isLoading, setIsLoading] = useState(false);

  const submitChanges = async () => {
    setIsLoading(true);
    let success = false;
    if (userInfo?.role === "Student") {
      await updateStudent(userInfo?._id, {
        name,
        surname,
        email,
        phone,
        extraInfo,
      });
      success = true;
      setIsLoading(false);
    } else if (userInfo?.role === "Parent") {
      await updateParent(userInfo?._id, {
        name,
        surname,
        email,
        phone,
        extraInfo,
      });
      success = true;
      setIsLoading(false);
    } else if (userInfo?.role === "Tutor") {
      await updateStudent(userInfo?._id, {
        name,
        surname,
        email,
        phone,
        extraInfo,
      });
      success = true;
      setIsLoading(false);
    } else {
      console.error(`Invalid role of ${userInfo?.role}`);
    }

    //Reset to see changes: might want to do it smoother
    if (success) {
      window.location.reload();
    }
  };
  const [loading, setLoading] = useState(false);

  if (!userInfo) {
    return "Cargando...";
  }
  const isActive = userInfo.status === "Activo";

  const changeActiveStatus = async (e) => {
    setLoading(true);
    let newStatus = isActive ? "Inactivo" : "Activo";
    let update = {
      status: newStatus,
    };
    await updateTutor(userInfo?._id, update);
    setLoading(false);
    window.location.reload(false);
  };
  return (
    <>
      <Stack
        p={{ base: 5, md: 10 }}
        w={{ base: "100vw", md: "70vw", lg: "70vw" }}
        h={"100%"}
      >
        <Text
          textAlign={{ base: "center", md: "start" }}
          fontSize={"200%"}
          fontWeight={700}
        >
          Mi cuenta
        </Text>
        {userInfo?.role !== "Tutor" && (
          <Wrap
            p={5}
            direction={{ base: "column", md: "row" }}
            justify={"space-between"}
            gap={{ base: 2, md: 10 }}
          >
            <WrapItem
              width={{ base: "100%", md: "40%" }}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={4}
              h={"13vh"}
            >
              {/* <Text fontSize={{ base: "lg", md: "xl" }} fontWeight={500}>
                Foto de perfil
              </Text> */}
              <Avatar size={"xl"} name={userInfo?.name}>
                {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
              </Avatar>
            </WrapItem>
            <WrapItem
              width={{ base: "100%", md: "40%" }}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={4}
            >
              <Text fontSize={{ base: "lg", md: "xl" }} whiteSpace={"nowrap"}>
                Sobre mí
              </Text>
              <Textarea
                placeholder={userInfo?.extraInfo || "¡Cuéntanos sobre ti!"}
                size="lg"
                resize={"None"}
                isDisabled={controlState}
                value={extraInfo}
                onChange={(e) => setExtraInfo(e.target.value)}
              />{" "}
            </WrapItem>
          </Wrap>
        )}
        <Stack
          gap={5}
          marginTop={5}
          w={{ base: "80vw", md: "50vw", lg: "70%" }}
        >
          <Stack
            width={"100"}
            justify={"space-between"}
            direction={{ base: "column", md: "row" }}
          >
            <Stack
              width={"100"}
              justify={"space-between"}
              align={"center"}
              direction={"row"}
            >
              <FormLabel>Nombres</FormLabel>
              <Input
                w={"70%"}
                value={name}
                placeholder={userInfo?.name || ""}
                isDisabled={controlState}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Stack>
            <Stack justify={"space-between"} align={"center"} direction={"row"}>
              <FormLabel>Apellidos</FormLabel>
              <Input
                w={"70%"}
                value={surname}
                placeholder={userInfo?.surname || ""}
                isDisabled={controlState}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Stack>
          </Stack>
          {/* <Stack justify={'space-between'} align={'center'} direction={'row'}>
                    <FormLabel>Año</FormLabel>
                    <Input w={'70%'} placeholder={'11°'} isDisabled={controlState}  />            
                </Stack>
                <Stack justify={'space-between'} align={'center'} direction={'row'}>
                    <FormLabel>Institución</FormLabel>
                    <Input w={'70%'} placeholder={'St. George'} isDisabled={controlState}  />            
                </Stack> */}
          <Stack justify={"flex-start"} align={"center"} direction={"row"}>
            <FormLabel>E-mail</FormLabel>
            <Input
              w={"70%"}
              value={email}
              placeholder={userInfo?.email || ""}
              isDisabled={controlState}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Stack>
          <Stack justify={"flex-start"} align={"center"} direction={"row"}>
            <FormLabel>Contraseña</FormLabel>
            <Stack w={"70%"}>
              <Input placeholder={"*******"} isDisabled={true} />
              {/* <Link
                textAlign={"end"}
                textDecoration={"underline"}
                fontSize={"80%"}
                color={MAIN_COLOR}
                //TODO: Send to reset password page
              >
                ¿Olvidaste tu contraseña?
              </Link> */}
            </Stack>
          </Stack>
          <WrapItem>
            <Stack
              display={"flex"}
              gap={3}
              flexDir={"row"}
              alignItems={"center"}
            >
              <Text fontWeight={500}>
                Celular{" "}
                {userInfo?.phone && (
                  <Text fontSize={"md"} textDecorationStyle="italic">
                    (Actualmente: {userInfo?.phone})
                  </Text>
                )}
              </Text>
              <PhoneNumberInput
                isDisabled={controlState}
                value={phone}
                options={countryOptions}
                setGetCountryCode={setGetCountryCode}
                onChange={(e) => {
                  setPhone(e);
                }}
              />
            </Stack>
          </WrapItem>

          {/* <Stack justify={"flex-start"} align={"center"} direction={"row"}>
            <PhoneNumberInput
              options={countryOptions}
              setGetCountryCode={setGetCountryCode}
              isDisabled={controlState}
              settings={true}
            />
          </Stack> */}
        </Stack>
        <HStack alignSelf={{ base: "center", md: "end" }}>
          {!controlState ? (
            <>
              <Button
                onClick={onClickHandler}
                bgColor={"red"}
                color={"white"}
                alignSelf={{ base: "none", md: "end" }}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                onClick={submitChanges}
                alignSelf={{ base: "none", md: "end" }}
                color={"white"}
                bgColor={MAIN_COLOR}
              >
                Guardar
              </Button>
            </>
          ) : userInfo.role !== "Tutor" ? (
            <Button
              onClick={onClickHandler}
              alignSelf={{ base: "none", md: "end" }}
              color={"white"}
              bgColor={MAIN_COLOR}
            >
              Editar
            </Button>
          ) : (
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Button
                marginRight={{ base: 0, md: 2 }}
                marginBottom={{ base: 2, md: 0 }}
                onClick={changeActiveStatus}
                isLoading={loading}
                bgColor={isActive ? "red" : "green"}
              >
                <Tooltip
                  hasArrow
                  label={
                    isActive
                      ? "Si es que se cambia entonces ya no podrán tener clases contigo 😨"
                      : "Para volver a tener clases 😎"
                  }
                >
                  <Text color={isActive ? "white" : "white"}>
                    {isActive
                      ? "Cambiar perfil a inactivo"
                      : "Cambiar perfil a Activo"}
                  </Text>
                </Tooltip>
              </Button>
              <Button
                alignSelf={{ base: "none", md: "end" }}
                color={"white"}
                bgColor={MAIN_COLOR}
                isDisabled={true}
              >
                Puede editar sus datos en 'Perfil'
              </Button>
            </Flex>
          )}
        </HStack>
      </Stack>
    </>
  );
};

export default MyAccount;
