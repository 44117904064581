import React from "react";
// import { CalendarModal, ReportClassModal } from "../../components";
import CalendarModal from "../../components/CalendarModal/CalendarModal";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import {
  Button,
  Text,
  useDisclosure,
  Stack,
  Box,
  VStack,
} from "@chakra-ui/react";
import CancelClassModal from "./CancelClassModal";
import { CANCEL_CLASS_COLOR } from "../../utils/constants";
import {
  getValidDriveLink,
  getDayInfo,
  getHourAndMinutes,
} from "../../utils/functions";
import { useSelector } from "react-redux";

const FutureClassModal = ({ event }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useSelector((state) => state.user);

  return (
    <>
      <VStack direction gap={3} m={10}>
        {event.extra.class.prevInfo ? (
          <>
            <Text>
              El estudiante dijo lo siguiente en preparación para la clase:
            </Text>
            <Box style={{ padding: 15, borderWidth: 2 }}>
              {event.extra.class.prevInfo}
            </Box>{" "}
          </>
        ) : (
          <Text>El estudiante no dejó ningun mensaje previo.</Text>
        )}
        <Button
          backgroundColor={CANCEL_CLASS_COLOR}
          color={"white"}
          onClick={onOpen}
        >
          <Text>Cancelar Clase</Text>
        </Button>
      </VStack>
      <CancelClassModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        classId={event.extra.class._id}
        tutorName={event.extra.info.tutorInfo.name}
        tutorImage={getValidDriveLink(
          event.extra.info.tutorInfo.imageDriveLink
        )}
        classTitle={event.title}
        classDescription={`${getDayInfo(event.start)},${getHourAndMinutes(
          event.start
        )} - ${getHourAndMinutes(event.end)}`}
        isTutor={true}
        classInfo={event.extra.class}
      />
    </>
  );
};

export default FutureClassModal;
