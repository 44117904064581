import React, { useState } from "react";
// import { CalendarModal, ReportClassModal } from "../../components";
import CalendarModal from "../../components/CalendarModal/CalendarModal";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import {
  Button,
  Text,
  useDisclosure,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Avatar,
  AvatarBadge,
  Box,
  WrapItem,
  Tooltip,
  ChakraProvider,
} from "@chakra-ui/react";
import { CANCEL_CLASS_COLOR } from "../../utils/constants";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
} from "../../utils/functions.js";

import CancelClassModal from "./CancelClassModal";
import AcceptClassModal from "./AcceptClassModal";
import RejectClassModal from "./RejectClassModal";

const PendingClassModal = ({ event }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const ModalData1 = {
    header: "¿Seguro que deseas cancelar esta clase?",
    className: "Matemáticas IB en 2 meses",
    tutor: "con Valentín Quezada",
    timing: "Viernes 11 mar, 11:00 - 12:00 hrs.",
  };
  const ModalData2 = {
    header: "Clase cancelada",
    className: "Matemáticas IB en 2 meses",
    tutor: "con Valentín Quezada",
    timing: "Viernes 11 mar, 11:00 - 12:00 hrs.",
  };

  const classCancelHandler = () => {
    onClose();
    setTimeout(() => {
      onOpen1();
    }, 600);
  };

  const {
    isOpen: isOpenAccept,
    onOpen: onOpenAccept,
    onClose: onCloseAccept,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const [tempConfirmed, setTempConfirmed] = useState(false);
  const [tempRejected, setTempRejected] = useState(false);

  return (
    <ChakraProvider>
      {" "}
      <AcceptClassModal
        isOpen={isOpenAccept}
        subjectDescription={event.extra.info.subjectDescription}
        timeDescription={event.extra.info.timeDescription}
        participantMessage={event.extra.info.participantMessage}
        onClose={onCloseAccept}
        foundClass={event.extra.class}
        onSuccess={() => {
          setTempConfirmed(true);
        }}
      />
      <RejectClassModal
        isOpen={isOpenReject}
        subjectDescription={event.extra.info.subjectDescription}
        timeDescription={event.extra.info.timeDescription}
        participantMessage={event.extra.info.participantMessage}
        onClose={onCloseReject}
        foundClass={event.extra.class}
        onSuccess={() => {
          setTempRejected(true);
        }}
      />
      {tempConfirmed ? (
        <Text
          color="#07A58E"
          alignSelf={{ base: "none", md: "center" }}
          //   fontSize="md"
        >
          Se confirmó la clase! Refresca la página para ver más opciones.
        </Text>
      ) : tempRejected ? (
        <Text alignSelf={{ base: "none", md: "center" }} color="#FF2B2B">
          {" "}
          Se rechazó la clase.
        </Text>
      ) : (
        <Tooltip
          label={
            event.extra.class.prevInfo
              ? `El estudiante dijo: ${event.extra.class.prevInfo}`
              : "El estudiante no dejó ningun mensaje previo."
          }
        >
          <Box
            //   flex={0.5}
            flexDir={"row"}
            display={"flex"}
            alignItems={"center"}
            // justifyContent={"space-between"}
            alignSelf={{ base: "none", md: "center" }}
          >
            <Button
              // onClick={onClassClick}
              rounded={"lg"}
              color={"white"}
              fontWeight={500}
              bgColor={"#07A58E"}
              margin={2}
              onClick={onOpenAccept}
            >
              Aceptar
            </Button>
            <Button
              margin={"0"}
              // onClick={onClassClick}
              rounded={"lg"}
              variant="ghost"
              fontWeight={500}
              color={"#FF2B2B"}
              onClick={onOpenReject}
            >
              Rechazar
            </Button>
          </Box>
        </Tooltip>
      )}
      {/* <Button backgroundColor={"#0ED37B"} color={"white"} onClick={onOpen2}>
          <Text>Report Class Modal</Text>
        </Button> */}
      {/* </Stack> */}
      <CancelClassModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        classId={event.extra.class._id}
        tutorName={event.extra.info.tutorInfo.name}
        tutorImage={getValidDriveLink(
          event.extra.info.tutorInfo.imageDriveLink
        )}
        classTitle={event.extra.info.subjectDescription}
        classDescription={event.extra.info.timeDescription}
      />
    </ChakraProvider>
  );
};

export default PendingClassModal;
