import React, { useState, useEffect } from "react";
import {
  Button,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Wrap,
  AspectRatio,
  Modal,
  ModalOverlay,
  Textarea,
  ModalBody,
  Avatar,
  AvatarBadge,
  VStack,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import StarRating from "../../components/StarRating";
import { formatTutorFeedback } from "../../utils/functions";
import { sendTutorFeedback } from "../../utils/ApiConfig";
import { MAIN_COLOR } from "../../utils/constants";

const SendTutorFeedbackModal = ({
  foundClass,
  isOpen,
  onOpen,
  onClose,
  onFeedbackSent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();
  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();

  const handleSendFeedback = async () => {
    for (let i = 0; i < answers.length; i++) {
      if (!answers[i] || answers[i].trim() === "") {
        alert("Asegurate de completar todos los campos");
        return;
      }
    }
    setIsLoading(true);
    let tutorFeedback = formatTutorFeedback(QUESTIONS, answers);
    let { success } = await sendTutorFeedback(foundClass._id, tutorFeedback);
    setIsLoading(false);
    if (success) {
      onClose();
      onOpenConfirmation();
      onFeedbackSent();
    } else {
      onOpenError();
    }
  };

  const QUESTIONS = [
    "¿Qué temas se abordó en clase?",
    "¿Qué se le dio bien al estudiante?",
    "¿En que puede mejorar el estudiante con respecto a lo abordado?",
    "Califica el desempeño/progreso del estudiante",
    "Nota para el padre - Ejemplo: (estudiante no presentó su tarea), (Se le dejó x tarea para la siguiente clase), (Mejoró muchísimo)",
  ];
  // Should have the same length as QUESTIONS
  const [answers, setAnswers] = useState(["", "", "", "", ""]);

  return (
    <>
      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={{ base: 5, md: 10 }}>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <VStack gap={{ base: 2, md: 5 }}>
              <Text
                fontSize={{ base: "90%", md: "125%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                ¡Sesión terminada! Realiza tu reporte de clase aquí
              </Text>
              {QUESTIONS.map((question, i) => {
                return (
                  <FormControl>
                    <FormLabel>
                      <Wrap>
                        <Text>{question}</Text>
                        <Text color={"red"}>*</Text>
                      </Wrap>
                    </FormLabel>
                    {question.includes("Califica") ? (
                      <StarRating
                        size={{ base: 4, md: 5, lg: 8 }}
                        icon="star"
                        scale={10}
                        fillColor="gold"
                        strokeColor="grey"
                        rating={answers[i].split("/")[0]}
                        setRating={(val) => {
                          let prev = [...answers];
                          prev[i] = `${val}/10`;
                          setAnswers(prev);

                          // setErrorMessage(""); //resetting
                          // setRating(val);
                        }}
                      />
                    ) : (
                      <Textarea
                        placeholder="Escribe aquí..."
                        size="xs"
                        resize={"None"}
                        value={answers[i]}
                        onChange={(e) => {
                          let text = e.target.value;
                          let prev = [...answers];
                          prev[i] = text;
                          setAnswers(prev);
                        }}
                      />
                    )}
                  </FormControl>
                );
              })}
              <Button
                bgColor={MAIN_COLOR}
                color={"white"}
                onClick={handleSendFeedback}
                isLoading={isLoading}
              >
                Mandar
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size={"xl"}
        isOpen={isOpenConfirmation}
        onClose={onCloseConfirmation}
      >
        <ModalOverlay />
        <ModalContent p={{ base: 5, md: 10 }}>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={{ base: 2, md: 5 }}>
              <Text
                fontSize={{ base: "90%", md: "125%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                ¡Gracias por tu valioso feedback 😍
              </Text>
              <Text
                fontSize={{ base: "80%", md: "110%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                Tu feedback nos ayuda a seguir mejorando.
              </Text>
              <Button
                bgColor={MAIN_COLOR}
                color={"white"}
                onClick={onCloseConfirmation}
              >
                Cerrar
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"xl"} isOpen={isOpenError} onClose={onCloseError}>
        <ModalOverlay />
        <ModalContent p={{ base: 5, md: 10 }}>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={{ base: 2, md: 5 }}>
              <Text
                fontSize={{ base: "90%", md: "125%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                Hubo un error 😬
              </Text>
              <Text
                fontSize={{ base: "80%", md: "110%" }}
                // fontWeight={600}
                textAlign={"center"}
              >
                Por favor intente otra vez. Si es que el problema persiste,
                porfavor contactar a Servicio al Cliente en Whatsapp.
                Agradecemos su comprensión.
              </Text>
              <Button
                bgColor={MAIN_COLOR}
                color={"white"}
                onClick={onCloseError}
              >
                Volver
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendTutorFeedbackModal;
