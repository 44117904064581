import React from "react";
import { Stack, Text } from '@chakra-ui/react';

const NoMatch = () => {
    return(<>
        <Stack p={10}>
            <Text textAlign={'center'} fontSize={'200%'} fontWeight={500}>Oops! there is as error</Text>
        </Stack>
    </>);
}

export default NoMatch;