import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ChakraProvider, Stack, Link, Divider } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";

import OwnTutors from "../Teacher/OwnTutors";
import SearchTutors from "../Teacher/SearchTutors";
import { getStudents } from "../../utils/ApiConfig";
import SelectedComponent from "../../components/SelectComponent/SelectComponent";

const ReserveClass = () => {
  const { userInfo } = useSelector((state) => state.user);
  // const [selectedStudent, setSelectedStudent] = useState({});
  // const [students, setStudents] = useState([]); // Relevant for parents
  // useEffect(() => {
  //   async function get_info() {
  //     if (userInfo.role === "Parent") {
  //       if (userInfo.students) {
  //         let res = await getStudents({ _id: { $in: userInfo.students } });
  //         setStudents(res);
  //       }
  //     }
  //   }
  //   get_info();
  // }, [userInfo]);
  if (!userInfo) {
    return "Cargando...";
  }
  if (userInfo.role !== "Student" && userInfo.role !== "Parent") {
    return "Esta página no está disponible.";
  }
  return (
    <ChakraProvider>
      <Stack p={10}>
        {/* {userInfo.role === "Student" && (
          <>
            <OwnTutors currStudent={userInfo} />
            <SearchTutors currStudent={userInfo} />
          </>
        )} */}
        {/* {userInfo.role === "Parent" && (
          <>
            <SelectedComponent
              dataOptions={students.map((student) => ({
                label: student.name,
                value: student._id,
              }))}
              setSelectedOptions={(option) => {
                let found = students.filter(
                  (student) => student._id === option.value
                );
                if (found && found.length > 0) {
                  setSelectedStudent(found[0]);
                }
              }}
            />
            {selectedStudent._id && (
              <>
                <OwnTutors currStudent={selectedStudent} />
                <SearchTutors currStudent={selectedStudent} />
              </>
            )}
          </>
        )} */}
        <SearchTutors />
        <Divider size="xl" />
        <OwnTutors />

        <Stack paddingTop={7}>
          <Link
            alignSelf={"end"}
            rounded={"full"}
            p={1}
            align={"center"}
            w={10}
            h={10}
            color={"white"}
            bgColor={"#E8E8E8"}
            href="#"
          >
            <ArrowUpIcon />
          </Link>
        </Stack>
      </Stack>
    </ChakraProvider>
  );
};
export default ReserveClass;
