import React, { useState, useEffect } from "react";
import {
  Text,
  Stack,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Box,
  Tag,
  Spinner,
} from "@chakra-ui/react";
import { getFamilyHistory } from "../../utils/ApiConfig";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
// import { StatusScreen, initMercadoPago } from "@mercadopago/sdk-react";
import { Tooltip } from "@chakra-ui/react";

// const Data = [
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
//   { Fecha: "12-02-2023", Horas: 16, Monto: "S/960", Comprobante: "00011" },
// ];
const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Box p="1">
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  </Box>
));

const MyPayments = () => {
  //   initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

  const [salesHistory, setSalesHistory] = useState([]);
  const { userInfo, isFetching } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function get_info() {
      if (!userInfo.family) {
        return;
      }
      let history = await getFamilyHistory(userInfo.family);
      let sales = history.filter((hist) => hist.model === "SaleHistory");
      console.log(sales);
      setSalesHistory(sales);
      setLoading(false);
    }
    get_info();
  }, []);

  const formatDate = (timestamp) => {
    let x = moment(timestamp).locale("es").format("MMMM D, YYYY");
    return x[0].toUpperCase() + x.slice(1, x.length);
  };

  // .format("dddd mm");

  const getSpanisHourName = (r) => {
    switch (r) {
      case "IB":
        return "IB";
      case "SAT":
        return "SAT";
      case "tutoring":
        return "Tutorías";
      case "olympiad":
        return "Olimpiadas";
      default:
        return "INVALID_HOUR_TYPE";
    }
  };
  return (
    <>
      <Stack p={10} w={{ base: "100vw", md: "70vw", lg: "70vw" }} h={"100vh"}>
        <Text
          textAlign={{ base: "center", md: "start" }}
          fontSize={"200%"}
          fontWeight={700}
        >
          Mis compras
        </Text>
        <Text fontSize="md">
          Todos tus recibos se encuentran en el email que usaste cuando hiciste
          la compra.
        </Text>
        {/* <StatusScreen initialization={{ paymentId: "1315111401" }} /> */}
        {loading ? (
          <Text>
            Cargando <Spinner />
          </Text>
        ) : salesHistory.length === 0 ? (
          <Text>No se encontraron compras!</Text>
        ) : (
          <TableContainer>
            <Table variant="simple">
              {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>Fecha</Th>
                  <Th>Hora(s)</Th>
                  <Th>Monto</Th>
                  <Th>Comprobante</Th>
                </Tr>
              </Thead>
              <Tbody>
                {salesHistory.map((sale) => {
                  return (
                    <Tr key={sale._id}>
                      <Td>{formatDate(sale.timestamp)}</Td>
                      <Td>{`${sale.diff.hoursBought} de ${getSpanisHourName(
                        sale.diff.hourType
                      )}`}</Td>
                      <Td>{`${sale.diff.amount} ${sale.diff.currency}`}</Td>
                      {
                        <Td>
                          {sale.diff.source === "mercadopago" ? (
                            <Text>
                              Se envió a
                              <Tooltip
                                label={
                                  sale.diff?.extraData?.payer?.email ||
                                  "El correo que usó cuando hizo la compra por MercadoPago"
                                }
                              >
                                <CustomCard>su correo</CustomCard>
                              </Tooltip>
                            </Text>
                          ) : sale.diff.source === "manual" ? (
                            <Text>Hecha por admin.</Text>
                          ) : (
                            <Text>
                              <Tooltip
                                label={
                                  "No se preocupe. Esto significa que la compra se hizo hace bastante tiempo, pero sus horas deberían de seguir siendo correctas."
                                }
                              >
                                <CustomCard>No se encontró</CustomCard>
                              </Tooltip>
                            </Text>
                          )}
                        </Td>
                      }
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default MyPayments;
