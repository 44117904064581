import * as React from "react";
import "./App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ChakraProvider, Spinner } from "@chakra-ui/react";

// import {
//   Demo,
//   Register,
//   Header,
//   ProtectedRoute,
//   Navigation,
// } from "./components";
// import Demo from "./"

import Register from "./components/Register";
import Header from "./components/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import Navigation from "./components/Navigation";

import Home from "./pages/Home";
import Clases from "./pages/Clases/Clases";
import Comprar from "./pages/Buy/Comprar";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import References from "./pages/Reference/Reference";
import Settings from "./pages/Settings/Settings";
import NoMatch from "./pages/NoMatch";
import ResourcesPage from "./pages/ResourcesPage";
import ReserveClass from "./pages/ReserveClass/ReserveClass";
import CustomPageForNotion from "./pages/CustomPageForNotion";
import ClassVideo from "./pages/classVideo/classVideo";
import Faq from "./pages/FAQ/Faq";
import AmericanUniversities from "./pages/AmericanUniversities";
import MyProfile from "./pages/MyProfile/MyProfile";
import ModalTesting from "./pages/ModalTesting";
import MyProfileTutor from "./pages/MyProfile/MyProfileTutor";
import ClasesTutor from "./pages/Clases/ClasesTutor";
import ClassPage from "./pages/classVideo/ClassPage";

// import {
//   Home,
//   Clases,
//   Comprar,
//   Login,
//   Dashboard,
//   References,
//   Settings,
//   NoMatch,
//   ResourcesPage,
//   // TeacherPage,
//   ReserveClass,
//   CustomPageForNotion,
//   // Profesores,
//   ClassVideo,
//   Faq,
//   Chat,
//   AmericanUniversities,
//   // ChatPage,
//   MyProfile,
//   ModalTesting,
//   MyProfileTutor,
//   ClasesTutor,
// } from "./pages";
import TeacherPage from "./pages/TeacherPage";
import ReserveTutorClassPage from "./pages/ReserveClass/ReserveTutorClassPage";
import HomeTutor from "./pages/HomeTutor";
import StudentFeedbackPage from "./pages/Feedback/StudentFeedbackPage";
import TutorFeedbackPage from "./pages/Feedback/TutorFeedbackPage";
import ConfirmClassPage from "./pages/Clases/ConfirmClassPage";
import ReportPage from "./pages/Report/ReportPage";
import { useSelector } from "react-redux";
import ChangePassword from "./pages/ChangePassword";

export default function App() {
  const clases = [
    { id: "1", Name: "Robin Wieruch" },
    { id: "2", Name: "Sarah Finnley" },
  ];
  const TutorClases = [
    { id: "1", Name: "Robin Wieruch" },
    { id: "2", Name: "Sarah Finnley" },
  ];

  const { userInfo } = useSelector((state) => state.user);

  return (
    <div className="body">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              // <ChakraProvider>
              <ProtectedRoute>
                {userInfo?.role === "Tutor" ? <HomeTutor /> : <Home />}

                {/* {userInfo?.role === "Tutor" ? (
                  <HomeTutor />
                ) : userInfo?._id ? (
                  <Home />
                ) : (
                  <></>
                )} */}
              </ProtectedRoute>
              // </ChakraProvider>
            }
          ></Route>
          {/* <Route path='/register' element={<><Navigation /><Register /></>}></Route> */}
          <Route
            path="/login"
            element={
              <ChakraProvider>
                <Navigation />
                <Login userLogin={true} />
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/newpassword"
            element={
              <ChakraProvider>
                <Navigation />
                <ChangePassword />
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/register"
            element={
              <ChakraProvider>
                <Navigation />
                <Login userLogin={false} />
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/dashboard"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <Dashboard />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/clases"
            element={
              // <ChakraProvider>
              <ProtectedRoute>
                <Header />
                {userInfo?.role === "Tutor" ? (
                  <ClasesTutor clases={clases} />
                ) : (
                  <Clases clases={TutorClases} />
                )}
              </ProtectedRoute>
              // </ChakraProvider>
            }
          ></Route>
          {/* <Route
            path="/video"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <ClassVideo />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route> */}
          <Route
            path="/call"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <ClassPage />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/reserveclasses/"
            element={
              // <ChakraProvider>
              <ProtectedRoute>
                <Header />
                <ReserveTutorClassPage />
              </ProtectedRoute>
              // </ChakraProvider>
            }
          ></Route>
          {/* <Route
            path="/profesores"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <Profesores />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route> */}
          {/* <Route
            path="/chat"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <ChatPage />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route> */}
          <Route
            path="/referidos"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <References />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/comprar"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <Comprar />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/ajustes"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <Settings />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/miperfil"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  {userInfo?.role === "Tutor" ? (
                    <MyProfileTutor />
                  ) : (
                    <MyProfile />
                  )}
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          {/* <Route
            path="/comprar"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <TeacherProfile />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route> */}
          <Route
            path="/recursos/ib"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <ResourcesPage />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/recursors/universidades"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <AmericanUniversities />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>
          <Route
            path="/faq"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <Faq />
                </ProtectedRoute>
              </ChakraProvider>
            }
          ></Route>

          <Route
            path="/tutor"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <TeacherPage />
                </ProtectedRoute>
              </ChakraProvider>
            }
          />
          <Route
            path="/studentfeedback"
            element={
              <ChakraProvider>
                <Header />
                <StudentFeedbackPage />
              </ChakraProvider>
            }
          />
          <Route
            path="/tutorfeedback"
            element={
              <ChakraProvider>
                <Header />
                <TutorFeedbackPage />
              </ChakraProvider>
            }
          />
          <Route
            path="/report"
            element={
              <ChakraProvider>
                <Header />
                <ReportPage />
              </ChakraProvider>
            }
          />
          <Route
            path="/confirm"
            element={
              <ChakraProvider>
                <Header />
                <ConfirmClassPage />
              </ChakraProvider>
            }
          />
          <Route
            path="/reservar"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ReserveClass />
                </ProtectedRoute>
              </>
            }
          />
          {/* 
          <Route
            path="/customPage/:notionId"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <CustomPageForNotion />
                </ProtectedRoute>
              </ChakraProvider>
            }
          /> */}

          <Route
            path="/recursos/info/:notionId"
            element={
              <ChakraProvider>
                <ProtectedRoute>
                  <Header />
                  <CustomPageForNotion />
                </ProtectedRoute>
              </ChakraProvider>
            }
          />
          {/* <Route
            path="/demo"
            element={
              <>
                <Header />
                <Demo />
              </>
            }
          /> */}
          <Route
            path="/modaltesting"
            element={
              <ChakraProvider>
                <Header />
                <ModalTesting />
              </ChakraProvider>
            }
          />
          {/* Route for all unknown route request */}
          <Route
            path="*"
            element={
              <ChakraProvider>
                <Header />
                <NoMatch />
              </ChakraProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
