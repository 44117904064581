import React, { useState, useEffect, useReducer } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  VStack,
  Tooltip,
  Box,
  Tag,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from "@chakra-ui/react";
import {
  ChatIcon,
  CloseIcon,
  HamburgerIcon,
  CalendarIcon,
  ExternalLinkIcon,
  CheckIcon,
  InfoOutlineIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
// import ModalRating from "../../components/ModalRating";
import RateClassModal from "./RateClassModal";
import { useNavigate } from "react-router-dom";
import CalendarClasses from "./CalendarClasses.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import SelectStudent from "./SelectStudent";
import {
  getClasses,
  getSubjectById,
  getTutorDetail,
  getStudent,
  acceptClass,
  rejectClass,
} from "../../utils/ApiConfig.js";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
  feedbackToData,
} from "../../utils/functions.js";
import {
  PENDING_CLASS_COLOR,
  PAST_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
  MAIN_COLOR,
} from "../../utils/constants";
import CancelClassModal from "./CancelClassModal";
import StarRating from "../../components/StarRating";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import SendTutorFeedbackModal from "./SendTutorFeedbackModal";
import ViewTutorFeedbackModal from "./ViewTutorFeedbackModal";
import AcceptClassModal from "./AcceptClassModal";
import RejectClassModal from "./RejectClassModal";
import ClassFeedbackModal from "./ClassFeedbackModal";

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Box p={1}>
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  </Box>
));

const ListaClases = ({ type, classInfo, info, listInfo }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenStudentFeedback,
    onOpen: onOpenStudentFeedback,
    onClose: onCloseStudentFeedback,
  } = useDisclosure();

  const {
    isOpen: isOpenAccept,
    onOpen: onOpenAccept,
    onClose: onCloseAccept,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const {
    isOpen: isOpenSendTutorFeedback,
    onOpen: onOpenSendTutorFeedback,
    onClose: onCloseSendTutorFeedback,
  } = useDisclosure();

  const {
    isOpen: isOpenViewTutorFeedback,
    onOpen: onOpenViewTutorFeedback,
    onClose: onCloseViewTutorFeedback,
  } = useDisclosure();

  const [tempConfirmed, setTempConfirmed] = useState(false);
  const [tempRejected, setTempRejected] = useState(false);
  const [tempSentFeedback, setTempSentFeedback] = useState(false);

  const { userInfo } = useSelector((state) => state.user);
  const [submittedRating, setSubmittedRating] = useState(0);
  if (!userInfo) {
    return "Cargando...";
  }
  // Each feedback has sender, text and rating
  const getClassRating = () => {
    //Gets the average of all feedbacks.
    let feedbacks = classInfo.individualStudentFeedback;
    if (!feedbacks || feedbacks.length === 0) {
      return null;
    }

    let ratings = feedbacks.map((feedback) => feedback.rating);
    let sum = ratings.reduce((partialSum, a) => partialSum + a, 0);
    let avg = sum / ratings.length;
    return Math.round(avg); //Since #starts should be an integer
  };
  const isHappeningNow =
    new Date(classInfo.start) < new Date() &&
    new Date() < new Date(classInfo.end);
  return (
    <ChakraProvider>
      {(type === "pendientes") | (type === "próximas") ? (
        <>
          <Stack
            // h={{ base: "21vh", sm: "14vh", md: "16vh" }}
            justify={"start"}
            direction={"row"}
            p={6}
            rounded={"lg"}
            boxShadow={"lg"}
            bgColor={"white"}
          >
            <CancelClassModal
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
              classId={classInfo._id}
              tutorName={info.tutorInfo.name}
              tutorImage={getValidDriveLink(info.tutorInfo.imageDriveLink)}
              classTitle={info.subjectDescription}
              classDescription={info.timeDescription}
              isTutor={true}
              participantMessage={info.participantMessage}
              user_who_rejected={userInfo?._id}
              classInfo={classInfo}
            />

            <AcceptClassModal
              isOpen={isOpenAccept}
              subjectDescription={info.subjectDescription}
              timeDescription={info.timeDescription}
              participantMessage={info.participantMessage}
              onClose={onCloseAccept}
              foundClass={classInfo}
              onSuccess={() => {
                setTempConfirmed(true);
              }}
            />
            <RejectClassModal
              isOpen={isOpenReject}
              subjectDescription={info.subjectDescription}
              timeDescription={info.timeDescription}
              participantMessage={info.participantMessage}
              onClose={onCloseReject}
              foundClass={classInfo}
              onSuccess={() => {
                setTempRejected(true);
              }}
            />
            <Tooltip label={info.participantMessage}>
              <Stack alignSelf={"center"}>
                <Avatar
                  size={{ base: "lg", md: "lg", lg: "xl" }}
                  name={info.participantMessage}
                  // name={info.tutorInfo.name}
                  // src={getValidDriveLink(info.tutorInfo.imageDriveLink)}
                >
                  {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                </Avatar>
              </Stack>
            </Tooltip>
            <Divider orientation="vertical" />
            {/* <Stack w={"100vw"} direction={"column"}> */}
            <HStack
              w={"100%"}
              justify={"space-between"}
              direction={{ base: "column", md: "row" }}
            >
              <Stack
                w={"100%"}
                justify={"space-between"}
                direction={{ base: "column", md: "row" }}
              >
                <Stack>
                  <Text
                    fontWeight={500}
                    fontSize={{
                      base: "80%",
                      md: "100%",
                      lg: "120%",
                      xl: "150%",
                    }}
                  >
                    {info.timeDescription}
                    {isHappeningNow && (
                      <Text color="red" as="span">
                        {" "}
                        Pasando ahora!
                      </Text>
                    )}
                  </Text>
                  <Text
                    fontSize={{
                      base: "70%",
                      md: "80%",
                      lg: "100%",
                      xl: "120%",
                    }}
                  >
                    {info.subjectDescription} con {info.participantMessage}
                  </Text>
                  <Text
                    fontSize={{
                      base: "50%",
                      md: "60%",
                      lg: "80%",
                      xl: "100%",
                    }}
                  ></Text>
                </Stack>
                {type === "pendientes" &&
                  (tempConfirmed ? (
                    <Text
                      color="#07A58E"
                      alignSelf={{ base: "none", md: "center" }}
                      //   fontSize="md"
                    >
                      Se confirmó la clase! Refresca la página para ver más
                      opciones.
                    </Text>
                  ) : tempRejected ? (
                    <Text
                      alignSelf={{ base: "none", md: "center" }}
                      color="#FF2B2B"
                    >
                      {" "}
                      Se rechazó la clase.
                    </Text>
                  ) : (
                    <Tooltip
                      label={
                        classInfo.prevInfo
                          ? `El estudiante dijo: ${classInfo.prevInfo}`
                          : "El estudiante no dejó ningun mensaje previo."
                      }
                    >
                      <Box
                        //   flex={0.5}
                        flexDir={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        // justifyContent={"space-between"}
                        alignSelf={{ base: "none", md: "center" }}
                      >
                        <Button
                          // onClick={onClassClick}
                          rounded={"lg"}
                          color={"white"}
                          fontWeight={500}
                          bgColor={"#07A58E"}
                          margin={2}
                          onClick={onOpenAccept}
                        >
                          Aceptar
                        </Button>
                        <Button
                          margin={"0"}
                          // onClick={onClassClick}
                          rounded={"lg"}
                          variant="ghost"
                          fontWeight={500}
                          color={"#FF2B2B"}
                          onClick={onOpenReject}
                        >
                          Rechazar
                        </Button>
                      </Box>
                    </Tooltip>
                  ))}
                {type === "próximas" && (
                  <Flex alignItems="center">
                    <Tooltip
                      label={
                        classInfo.prevInfo ||
                        "El estudiante no dejó ningun mensaje previo"
                      }
                    >
                      <CustomCard alignItems={"center"}>
                        El estudiante dijo
                      </CustomCard>
                    </Tooltip>
                  </Flex>
                )}
              </Stack>
              {type === "próximas" && ( //Can only cancel when it's a future classs. Otherwise it can reject
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    variant="outline"
                    border={0}
                  />
                  <MenuList>
                    <MenuItem
                      color={"red"}
                      icon={<CloseIcon />}
                      onClick={onOpen}
                    >
                      Cancelar
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </Stack>
          {/* </Stack> */}
        </>
      ) : (
        <>
          <Stack
            // h={{ base: "27vh", sm: "20vh", md: "16vh" }}
            justify={"start"}
            direction={"row"}
            p={6}
            rounded={"lg"}
            boxShadow={"lg"}
            bgColor={"white"}
          >
            <ClassFeedbackModal
              foundClass={classInfo}
              isOpen={isOpenStudentFeedback}
              onOpen={onOpenStudentFeedback}
              onClose={onCloseStudentFeedback}
            />
            <ReportClassModal />
            <SendTutorFeedbackModal
              isOpen={isOpenSendTutorFeedback}
              onOpen={onOpenSendTutorFeedback}
              onClose={onCloseSendTutorFeedback}
              foundClass={classInfo}
              onFeedbackSent={() => {
                setTempSentFeedback(true);
              }}
            />
            <ViewTutorFeedbackModal
              isOpen={isOpenViewTutorFeedback}
              onOpen={onOpenViewTutorFeedback}
              onClose={onCloseViewTutorFeedback}
              tutorFeedback={classInfo.tutorFeedback}
            />
            <Tooltip label={info.participantMessage}>
              <Stack alignSelf={"center"}>
                <Avatar
                  size={{ base: "lg", md: "lg", lg: "xl" }}
                  name={info.participantMessage}
                  // name={info.tutorInfo.name}

                  // src={getValidDriveLink(info.tutorInfo.imageDriveLink)}
                >
                  {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                </Avatar>
              </Stack>
            </Tooltip>
            <Divider orientation="vertical" />
            <Stack w={"100%"}>
              <Stack
                justify={"space-between"}
                direction={{ base: "column", md: "row" }}
              >
                <Stack>
                  <Text
                    fontWeight={500}
                    fontSize={{
                      base: "80%",
                      md: "100%",
                      lg: "120%",
                      xl: "150%",
                    }}
                  >
                    {info.timeDescription}
                  </Text>

                  <Text
                    fontSize={{
                      base: "70%",
                      md: "80%",
                      lg: "100%",
                      xl: "120%",
                    }}
                  >
                    {info.subjectDescription} con {info.participantMessage}
                  </Text>
                  {/* Assume for now that all past classes ocurred*/}
                  <>
                    <Text
                      fontSize={{
                        base: "60%",
                        md: "70%",
                        lg: "100%",
                        xl: "120%",
                      }}
                      color={"#07A58E"}
                    >
                      <CheckIcon /> Ganaste {classInfo.duration}h de clase.
                    </Text>
                  </>
                </Stack>
                {/* <ModalRating /> */}
                <VStack alignItems="center" justifyContent="center">
                  {getClassRating() && (
                    <Tooltip label="Puntuación que recibiste">
                      <CustomCard colorScheme="whiteAlpha">
                        <StarRating
                          size={{ base: 4, md: 4, lg: 5 }}
                          icon="star"
                          scale={5}
                          fillColor="gold"
                          strokeColor="grey"
                          rating={submittedRating || getClassRating()}
                          setRating={(val) => {}} //static
                        />
                      </CustomCard>
                    </Tooltip>
                  )}

                  {classInfo.tutorFeedback ? (
                    <Button
                      color="white"
                      bg="#07A58E"
                      onClick={onOpenViewTutorFeedback}
                    >
                      Ver reporte
                    </Button>
                  ) : tempSentFeedback ? (
                    <Text
                      color="#07A58E"
                      alignSelf={{ base: "none", md: "center" }}
                    >
                      Gracias por mandar tu feedback. Para verlo puede refrescar
                      la página.
                    </Text>
                  ) : (
                    <Button
                      color="white"
                      bg="#07A58E"
                      onClick={onOpenSendTutorFeedback}
                    >
                      Mandar feedback para el estudiante
                    </Button>
                  )}
                </VStack>
              </Stack>
            </Stack>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
                border={0}
              />
              <MenuList>
                {/* <MenuItem icon={<ChatIcon />}>Chat</MenuItem> */}
                {/* <MenuItem
                  icon={<ExternalLinkIcon />}
                  onClick={() => {
                    navigate(`/tutor?id=${classInfo.tutorId}`);
                  }}
                >
                  Ver tutor
                </MenuItem> */}
                <MenuItem
                  icon={<ExternalLinkIcon />}
                  onClick={onOpenStudentFeedback}
                >
                  Ver feedback de la clase
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </>
      )}
    </ChakraProvider>
  );
};

const PAST_CLASSES_TO_SHOW = 2;
const SHOW_CLASSES_STEP = 10;

const ListClassesTutor = ({ pendingClasses, futureClasses, pastClasses }) => {
  const [classesToShow, setClassesToShow] = useState(PAST_CLASSES_TO_SHOW);

  const [expand, setExpand] = useState(false);
  // const [pastClassesShow, setPastClassesShow] = useState([]);
  // const [pastClassesHide, setPastClassesHide] = useState([]);

  const onExpandClick = () => {
    // setExpand((prev) => !prev);
    setClassesToShow((prev) => prev + SHOW_CLASSES_STEP);
  };
  const onResetClasses = () => {
    setClassesToShow(PAST_CLASSES_TO_SHOW);
  };
  // useEffect(() => {
  //   if (pastClasses.length > PAST_CLASSES_TO_SHOW) {
  //     setPastClassesShow(pastClasses.slice(0, PAST_CLASSES_TO_SHOW));
  //     setPastClassesHide(
  //       pastClasses.slice(PAST_CLASSES_TO_SHOW, pastClasses.length)
  //     );
  //   } else {
  //     setPastClassesShow(pastClasses);
  //   }
  // }, []);
  return (
    <ChakraProvider>
      <Stack>
        <Text fontSize={"130%"} fontWeight={500}>
          Clases pendientes &nbsp;
          <Popover placement="top-start" trigger="hover">
            <PopoverTrigger>
              <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
            </PopoverTrigger>
            <PopoverContent w={"200px"}>
              <PopoverBody>
                {" "}
                <Text fontSize={"70%"} textAlign={"start"}>
                  Tienes que aprobar las clases para poder pagarte! 🤓
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Text>
        {/** Pending classes */}
        {pendingClasses.length === 0 && (
          <Text>
            No se encontraron clases.{" "}
            {futureClasses.length > 0 && "Buen trabajo!"}
          </Text>
        )}
        {pendingClasses.map((pendingClass) => (
          <ListaClases
            key={pendingClass.class._id}
            type="pendientes"
            classInfo={pendingClass.class}
            info={pendingClass.info}
            listInfo={pendingClass.listInfo}
          />
        ))}
        <Text paddingTop={5} fontSize={"130%"} fontWeight={500}>
          Próximas clases
        </Text>
        {/* Future Classes */}
        {futureClasses.length === 0 && <Text>No se encontraron clases.</Text>}
        {futureClasses.map((futureClass) => (
          <ListaClases
            key={futureClass.class._id}
            type="próximas"
            classInfo={futureClass.class}
            info={futureClass.info}
            listInfo={futureClass.listInfo}
          />
        ))}
        <Text paddingTop={5} fontSize={"130%"} fontWeight={500}>
          Clases pasadas
        </Text>
        {/* Past Classes */}
        {pastClasses.length === 0 && <Text>No se encontraron clases.</Text>}

        {pastClasses.slice(0, classesToShow).map((pastClass) => (
          <ListaClases
            key={pastClass.class._id}
            type="pasadas"
            classInfo={pastClass.class}
            info={pastClass.info}
            listInfo={pastClass.listInfo}
          />
        ))}
        <Flex flexDir="column" alignItems="flex-end">
          <Text
            textAlign={"end"}
            fontSize={{ base: "70%", sm: "80%", md: "90%" }}
          >
            Viendo {Math.min(pastClasses.length, classesToShow)}/
            {pastClasses.length} clases pasadas.
          </Text>
          {pastClasses.length > classesToShow && (
            <Text
              onClick={onExpandClick}
              textAlign={"end"}
              fontSize={{ base: "70%", sm: "80%", md: "90%" }}
              // color={"#07A58E"}
              color={MAIN_COLOR}
              textDecoration={"underline"}
              _hover={{ cursor: "pointer" }}
            >
              Ver más clases
            </Text>
          )}
          {pastClasses.length <= classesToShow && pastClasses.length > 0 && (
            <Text
              onClick={onResetClasses}
              textAlign={"end"}
              fontSize={{ base: "70%", sm: "80%", md: "90%" }}
              // color={"#07A58E"}
              color={MAIN_COLOR}
              textDecoration={"underline"}
              _hover={{ cursor: "pointer" }}
            >
              Reiniciar
            </Text>
          )}
        </Flex>
      </Stack>
    </ChakraProvider>
  );
};

export default ListClassesTutor;
