import React, { useEffect, useState } from "react";
import {
  Stack,
  VStack,
  HStack,
  Text,
  SimpleGrid,
  Image,
  Button,
  Divider,
  Wrap,
  WrapItem,
  Flex,
} from "@chakra-ui/react";

import UserInfoModal from "./../components/UserInfoModal/UserInfoModal";
import { Images, getValidDriveLink } from "../utils";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Avatar } from "@mui/material";
import { MAIN_COLOR } from "../utils/constants";
import { getTutors } from "../utils/ApiConfig";
import { useNavigate } from "react-router-dom";

function Cards(props) {
  return (
    <HStack display={"flex"} gap={2}>
      <Image src={props.address} alt="..." />
      <Text>{props.name}</Text>
    </HStack>
  );
}

const tutorsArray = [
  {
    name: "Valentín Quezada",
    image: "",
    subject: "Matemáticas",
  },
  {
    name: "Oscar Clemente",
    image: "",
    subject: "Física",
  },
  {
    name: "Gyalpo Dongo",
    image: "",
    subject: "Biología",
  },
  {
    name: "Gyalpo Dongo",
    image: "",
    subject: "Biología",
  },
];

const Tutor = (props) => {
  return (
    <Flex align={"center"} justifyContent="center" alignItems={"center"}>
      <VStack>
        {/* <Avatar></Avatar> */}
        <Image
          src={props.image}
          alt="..."
          height={"200px"}
          borderRadius="full"
          // borderColor={MAIN_COLOR}
          // borderWidth={1}
        />
        <Text fontSize={"90%"} fontWeight={500}>
          {props.name}
        </Text>
        <Text fontSize={"80%"}>{props.subject}</Text>
      </VStack>
    </Flex>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  let [tutorsArray, setTutorsArray] = useState([]);

  useEffect(() => {
    async function get_info() {
      let res = await getTutors({
        imageDriveLink: { $ne: "", $exists: true },
        status: "Activo",
      });
      res = res?.data;

      setTutorsArray(res);
    }
    get_info();
  });
  const show_university = true;
  return (
    <>
      <Stack m={10}>
        <Text fontSize={"200%"}>
          ¡Bienvenido a IB Masters! ¿Qué quieres aprender hoy?
        </Text>
        <VStack gap={5}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            gap={10}
            justify={"space-between"}
          >
            <Stack rounded={"xl"} shadow={"md"} bgColor={"white"} p={6}>
              <HStack justify={"space-between"} alignItems={"flex-start"}>
                <Stack>
                  <Link
                    to="/recursos/ib"
                    // style={{ textDecoration: "underline" }}
                  >
                    <Text fontSize={"150%"}>Bachillerato Internacional</Text>
                  </Link>
                  <Text fontSize={"125%"}>
                    Guía obligatoria para entender el IB
                  </Text>
                </Stack>
                <Image src={Images.BACHILLERATO_IMAGES} alt="..." />
              </HStack>
              <Divider />
              <VStack>
                <SimpleGrid columns={2} spacing={10}>
                  <Cards
                    name="IB Matemáticas"
                    address={Images.RESOURCE_CALULATOR}
                  />
                  <Cards name="IB Historia" address={Images.RESOURCE_BOOK} />
                  <Cards name="IB Química" address={Images.RESOURCE_XYZ} />
                  <Cards name="IB Biología" address={Images.RESOURCE_LEAF} />
                  <Cards name="IB Inglés" address={Images.RESOURCE_EARTH} />
                  <Cards name="IB Literatura" address={Images.RESOURCE_BOOK} />
                  <Cards name="IB Física" address={Images.RESOURCE_STAR} />
                  <Cards name="IB TOK" address={Images.RESOURCE_BRAIN} />
                </SimpleGrid>
              </VStack>
            </Stack>
            {show_university && (
              <Stack rounded={"xl"} shadow={"md"} bgColor={"white"} p={6}>
                <HStack justify={"space-between"} alignItems={"flex-start"}>
                  <Stack>
                    <Link
                      to="/recursors/universidades"
                      // style={{ textDecoration: "underline" }}
                    >
                      <Text fontSize={"150%"}>Universidades americanas</Text>
                    </Link>
                    <Text fontSize={"125%"}>
                      Guía para universidades americanas
                    </Text>
                  </Stack>
                  <Image src={Images.UNIVERSIDADES_IMAGES} alt="..." />
                </HStack>
                <Divider />
                <VStack>
                  <SimpleGrid columns={2} spacing={10}>
                    <Cards
                      name="Ensayos personales"
                      address={Images.RESOURCE_EDIT}
                    />
                    <Cards
                      name="Actividades extracurriculares"
                      address={Images.RESOURCE_SAKET}
                    />
                    <Cards
                      name="Exámenes de entrada"
                      address={Images.RESOURCE_100}
                    />
                    <Cards
                      name="Cartas de recomendación"
                      address={Images.RESOURCE_MAIL}
                    />
                    <Cards
                      name="Becas y ayuda financiera"
                      address={Images.RESOURCE_DOLLAR}
                    />
                    <Cards
                      name="Lista de universidades"
                      address={Images.RESOURCE_CAP}
                    />
                  </SimpleGrid>
                </VStack>
              </Stack>
            )}
          </Flex>

          <Stack
            direction={{ base: "column", md: "row" }}
            fontSize={{ base: "1.5vw", md: "1.1vw", lg: "0.9vw", xl: "0.7vw" }}
            rounded={"xl"}
            shadow={"md"}
            // justifyContent={"space-between"}
            w={"60%"}
            px={10}
            py={4}
            bgColor={"white"}
          >
            <VStack w={"100%"}>
              <Text fontSize={"150%"} color={MAIN_COLOR}>
                Tenemos profesores increíbles para IB, ¡reserva una clase con
                ellos!
              </Text>
              <Stack w={"100%"}>
                <Splide
                  options={{
                    perPage: 3,
                    rewind: true,
                    height: "15rem",
                    gap: "2rem",
                    padding: "1rem",
                  }}
                  aria-labelledby="basic-example-heading"
                >
                  {tutorsArray.map((t, id) => (
                    <SplideSlide key={t._id}>
                      <Tutor
                        name={t.name}
                        subject={t.subject}
                        image={getValidDriveLink(t.imageDriveLink)}
                      />
                    </SplideSlide>
                  ))}
                </Splide>
              </Stack>
              <Button
                fontSize={{ base: "2vw", md: "1.4vw", lg: "1vw" }}
                bgColor={MAIN_COLOR}
                color={"white"}
                onClick={() => navigate("/reservar")}
              >
                Reservar
              </Button>
            </VStack>
          </Stack>
          <UserInfoModal />
        </VStack>
      </Stack>
    </>
  );
};

export default Dashboard;
