import React, { useState, useEffect } from "react";
import ConfirmClass from "../../components/ConfirmClass";
import { Flex } from "@chakra-ui/react";
const ConfirmClassPage = () => {
  const [classId, setClassId] = useState();
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");
    setClassId(id);
  }, []);
  if (!classId) {
    return "Cargando...";
  }
  return (
    <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
      <ConfirmClass classId={classId} />
    </Flex>
  );
};

export default ConfirmClassPage;
