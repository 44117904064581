import React, { useEffect, useState } from "react";
import { Box, VStack } from "@chakra-ui/react";
import NotionRendered from "./../components/NotionRendered/NotionRendered";

const ResourcesPage = () => {
  const PageId = "ba9a094493d7454b892ea72e6425767f";
  const mapPageUrl = (pageId) => `/recursos/info/${pageId}`;
  return (
    <VStack spacing={5}>
      <Box mt={10} width={{ base: "90%", md: "80%" }} mx={"auto"}>
        <NotionRendered notionId={PageId} mapPageUrl={mapPageUrl} />
      </Box>
    </VStack>
  );
};

export default ResourcesPage;
