import React, { useEffect, useState } from "react";
import {
  Stack,
  Text,
  Wrap,
  VStack,
  HStack,
  Avatar,
  useClipboard,
  Input,
  Flex,
  Button,
} from "@chakra-ui/react";
import { getFamilyInfo, getFamilies } from "../../utils/ApiConfig";
import { getDayInfo } from "../../utils/functions";
import { useSelector } from "react-redux";
import { MAIN_COLOR } from "../../utils/constants";

function ReferencesCard({ name, date }) {
  return (
    <HStack p={2}>
      <Avatar name={name}></Avatar>
      <Stack direction={"column"} align={"start"}>
        <Text fontSize={"90%"} fontWeight={500}>
          Familia {name}
        </Text>
        <Text fontSize={"70%"}>{date}</Text>
      </Stack>
    </HStack>
  );
}

const References = () => {
  const {
    onCopy,
    value: code,
    setValue: setCode,
    hasCopied,
  } = useClipboard("");

  const { userInfo, isLoading } = useSelector((state) => state.user);
  const [referredFamilies, setReferredFamilies] = useState([]);
  const [referralId, setReferralId] = useState("");
  const [hasFamily, setHasFamily] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    async function get_info() {
      setLoading(true);
      let hasFam = userInfo.role === "Student" || userInfo.role === "Parent";
      setHasFamily(hasFam);

      let refId;
      let referrals;
      if (hasFam) {
        let fam = await getFamilyInfo(userInfo.family);
        refId = fam.referral_id;
        referrals = fam.referrals;
      } else {
        refId = userInfo.referral_id;
        referrals = userInfo.referrals;
      }
      setLoading(false);
      setReferralId(refId);
      if (referrals && referrals.length > 0) {
        let families = await getFamilies({ _id: { $in: referrals } });
        console.log(families);
        setReferredFamilies(families);
      }
    }
    get_info();
  }, [userInfo]);
  if (isLoading || loading) {
    console.log("Cargando...");
    return "Cargando...";
  }
  if (!referralId) {
    return (
      <Stack margin={10}>
        <Text textAlign="center" color="red">
          Ooops, no pudimos encontrar un id de referidos para su cuenta. Esto no
          debería pasar, porfavor contáctese con gerencia para solucionar esto.
        </Text>
      </Stack>
    );
  }
  return (
    <>
      <Stack p={10}>
        <Stack gap={10} w={{ base: "90vw", md: "60vw" }} alignSelf={"center"}>
          <Stack>
            <Text fontSize={{ base: "150%", md: "200%" }} fontWeight={500}>
              ¡Refiere a tus amigos!
            </Text>
            <Stack p={10} bgColor={"white"} rounded={"lg"} boxShadow={"md"}>
              <VStack>
                <Wrap>
                  <Text>¡Comparte este código con tus amigos y reciban</Text>
                  <Text color={MAIN_COLOR}> $10 USD</Text>
                  <Text> de descuento cuando ellos compren sus primeras</Text>
                  <Text color={MAIN_COLOR}> 4 horas </Text>
                  <Text>de clase!</Text>
                </Wrap>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  mb={2}
                  align={"center"}
                >
                  <Input
                    placeholder={referralId}
                    value={referralId}
                    mr={2}
                    isDisabled
                  />
                  <Button
                    value={referralId}
                    px={7}
                    color="white"
                    bgColor={MAIN_COLOR}
                    onClick={(e) => {
                      onCopy();
                      setCode(e.target.value);
                    }}
                  >
                    {hasCopied ? "Código copiado!" : "Copiar código"}
                  </Button>
                </Stack>
                <Text>
                  En total {hasFamily ? "su familia" : "usted"} ha referido{" "}
                  <b>{referredFamilies.length}</b> familia
                  {referredFamilies.length !== 1 && `s`}, y ganado{" "}
                  <b>${10 * referredFamilies.length}</b>!
                </Text>
                {/* <Text>
                  Tu familia ha referido <b>{referredFamilies.length}</b> veces,
                  asi que tienes <b>${10 * referredFamilies.length}</b> para
                  usar en futuras clases!
                </Text> */}
              </VStack>
            </Stack>
          </Stack>
          <Stack>
            <Text fontSize={{ base: "150%", md: "200%" }} fontWeight={500}>
              Mis referidos
            </Text>
            {referredFamilies.map((referredFamily) => (
              <ReferencesCard
                key={referredFamily._id}
                name={referredFamily.surname}
                date={getDayInfo(referredFamily.created)}
              />
            ))}
            {/* <ReferencesCard name="Juana García" date="01/02/2023" />
            <ReferencesCard name="Alessandra Flores" date="24/01/2023" />
            <ReferencesCard name="Javier Martínez" date="23/01/2023" /> */}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default References;
