import React, { useState, useEffect } from "react";
import {
  Center,
  Flex,
  Text,
  useDisclosure,
  Box,
  VStack,
  Button,
} from "@chakra-ui/react";
import { getClass, getStudents } from "../../utils/ApiConfig";
import ViewTutorFeedbackModal from "../Clases/ViewTutorFeedbackModal";
import { feedbackToData } from "../../utils";

const TutorFeedbackPage = () => {
  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCloseView,
  } = useDisclosure();
  const [foundClass, setFoundClass] = useState({});
  useEffect(() => {
    async function get_info() {
      let params = new URLSearchParams(window.location.search);
      let classId = params.get("id");
      let cls = await getClass(classId);
      if (cls?._id) {
        setFoundClass(cls);
        onOpenView();
        // if (!cls.tutorFeedback) {
        //   onOpen();
        // } else {
        //   onOpenView();
        // }
      }
    }
    get_info();
  }, []);
  if (!foundClass._id) {
    return "Cargando...";
  }
  return (
    <>
      {/* <VStack
        p={5}
        gap={5}
        marginTop={5}
        borderRadius={12}
        alignItems={"flex-start"}
        overflowY={"scroll"}
        scrollBehavior={"smooth"}
        backgroundColor={"#4444441A"}
        maxH={"50vh"}
      >
        {foundClass.tutorFeedback ? (
          feedbackToData(foundClass.tutorFeedback).map(([q, a], index) => (
            <Box
              key={`item-${index}`}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Text fontSize={"lg"} fontWeight={500}>
                {q}
              </Text>
              <Text fontSize={"md"} fontWeight={400}>
                {a}
              </Text>
            </Box>
          ))
        ) : (
          <Text>El tutor aún no manda su reporte. Este atento!</Text>
        )}

      </VStack> */}
      <Flex alignItems="center" justifyContent={"center"}>
        <Button onClick={onOpenView} m={5} alignSelf="center">
          Ver reporte
        </Button>
      </Flex>
      <ViewTutorFeedbackModal
        isOpen={isOpenView}
        onOpen={onOpenView}
        onClose={onCloseView}
        tutorFeedback={foundClass.tutorFeedback}
      />
    </>
  );
};

export default TutorFeedbackPage;
