import React from "react";
import { useParams } from "react-router-dom";
import NotionRendered from "../components/NotionRendered/NotionRendered";
import { Box, VStack } from "@chakra-ui/react";

const CustomPageForNotion = () => {
  const { notionId } = useParams();
  const mapPageUrl = (pageId) => `/customPage/${pageId}`;

  return (
    <>
      <VStack spacing={5}>
        <Box mt={10} width={{ base: "90%", md: "80%" }} mx={"auto"}>
          <NotionRendered notionId={notionId} mapPageUrl={mapPageUrl} />
        </Box>
      </VStack>
    </>
  );
};

export default CustomPageForNotion;
