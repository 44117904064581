import React from "react";
import {
  Stack,
  Button,
  Text,
  FormLabel,
  Input,
  useClipboard,
  useToast,
  VStack,
  Box,
  Avatar,
  WrapItem,
} from "@chakra-ui/react";
import { getFamilyInfo, sendInviteToFamily } from "../../utils/ApiConfig";
import { useState } from "react";
import { validateEmail } from "../../utils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import FamilyMember from "./Family Member/FamilyMember";
import { MAIN_COLOR } from "../../utils/constants";

const Family = () => {
  const {
    onCopy,
    value: code,
    setValue: setCode,
    hasCopied,
  } = useClipboard("");

  const { userInfo, isFetching } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [family, setFamily] = useState();
  const [familyMembers, setFamilyMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  useEffect(() => {
    async function getFamily() {
      const data = await getFamilyInfo(userInfo?.family);
      setFamily(data);
      const parent = data.parents.map((parents) => {
        return { parentId: parents, role: "Parent" };
      });

      const child = data.students.map((child) => {
        return { studentId: child, role: "Student" };
      });

      const allMembers = [...parent, ...child];

      setFamilyMembers(allMembers);
    }
    getFamily();
  }, [isFetching]);

  const SendInviteToFamilyHandler = async () => {
    if (!validateEmail(email)) {
      return toast({
        title: "Enter a vaild email",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(true);

    const params = { sender: userInfo, email: email.trim() };
    const response = await sendInviteToFamily(params);
    setIsLoading(false);
    if (!response.data.success) {
      toast({
        title: response.data.message,
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setEmail("");
      toast({
        title: response.data.message,
        status: "success",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack p={10} w={{ base: "100vw", md: "70vw", lg: "70vw" }} h={"100vh"}>
        <Text
          textAlign={{ base: "center", md: "start" }}
          fontSize={"200%"}
          fontWeight={700}
        >
          Familia
        </Text>
        <Text
          fontSize={"110%"}
          fontWeight={500}
          textAlign={{ base: "center", md: "start" }}
        >
          Código de familia
        </Text>
        <Stack w={{ base: "80vw", md: "50vw", lg: "30vw" }} paddingTop={5}>
          <Stack
            direction={{ base: "column", md: "row" }}
            mb={2}
            align={"center"}
          >
            <Input
              placeholder={family?.join_family_id}
              value={family?.join_family_id}
              mr={2}
              isDisabled
            />
            <Button
              value={family?.join_family_id}
              px={7}
              color="white"
              bgColor={MAIN_COLOR}
              onClick={(e) => {
                onCopy();

                setCode(e.target.value);
              }}
            >
              {hasCopied ? "Código copiado!" : "Copiar código"}
            </Button>
          </Stack>
          {/* <Stack justify={'space-between'} align={'center'} direction={'row'}>
                    <FormLabel>Nombres</FormLabel>
                    <Input w={'70%'}  />            
                </Stack>
                <Stack justify={'space-between'} align={'center'} direction={'row'}>
                    <FormLabel>Apellidos</FormLabel>
                    <Input w={'70%'}  />            
                </Stack> */}
          <Text
            fontSize={"110%"}
            fontWeight={500}
            textAlign={{ base: "center", md: "start" }}
          >
            Agregar miembro a familia
          </Text>
          <Stack justify={"space-between"} align={"center"} direction={"row"}>
            <FormLabel>E-mail</FormLabel>
            <Input
              value={email}
              placeholder="ex: family@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              w={"70%"}
            />
          </Stack>
          <Button
            color={"white"}
            onClick={SendInviteToFamilyHandler}
            bgColor={MAIN_COLOR}
            alignSelf={"end"}
            isLoading={isLoading}
          >
            Invitar
          </Button>
        </Stack>
        <VStack
          marginTop={5}
          gap={4}
          alignSelf={"flex-start"}
          alignItems={"flex-start"}
        >
          <Text fontSize={"2xl"} fontWeight={500}>
            Mi familia
          </Text>
          <FamilyMember FamilyMembers={familyMembers} />
        </VStack>
      </Stack>
    </>
  );
};

export default Family;
