import React, { useState, useEffect } from "react";
import { Button, useDisclosure, Flex, Text } from "@chakra-ui/react";
import AcceptClassModal from "../pages/Clases/AcceptClassModal";
import RejectClassModal from "../pages/Clases/RejectClassModal";
import { getClass } from "../utils/ApiConfig";
import { extractMoreInfoFromClass } from "../utils";

const ConfirmClass = ({ classId }) => {
  const [fullClass, setFullClass] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingFullClass, setLoadingFullClass] = useState(true);
  useEffect(() => {
    async function get_info() {
      console.log("ClassId");
      console.log(classId);
      let cls = await getClass(classId);
      setLoading(false);
      if (!cls?._id) {
        setLoadingFullClass(false);
        return;
      }
      let full = await extractMoreInfoFromClass(cls, "", true);
      setLoadingFullClass(false);
      setFullClass(full);
    }
    get_info();
  }, []);
  const {
    isOpen: isOpenAccept,
    onOpen: onOpenAccept,
    onClose: onCloseAccept,
  } = useDisclosure();
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  if (loading) {
    return "Cargando..";
  }
  if (loadingFullClass) {
    return "Cargando un poco más...";
  }
  if (!fullClass?.class) {
    return "No se encontró una clase con este id, probablemente se rechazó";
  }
  return (
    <Flex alignItems="center" flexDir="column">
      <AcceptClassModal
        isOpen={isOpenAccept}
        subjectDescription={fullClass.info.subjectDescription}
        timeDescription={fullClass.info.timeDescription}
        participantMessage={fullClass.info.participantMessage}
        onClose={onCloseAccept}
        foundClass={fullClass.class}
        onSuccess={() => {
          window.location.reload(false);
        }}
      />
      <RejectClassModal
        isOpen={isOpenReject}
        subjectDescription={fullClass.info.subjectDescription}
        timeDescription={fullClass.info.timeDescription}
        participantMessage={fullClass.info.participantMessage}
        onClose={onCloseReject}
        foundClass={fullClass.class}
        onSuccess={() => {
          window.location.reload(false);
        }}
      />
      <div>Elige una de las siguientes opciones (se mostrará más info)</div>
      <Text>
        {fullClass.info.subjectDescription} con{" "}
        {fullClass.info.participantMessage}
      </Text>
      {fullClass.class.pending ? (
        <Flex>
          <Button
            onClick={onOpenAccept}
            bgColor={"green"}
            color="white"
            marginRight={10}
          >
            Aceptar
          </Button>
          <Button onClick={onOpenReject} bgColor="red" color="white">
            Rechazar
          </Button>
        </Flex>
      ) : (
        <div>Clase confirmada!</div>
      )}
    </Flex>
  );
};

export default ConfirmClass;
