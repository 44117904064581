import { useEffect, useState, useReducer, useRef } from "react";
import {
  TextField,
  Button,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from "../../data/events";
import {
  ChakraProvider,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button as ChakraButton,
  ModalBody,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  DatePicker,
  FormControl,
  FormLabel,
  Select,
  TimePicker,
  Input,
  Box,
  ModalHeader,
  ModalFooter,
  VStack,
  Textarea,
  useToast,
} from "@chakra-ui/react";

// import { ModalTesting } from "../../pages";
import moment from "moment";
import "moment/locale/es";
import { useDispatch, useSelector } from "react-redux";
import {
  getTutorDetail,
  getSubjectById,
  createNewClass,
  isValidClass,
} from "../../utils/ApiConfig.js";
import {
  PENDING_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
  PAST_CLASS_COLOR,
  MAIN_COLOR,
} from "../../utils/constants";
import {
  getBlockingEventsReserve,
  getValidDriveLink,
} from "../../utils/functions";

import "./ReserveTutorSchedule.css";
// import ReserveDialogModal from "./ReserveDialogModal";

// import PendingClassModal from "./PendingClassModal";
// import PastClassModal from "./PastClassModal";
// import FutureClassModal from "./FutureClassModal";
// import "./CalendarClasses.css";
import esLocale from "date-fns/locale/es";

const CustomEditor = ({
  scheduler,
  tutorDetail,
  subjectDetail,
  // setClassTimeForm,
  // ClassTimeForm,
  selectedStudent, //This class's student
}) => {
  const { userInfo } = useSelector((state) => state.user);

  const event = scheduler.edited;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(scheduler.state.start.value);
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [duration, setDuration] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();

  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();

  const [errorMessage, setErrorMessage] = useState("");

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const DescriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const formattedStartDate = moment(startDate)
    .locale("es")
    .format("ddd, MMM D, YYYY h:mm A");
  // formattedStartDate.locale("es");

  const startTime = moment(startDate).format("HH:mm:ss");

  const startDateTimeString = moment(startDate).format("YYYY-MM-DD");
  const endDateTime = moment(startDateTimeString + " " + startTime).add(
    duration,
    "hours"
  );

  const formattedEndDate = moment(endDateTime)
    .locale("es")
    .format("ddd MMM DD YYYY HH:mm:ss");
  // formattedEndDate.locale("es");
  const endDateObj = moment(
    formattedEndDate,
    "ddd MMM DD YYYY HH:mm:ss"
  ).toDate();

  const start = moment(startDate).format("dddd, MMMM D, h:mm a");
  const end = moment(endDateObj).format("h:mm a");

  const formattedDatetoShow = `${start} - ${end}`;
  const handleAccept = async () => {
    const newClassObject = {
      tutor: tutorDetail,
      subject: subjectDetail,
      students: [selectedStudent],
      parents: [],
      prevInfo: description,
      event: {
        start: startDate,
        end: endDate,
      },
    };
    let isValid = await isValidClass(tutorDetail._id, startDate, endDate);
    if (!isValid.valid) {
      setErrorMessage(
        "Este horario interfiere con los horarios disponibles del tutor!"
      );
      onOpenError();
      setIsLoading(false);
      onClose();
      return;
    }

    const { success, data } = await createNewClass(newClassObject);
    setIsLoading(false);
    onClose();

    if (!success) {
      setErrorMessage(data.response.data.message);
      onOpenError();
    } else {
      onOpenSuccess();
      let newClass = data;
      console.log("Created class");
      console.log(newClass);

      //Add it (temporarily) to the schedule
      try {
        // scheduler.loading(true);
        const NewClassCreate = await new Promise((res) => {
          setTimeout(() => {
            res({
              event_id: event?.event_id || Math.random(),
              title: subjectDetail?.name,
              start: startDate,
              end: endDate,
              description,
            });
          }, 3000); //so that it "feels" like 5s
        });

        scheduler.onConfirm(NewClassCreate, event ? "edit" : "create");
        scheduler.close();
      } finally {
        scheduler.loading(false);
      }
    }
  };

  useEffect(() => {
    setEndDate(endDateObj);
  }, [duration]);

  const handleSubmit = async () => {
    try {
      // scheduler.loading(true);

      onOpen();
    } finally {
      scheduler.loading(false);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const ReserveModal = () => {
    return (
      <>
        <ChakraProvider>
          <Modal size={"xl"} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody w={"100%"} className="modal-reserve">
                <Stack p={10} align={"center"} gap={5}>
                  <Text fontSize={"120%"} fontWeight={500}>
                    ¿Deseas reservar esta clase?
                  </Text>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    align={"center"}
                    gap={5}
                  >
                    <Avatar
                      size={"xl"}
                      name={tutorDetail?.name}
                      src={getValidDriveLink(tutorDetail?.imageDriveLink)}
                    >
                      {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                    </Avatar>
                    <Stack>
                      <Text fontSize={"85%"}>{subjectDetail?.name}</Text>
                      <Text
                        fontSize={"70%"}
                      >{`con ${tutorDetail?.name} ${tutorDetail?.surname}`}</Text>
                      <Text fontSize={"80%"} color={MAIN_COLOR}>
                        {formattedDatetoShow}
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack direction gap={5}>
                    <ChakraButton
                      isLoading={isLoading}
                      bgColor={MAIN_COLOR}
                      color={"white"}
                      onClick={() => {
                        // onClose();
                        setIsLoading(true);
                        handleAccept();
                      }}
                    >
                      Aceptar
                    </ChakraButton>{" "}
                    <ChakraButton
                      isDisabled={isLoading}
                      onClick={() => {
                        onClose();
                        // scheduler.close();
                      }}
                    >
                      Volver
                    </ChakraButton>
                  </Stack>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      </>
    );
  };
  const ROLE_TO_MESSAGES = {
    note_placeholder: {
      Student: "En esta clase quiero...",
      Parent: `En esta clase quiero que ${selectedStudent.name} ...`,
    },
  };
  if (!userInfo) {
    return "Cargando...";
  }
  return (
    <ChakraProvider>
      <VStack
        marginTop={{ base: "auto", md: "initial" }}
        marginBottom={{ base: "auto", md: "initial" }}
        minH={{ base: "60vh", md: "initial" }}
        alignSelf={"center"}
        border={{ base: "1px solid grey", md: "initial" }}
        // backgroundColor={{ base: "", md: "initial" }}
        overflow={"hidden"}
        width={{ base: "80vw", md: "50vw" }}
        alignItems={"flex-start"}
        px={{ base: 5, md: 10 }}
        rounded={"lg"}
        gap={3}
        py={5}
        style={{ zIndex: 100000 }}
      >
        <Text fontSize={"xl"} fontWeight={500}>
          {selectedStudent.name}: Reserva una clase
        </Text>
        <Box
          width={"100%"}
          gap={5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          flexDir={{ base: "column", md: "row" }}
        >
          <FormControl>
            <FormLabel>Cuándo empieza</FormLabel>
            <Text
              whiteSpace={"nowrap"}
              fontSize={"lg"}
              fontWeight={400}
            >{`${formattedStartDate}`}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>Duración</FormLabel>
            <Select value={duration} onChange={handleDurationChange}>
              <option value={1}>1 hora</option>
              <option value={1.5}>Hora y media</option>
              <option value={2}>2 horas</option>
              <option value={2.5}>2 horas y media</option>
              <option value={3}>3 horas</option>
            </Select>
          </FormControl>
        </Box>

        <FormControl>
          <FormLabel>Notas para el tutor</FormLabel>
          <Textarea
            backgroundColor={"#F3F3F3"}
            width={"100%"}
            minH="100px"
            value={description}
            onChange={DescriptionHandler}
            placeholder={ROLE_TO_MESSAGES.note_placeholder[userInfo.role]}
            size={{ base: "sm", md: "md" }}
          />
        </FormControl>
        <Box gap={10} display={"flex"} alignSelf={"flex-end"}>
          <ChakraButton onClick={scheduler.close}>Cancelar</ChakraButton>
          <ChakraButton
            onClick={handleSubmit}
            color={"white"}
            bgColor={MAIN_COLOR}
          >
            Confirmar
          </ChakraButton>
        </Box>
        <SuccessCreatingClassModal
          isOpen={isOpenSuccess}
          onOpen={onOpenSuccess}
          onClose={onCloseSuccess}
        />
        <ErrorCreatingClassModal
          isOpen={isOpenError}
          onOpen={onOpenError}
          onClose={onCloseError}
          errorMessage={errorMessage}
        />
        <ReserveModal />
      </VStack>
    </ChakraProvider>
  );
};

const ErrorCreatingClassModal = ({ isOpen, onOpen, onClose, errorMessage }) => {
  return (
    <ChakraProvider>
      <Modal size={"xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody w={"100%"} className="modal-reserve">
            <Stack p={10} align={"center"} gap={5}>
              <Text fontSize={"120%"} fontWeight={500}>
                Hubo un error agendando esta clase 😨
              </Text>
              <Stack
                direction={{ base: "column", md: "row" }}
                align={"center"}
                gap={5}
              >
                <Stack>
                  <Text fontSize={"85%"} color="red" textAlign="center">
                    {errorMessage}
                  </Text>
                </Stack>
              </Stack>
              <Text>Por favor vuelve a intentarlo</Text>
              <Stack direction gap={5}>
                <ChakraButton onClick={onClose}>Volver</ChakraButton>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
const SuccessCreatingClassModal = ({ isOpen, onOpen, onClose }) => {
  return (
    <ChakraProvider>
      <Modal size={"xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody w={"100%"} className="modal-reserve">
            <Stack p={10} align={"center"} gap={5}>
              <Text fontSize={"120%"} fontWeight={500}>
                Se agendó la clase correctamente 😎
              </Text>
              <Stack
                direction={{ base: "column", md: "row" }}
                align={"center"}
                gap={5}
              >
                <Stack>
                  <Text fontSize={"85%"} color="green" textAlign="center">
                    Ahora solo tiene que esperar que el tutor lo confirme. Feliz
                    aprendizaje! 🤓
                  </Text>
                </Stack>
              </Stack>
              <Text fontSize={"100%"} fontWeight={500}>
                Esta ventana se cerrará en 5s
              </Text>
              <Stack direction gap={5}>
                <ChakraButton onClick={onClose}>Volver</ChakraButton>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
function ReserveTutorSchedule({
  //   width,
  // setClassTimeForm,
  // ClassTimeForm,
  tutorDetail,
  subjectDetail,

  subjectId,
  tutorId,
  selectedStudent, //This class's student
  //   pendingClasses,
  //   futureClasses,
  //   pastClasses,
}) {
  const reserveCalendarRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleScheduleClick(schedule) {
    const startTime = new Date(schedule.start);
    console.log("Start Time:", startTime);
  }
  const [calendarEvents, setCalendarEvents] = useState([]);

  //https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate

  useEffect(() => {
    let allClasses = [];
    // let calendarFormat = allClasses.map((currClass) => ({
    //   title: currClass.info.subjectDescription,
    //   start: new Date(currClass.class.start),
    //   end: new Date(currClass.class.end),
    //   type: currClass.info.type,
    //   ...currClass.calendar,
    //   extra: currClass, //Save info for later use
    // }));
    // setCalendarEvents(calendarFormat);
    setLoading(false);
  }, []);

  const getRemoteEvents = async ({ start, end, view }) => {
    console.log("Fetching remote events");
    console.log(start);
    console.log(end);

    let calendarEvents = await getBlockingEventsReserve(
      start,
      end,
      tutorDetail._id,
      selectedStudent._id
    );
    console.log("Final calendar events;");
    console.log(calendarEvents);
    return calendarEvents;
  };
  if (!selectedStudent?._id) {
    return "Buscando info de usuario...";
  }
  return loading ? (
    "Cargando..."
  ) : (
    <div className="reserve-scheduler-container">
      <Scheduler
        ref={reserveCalendarRef}
        translations={{
          navigation: {
            month: "Mes",
            week: "Semana",
            day: "Día",
            today: "Hoy",
          },
          form: {
            addTitle: "Agregar evento",
            editTitle: "Editar evento",
            confirm: "Confirmar",
            delete: "Borrar",
            cancel: "Cancelar",
          },
          event: {
            title: "Título",
            start: "Empieza",
            end: "Termina",
            allDay: "Todo el día",
          },
          moreEvents: "Más...",
          loading: "Cargando...",
        }}
        onScheduleClick={handleScheduleClick}
        // events={calendarEvents}
        getRemoteEvents={getRemoteEvents}
        // draggable
        // view={width ? "day" : "week"}
        month={null}
        day={null}
        locale={esLocale}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: new Date().getDay(),
          startHour: 0,
          endHour: 23,
          step: 30,
          cellRenderer: ({ height, start, onClick, end, ...props }) => {
            // Fake some condition up
            const hour = start.getHours();
            const disabled = true; //hour === 14;
            const restProps = disabled ? {} : props;
            return (
              <Button
                style={{
                  height: "100%",
                  // backgroundColor: "rgba(0, 0, 0, 0.5)",
                  // background: disabled ? "#eee" : "transparent",
                  // cursor: disabled ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  // if (disabled) {
                  //   //     handleClick();
                  //   //     return <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  //   //     This slot is already booked!
                  //   //   </Alert>;
                  //   // setOpen(true);
                  //   return setOpen(true);
                  // }
                  onClick();
                }}
                disableRipple={disabled}
                // disabled={disabled}
                // {...restProps}
              ></Button>
            );
          },
        }}
        //border-width:0;border-style:solid;box-sizing:border-box

        // loading={loading}
        // Put back
        customEditor={(scheduler) => (
          <div style={{ zIndex: 10000 }}>
            <CustomEditor
              subjectDetail={subjectDetail}
              tutorDetail={tutorDetail}
              scheduler={scheduler}
              selectedStudent={selectedStudent}
            />
          </div>
        )}

        // viewerExtraComponent={(fields, event) => {
        //   console.log(fields);
        //   console.log(event);
        //   return (
        //     <>
        //       <ChakraProvider>
        //         {event.type === "pending" ? (
        //           <PendingClassModal event={event} />
        //         ) : event.type === "past" ? (
        //           <PastClassModal event={event} />
        //         ) : event.type === "future" ? (
        //           <FutureClassModal event={event} />
        //         ) : (
        //           <></>
        //         )}
        //       </ChakraProvider>
        //     </>
        //   );
        // }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          No se puede agregar eventos!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ReserveTutorSchedule;
