import { Box, Image, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Images } from "../../utils";
import { getSubjectById } from "../../utils/ApiConfig";

const TutorSubjectList = ({ subjectId }) => {
  const [loading, setLoading] = useState(false);
  const [subjectDetail, setSubjectDetail] = useState(null);
  useEffect(() => {
    async function getTutor() {
      setLoading(true);
      const response = await getSubjectById(subjectId);
      setSubjectDetail(response?.data);
      setLoading(false);
    }
    getTutor();
  }, [subjectId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box display={"flex"} gap={3} alignItems={"center"}>
      <Image
        width={"1.4rem"}
        height={"1.4rem"}
        src={Images.RESOURCE_CALULATOR}
      />
      <Text>{subjectDetail?.name}</Text>
    </Box>
  );
};

export default TutorSubjectList;
