import React, { useState, useEffect } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import {
  ChatIcon,
  CloseIcon,
  HamburgerIcon,
  CalendarIcon,
  ExternalLinkIcon,
  CheckIcon,
  InfoOutlineIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import ModalRating from "../../components/ModalRating";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CalendarClassesTutor from "./CalendarClassesTutor.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import SelectStudent from "./SelectStudent";
import {
  getClasses,
  getSubjectById,
  getTutorDetail,
} from "../../utils/ApiConfig.js";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
  getHumanReadableTimeUntil,
  extractMoreInfoFromClass,
} from "../../utils/functions.js";

import CancelClassModal from "./CancelClassModal";
import ListClassesTutor from "./ListClassesTutor";
import ClassWithCountdown from "./ClassWithCountdown";
import { MAIN_COLOR } from "../../utils/constants";

const ClasesTutor = () => {
  const [pendingClasses, setPendingClasses] = useState([]);
  const [futureClasses, setFutureClasses] = useState([]);
  const [pastClasses, setPastClasses] = useState([]);

  const [list, setList] = useState(true);
  const [width] = useMediaQuery("(max-width: 650px)");
  const { userInfo } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const getOwnClasses = async (params, invert_order = false) => {
    if (!userInfo?._id) {
      return [];
    }
    let res;
    if (!invert_order) {
      res = await getClasses({
        tutorId: userInfo._id,
        ...params,
      });
    } else {
      res = await getClasses(
        {
          tutorId: userInfo._id,
          ...params,
        },
        {},
        [],
        true
      );
    }
    return res ? res.data : [];
  };
  useEffect(() => {
    if (!userInfo._id) {
      return;
    }
    setPendingClasses([]);
    setFutureClasses([]);
    setPastClasses([]);
    setLoading(true);

    const updateDataFromDb = async () => {
      let today = new Date();
      let pending = await getOwnClasses({
        pending: true,
        end: { $gte: today }, //has not ended and it's pending
      });
      pending = await Promise.all(
        pending.map((pendingClass) =>
          extractMoreInfoFromClass(pendingClass, "pending", true)
        )
      );
      let future = await getOwnClasses({
        pending: false,
        end: { $gte: today }, //hasn't ended yet
      });
      future = await Promise.all(
        future.map((futureClass) =>
          extractMoreInfoFromClass(futureClass, "future", true)
        )
      );
      let past = await getOwnClasses(
        { pending: false, end: { $lt: today } }, //has ended
        true
      );
      past = await Promise.all(
        past.map((pastClass) =>
          extractMoreInfoFromClass(pastClass, "past", true)
        )
      );

      setPendingClasses(pending);
      setFutureClasses(future);
      setPastClasses(past);

      setLoading(false);
    };
    updateDataFromDb();
  }, [userInfo]); // Load when user is logged in

  if (!userInfo?._id) {
    return <></>;
  }
  return (
    <div style={{ padding: 30 }}>
      <ChakraProvider>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize={"150%"} fontWeight={500}>
            Tus clases
          </Text>
          {userInfo._id && (
            <Text fontSize="lg" as="u">
              Haz dado{" "}
              <Text fontWeight={"bold"} as="span">
                {pastClasses.length}
              </Text>{" "}
              clases, tienes{" "}
              <Text fontWeight={"bold"} color="red" as="span">
                {pendingClasses.length}
              </Text>{" "}
              por confirmar y{" "}
              <Text fontWeight={"bold"} as="span">
                {futureClasses.length}
              </Text>{" "}
              clases por venir!
            </Text>
          )}
        </Flex>
        {loading ? (
          <Center spacing={4}>
            <Spinner boxSize={24} />
            <Text fontSize="lg" ml={3}>
              Cargando ...
            </Text>
          </Center>
        ) : (
          <>
            {futureClasses.length > 0 && (
              <ClassWithCountdown
                // link={futureClasses[0].length}
                // name="Matemáticas IB en 2 meses"
                name={futureClasses[0].info.subjectDescription}
                participantMessage={futureClasses[0].info.participantMessage}
                schedule={futureClasses[0].info.timeDescription}
                tutorName={futureClasses[0].info.tutorInfo.name}
                tutorImage={getValidDriveLink(
                  futureClasses[0].info.tutorInfo.imageDriveLink
                )}
                startTime={futureClasses[0].class.start}
                endTime={futureClasses[0].class.end}
                classId={futureClasses[0].class._id}
                isTutor={true}
                // schedule="Jueves 09 de marzo a las 15:00 hrs."
              />
            )}
            <HStack
              borderBottom={"0.7px solid black"}
              paddingTop={7}
              gap={5}
              justifyContent={"start"}
            >
              <Stack>
                <Text
                  paddingBottom={"10px"}
                  // borderBottom={list ? "2px solid #07A58E" : ""}
                  borderBottom={list ? `2px solid ${MAIN_COLOR}` : ""}
                  // color={list ? "#07A58E" : "black"}
                  color={list ? MAIN_COLOR : "black"}
                  cursor={"pointer"}
                  fontWeight={500}
                  fontSize={"150%"}
                  onClick={() => {
                    setList(true);
                  }}
                >
                  <HamburgerIcon /> Lista
                </Text>
              </Stack>
              <Stack>
                <Text
                  paddingBottom={"10px"}
                  // borderBottom={!list ? "2px solid #07A58E" : ""}
                  borderBottom={!list ? `2px solid ${MAIN_COLOR}` : ""}
                  cursor={"pointer"}
                  fontWeight={500}
                  fontSize={"150%"}
                  // color={list ? "black" : "#07A58E"}
                  color={list ? "black" : MAIN_COLOR}
                  onClick={() => {
                    setList(false);
                  }}
                >
                  <CalendarIcon /> Calendario
                </Text>
              </Stack>
            </HStack>
            {list && (
              <ListClassesTutor
                pendingClasses={pendingClasses}
                futureClasses={futureClasses}
                pastClasses={pastClasses}
                width={width}
              />
            )}
          </>
        )}
      </ChakraProvider>
      {!list && (
        <CalendarClassesTutor
          pendingClasses={pendingClasses}
          futureClasses={futureClasses}
          pastClasses={pastClasses}
          width={width}
        />
      )}
    </div>
  );
};

export default ClasesTutor;
