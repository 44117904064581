import React, { useEffect, useState } from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import NotionRendered from "./../components/NotionRendered/NotionRendered";

const AmericanUniversities = () => {
  const PageId = "5b2eb6e165b2441091ef874f531e71f6";
  const mapPageUrl = (pageId) => `/recursos/info/${pageId}`;
  return (
    <VStack spacing={5}>
      <Box mt={10} width={{ base: "90%", md: "80%" }} mx={"auto"}>
        <Text>
          Aún seguimos trabajando en esta página para darte el mejor contenido
          que podamos. Este atento!
        </Text>
        {/* <NotionRendered notionId={PageId} mapPageUrl={mapPageUrl} /> */}
      </Box>
    </VStack>
  );
};

export default AmericanUniversities;
