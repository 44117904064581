import React, { useState, useEffect } from "react";
import {
  Stack,
  Text,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import MyAccount from "./MyAccount";
import MyPayments from "./MyPayments";
import Family from "./Family";
import PaymentMethods from "./PaymentMethods";
import Historial from "./Historial";
import { useSelector } from "react-redux";
import { MAIN_COLOR } from "../../utils/constants";

const Settings = () => {
  const [category, setCategory] = useState(1);
  const { userInfo } = useSelector((state) => state.user);

  const onClickHandler1 = () => {
    setCategory(1);
    console.log(category);
  };
  const onClickHandler2 = () => {
    setCategory(2);
    console.log(category);
  };
  const onClickHandler3 = () => {
    setCategory(3);
    console.log(category);
  };
  const onClickHandler4 = () => {
    setCategory(4);
    console.log(category);
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let start = params.get("start");
    if (start) {
      setCategory(Number(start));
    }
  }, []);

  return (
    <>
      <Stack
        py={{ base: 2, md: 10 }}
        px={{ base: 3, lg: 10, xl: 100 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack w={"100%"} gap={4} p={{ base: 5, md: 10 }}>
          <Stack w={"100%"} display={{ base: "none", md: "block" }}>
            <Stack paddingBottom={3} borderBottom={"1px solid #444444"}>
              <Text
                onClick={onClickHandler1}
                fontWeight={category === 1 ? 700 : "none"}
                cursor={"pointer"}
              >
                Mi cuenta
              </Text>
            </Stack>
            {userInfo?.role === "Tutor" ? (
              <>
                <Stack paddingBottom={3} borderBottom={"1px solid #444444"}>
                  <Text
                    onClick={onClickHandler2}
                    fontWeight={category === 2 ? 700 : "none"}
                    cursor={"pointer"}
                  >
                    Historial
                  </Text>
                </Stack>
              </>
            ) : (
              <>
                <Stack paddingBottom={3} borderBottom={"1px solid #444444"}>
                  <Text
                    onClick={onClickHandler2}
                    fontWeight={category === 2 ? 700 : "none"}
                    cursor={"pointer"}
                  >
                    Mis compras
                  </Text>
                </Stack>
                {/* <Stack paddingBottom={3} borderBottom={"1px solid #444444"}>
                  <Text
                    onClick={onClickHandler3}
                    fontWeight={category === 3 ? 700 : "none"}
                    cursor={"pointer"}
                  >
                    Métodos de pago
                  </Text>
                </Stack> */}
                <Stack paddingBottom={3} borderBottom={"1px solid #444444"}>
                  <Text
                    onClick={onClickHandler4}
                    fontWeight={category === 4 ? 700 : "none"}
                    cursor={"pointer"}
                  >
                    Familia
                  </Text>
                </Stack>
              </>
            )}
          </Stack>
          <Menu>
            <MenuButton
              alignSelf={"end"}
              display={{ base: "block", md: "none" }}
              as={IconButton}
              aria-label="Options"
              icon={<SettingsIcon color={MAIN_COLOR} />}
              variant="outline"
              border={0}
            />
            <MenuList>
              <MenuItem onClick={onClickHandler1}>Mi cuenta</MenuItem>
              {userInfo?.role === "Tutor" ? (
                <>
                  <MenuItem onClick={onClickHandler2}>Historial</MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={onClickHandler2}>Mis pagos</MenuItem>
                  {/* <MenuItem onClick={onClickHandler3}>Métodos de pago</MenuItem> */}
                  <MenuItem onClick={onClickHandler4}>Familia</MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </Stack>
        <Stack m={10} bgColor={"white"} rounded={"lg"} shadow={"lg"}>
          {category === 1 ? (
            <>
              <MyAccount />
            </>
          ) : (
            <></>
          )}
          {userInfo?.role === "Tutor" ? (
            <>
              {category === 2 ? (
                <>
                  <Historial />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {category === 2 ? (
                <>
                  <MyPayments />
                </>
              ) : (
                <></>
              )}
              {category === 3 ? (
                <>
                  <PaymentMethods />
                </>
              ) : (
                <></>
              )}
              {category === 4 ? (
                <>
                  <Family />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Settings;
