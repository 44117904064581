import React, { useState, useEffect } from "react";
import { Center, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { getClass, getStudents } from "../../utils/ApiConfig";
import { extractMoreInfoFromClass } from "../../utils/functions";
import RateClassModal from "../Clases/RateClassModal";
import SelectStudent from "../Clases/SelectStudent";
// import { SelectComponent } from "../../components";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import SendTutorFeedbackModal from "../Clases/SendTutorFeedbackModal";
import ViewTutorFeedbackModal from "../Clases/ViewTutorFeedbackModal";

const TutorFeedbackPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCloseView,
  } = useDisclosure();
  const [foundClass, setFoundClass] = useState({});
  useEffect(() => {
    async function get_info() {
      let params = new URLSearchParams(window.location.search);
      let classId = params.get("id");
      let cls = await getClass(classId);
      if (cls?._id) {
        setFoundClass(cls);
        if (!cls.tutorFeedback) {
          onOpen();
        } else {
          onOpenView();
        }
      }
    }
    get_info();
  }, []);
  if (!foundClass._id) {
    return "Cargando...";
  }
  return (
    <>
      <SendTutorFeedbackModal
        foundClass={foundClass}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onFeedbackSent={() => {}}
      />
      <ViewTutorFeedbackModal
        isOpen={isOpenView}
        onOpen={onOpenView}
        onClose={onCloseView}
        tutorFeedback={foundClass.tutorFeedback}
      />
    </>
  );
};

export default TutorFeedbackPage;
