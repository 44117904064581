import { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from "../../data/events";
import {
  ChakraProvider,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button as ChakraButton,
  ModalBody,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  DatePicker,
  FormControl,
  FormLabel,
  Select,
  TimePicker,
  Input,
  Box,
  ModalHeader,
  ModalFooter,
  VStack,
  Textarea,
  useToast,
} from "@chakra-ui/react";

// import { ModalTesting } from "../../pages";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getTutorDetail, getSubjectById } from "../../utils/ApiConfig.js";
import {
  PENDING_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
  PAST_CLASS_COLOR,
} from "../../utils/constants";
import PendingClassModalTutor from "./PendingClassModalTutor";
import PastClassModalTutor from "./PastClassModalTutor";
import FutureClassModalTutor from "./FutureClassModalTutor";
import "./CalendarClasses.css";
import esLocale from "date-fns/locale/es";

function CalendarClassesTutor({
  width,
  // setClassTimeForm,
  // ClassTimeForm,
  createNewClassHandler,
  tutorDetail,
  subjectDetail,

  pendingClasses,
  futureClasses,
  pastClasses,
}) {
  const { userInfo } = useSelector((state) => state.user);

  const classCalendarRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleScheduleClick(schedule) {
    const startTime = new Date(schedule.start);
    console.log("Start Time:", startTime);
  }
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    let allClasses = [...pendingClasses, ...futureClasses, ...pastClasses];
    let calendarFormat = allClasses.map((currClass) => ({
      title: currClass.info.subjectDescription,
      start: new Date(currClass.class.start),
      end: new Date(currClass.class.end),
      type: currClass.info.type,
      ...currClass.calendar,
      extra: currClass, //Save info for later use
    }));
    setCalendarEvents(calendarFormat);
    setLoading(false);
  }, []);
  // useEffect(() => {
  //   let todayCellId = `hour-${new Date().getHours()}`;
  //   let el = document.getElementById(todayCellId);
  //   if (el) {
  //     el.scrollIntoView({ smooth: true });
  //   } else {
  //     console.log("didnt found any!");
  //   }
  // });
  if (!userInfo) {
    return "Cargando usuario...";
  }
  return loading ? (
    "Cargando..."
  ) : (
    <div className="classes-scheduler-container">
      <Scheduler
        height={300}
        ref={classCalendarRef}
        translations={{
          navigation: {
            month: "Mes",
            week: "Semana",
            day: "Día",
            today: "Hoy",
          },
          form: {
            addTitle: "Agregar evento",
            editTitle: "Editar evento",
            confirm: "Confirmar",
            delete: "Borrar",
            cancel: "Cancelar",
          },
          event: {
            title: "Título",
            start: "Empieza",
            end: "Termina",
            allDay: "Todo el día",
          },
          moreEvents: "Más...",
          loading: "Cargando...",
        }}
        onScheduleClick={handleScheduleClick}
        events={calendarEvents}
        // fetchRemoteEvents={fetchRemoteEvents}
        // draggable
        // view={width ? "day" : "week"}
        month={null}
        day={null}
        locale={esLocale}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: new Date().getDay(),
          startHour: 0,
          endHour: 23,
          step: 30,
          cellRenderer: ({ height, start, onClick, end, ...props }) => {
            // Fake some condition up
            const hour = start.getHours();
            const disabled = true; //hour === 14;
            const restProps = disabled ? {} : props;
            return (
              <Button
                id={`hour-${start.getHours()}`}
                style={{
                  height: "100%",
                  // backgroundColor: "rgba(255, 255, 255, 0.75)",
                  // background: disabled ? "#eee" : "transparent",
                  cursor: disabled ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  if (disabled) {
                    //     handleClick();
                    //     return <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    //     This slot is already booked!
                    //   </Alert>;
                    setOpen(true);
                    return setOpen(true);
                  }
                  onClick();
                }}
                disableRipple={disabled}
                // disabled={disabled}
                // {...restProps}
              ></Button>
            );
          },
        }}
        //border-width:0;border-style:solid;box-sizing:border-box

        // loading={loading}
        viewerExtraComponent={(fields, event) => {
          console.log(event.type);
          return (
            <>
              <ChakraProvider>
                {event.type === "pending" ? (
                  <PendingClassModalTutor event={event} />
                ) : event.type === "past" ? (
                  <PastClassModalTutor
                    event={event}
                    subjectInfo={event.extra.info.subjectInfo}
                    tutorInfo={event.extra.info.tutorInfo}
                    classInfo={event.extra.class}
                    subjectDescription={event.extra.info.subjectDescription}
                    timeDescription={event.extra.info.timeDescription}
                  />
                ) : event.type === "future" ? (
                  <FutureClassModalTutor event={event} />
                ) : (
                  <></>
                )}
                {/* <ModalTesting /> */}
              </ChakraProvider>
            </>
          );
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Solo se puede agregar eventos cuando se está reservando una clase!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CalendarClassesTutor;
