import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
  WrapItem,
} from "@chakra-ui/react";
import React, { useCallback, useState, useEffect } from "react";
import { Images } from "../../utils";
import { MAIN_COLOR } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSchool } from "../../utils/ApiConfig";

const MyProfile = () => {
  const { userInfo, isFetching } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [userSchool, setUserSchool] = useState("");
  useEffect(() => {
    async function get_info() {
      let schoolName;
      try {
        let school = await getSchool(userInfo.school);
        schoolName = school.name;
      } catch (e) {
        schoolName = userInfo.school;
      }
      setUserSchool(schoolName);
    }
    get_info();
  }, []);
  const EditarHandler = useCallback(() => {
    navigate("/ajustes");
  }, [navigate]);

  const ROLE_TO_NAME = {
    Student: "Estudiante",
    Parent: "Padre de familia",
  };

  if (isFetching) {
    return <Spinner size="xl" />;
  }
  return (
    <VStack
      marginTop={5}
      paddingBottom={{ base: "20", md: "0" }}
      width={{ base: "100%", md: "80%" }}
      marginLeft={"auto"}
      gap={4}
      marginRight={"auto"}
      alignSelf={"center"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        px={{ base: "10", md: "0" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"2xl"} fontWeight={"500"}>
          Mi perfil
        </Text>
        <Button
          onClick={EditarHandler}
          backgroundColor={MAIN_COLOR}
          color={"white"}
          borderRadius={"lg"}
          size="md"
        >
          Editar
        </Button>
      </Box>
      <Box
        px={10}
        width={"100%"}
        boxShadow={" 0px 0px 26.7223px rgba(179, 194, 191, 0.2)"}
        minH={"70vh"}
        backgroundColor={{ base: "#F3F3F3", md: "#ffffff" }}
        p={{ base: "0", md: "10" }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
        >
          <Box
            display={"flex"}
            width={{ base: "100%", md: "initial" }}
            justifyContent={"space-between"}
            alignItems={{ base: "center" }}
            gap={6}
            py={{ base: "5", md: "0" }}
            flexDirection={{ base: "column", md: "row" }}
            height={"max-content"}
            background={{
              base: "linear-gradient(107.45deg, #07A58E -16.34%, #0ED37B 104.33%)",
              md: "none",
            }}
            position={"relative"}
          >
            <Box
              display={{ base: "flex", md: "none" }}
              width={"100%"}
              height={"100%"}
              position={"absolute"}
              backgroundImage={{
                base: `url(${require("../../assets/BackgroundImage.png")})`,
                md: "none",
              }}
              backgroundPosition={"center "}
              backgroundRepeat={"repeat"}
            ></Box>
            <WrapItem>
              <Avatar
                border={" 3.64583px solid rgba(68, 68, 68, 0.25)"}
                size="2xl"
                name={userInfo?.name}
                // name="Segun Adebayo"
                // src={Images.AVATAR_URL}
              >
                {/* <AvatarBadge boxSize=".7em" bg="green.500" /> */}
              </Avatar>
            </WrapItem>
            <VStack
              alignItems={{ base: "center", md: "flex-start" }}
              display={"flex"}
            >
              <Text fontSize={"2xl"} fontWeight={500}>
                {`${userInfo?.name} ${userInfo?.surname}`}
              </Text>
              <Text fontSize={"lg"} fontWeight={400}>
                {ROLE_TO_NAME[userInfo?.role]}
                {/* , Perú */}
                {/* Estudiante, Perú */}
                {/* {`${userInfo?.role} ${"Peru"}`} */}
              </Text>
              <Text
                display={{ base: "none", md: "flex" }}
                fontSize={"lg"}
                fontWeight={400}
              >
                {/* 11° año del St. George’s College */}
                {userSchool}
              </Text>
            </VStack>
          </Box>
          {/* <Box
            marginTop={{ base: "5", md: "0" }}
            display={"flex"}
            alignItems={"center"}
            gap={2}
            px={{ base: "10", md: "0" }}
            py={{ base: "5", md: "0" }}
            backgroundColor={"#FFFFFF"}
            width={{ base: "100%", md: "initial" }}
          >
            <Image
              width={{ base: "3rem", md: "initial" }}
              height={{ base: "3rem", md: "initial" }}
              src={Images.MIPERFIL}
              alt="university.png"
            />
            <Text display={{ base: "flex", md: "none" }} fontSize={"lg"}>
              11° año del St. George’s College
            </Text>
          </Box> */}
        </Box>
        <Divider
          display={{ base: "none", md: "flex" }}
          marginTop={5}
          backgroundColor={"#44444440"}
          height={0.6}
        />
        {/* bottom */}
        <Box
          display={"flex"}
          marginTop={{ base: "0", md: "5" }}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          flexDir={{ base: "column", md: "row" }}
        >
          <Box
            py={{ base: "5", md: "0" }}
            px={{ base: "10", md: "0" }}
            backgroundColor={"#FFFFFF"}
            marginTop={{ base: "5", md: "0" }}
            width={{ base: "100%", md: "50%" }}
            display={"flex"}
            gap={4}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text fontSize={"2xl"} fontWeight={500}>
              Datos
            </Text>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"flex-start"}
              gap={3}
            >
              <Image
                width={"1.4rem"}
                height={"1.4rem"}
                src={Images.MIPERFIL_PHONE}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Text>Celular</Text>
                <Text>{userInfo?.phone}</Text>
              </Box>
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"flex-start"}
              gap={3}
            >
              <Image
                src={Images.RESOURCE_MAIL}
                width={"1.4rem"}
                height={"1.4rem"}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Text>E-mail</Text>
                <Text>{userInfo?.email}</Text>
              </Box>
            </Box>
            {/* <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"flex-start"}
              gap={3}
            >
              <Image
                width={"1.4rem"}
                height={"1.4rem"}
                src={Images.MIPERFIL_BALLONS}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Text>Fecha de nacimiento</Text>
                <Text>{userInfo?.birthday}</Text>
              </Box>
            </Box> */}
          </Box>
          <Box
            py={{ base: "5", md: "0" }}
            px={{ base: "10", md: "0" }}
            backgroundColor={"#FFFFFF"}
            marginTop={{ base: "5", md: "0" }}
            width={{ base: "100%", md: "50%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text fontSize={"2xl"} fontWeight={500}>
              Sobre mí
            </Text>
            <Text fontSize={"xl"} fontWeight={300}>
              {
                userInfo?.extraInfo ||
                  // <Text textDecoration={"italic"}>
                  "Puedes editar esta info en tus ajustes."
                // </Text>
              }
            </Text>
          </Box>
        </Box>
      </Box>
    </VStack>
  );
};

export default MyProfile;
