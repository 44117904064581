import { useState, useEffect } from "react";

import ReserveTutorSchedule from "./ReserveTutorSchedule";
import { getTutorDetail, getFilteredSubjects } from "../../utils/ApiConfig";
import SelectedComponent from "../../components/SelectComponent/SelectComponent.jsx";
import {
  Stack,
  Text,
  ChakraProvider,
  Divider,
  HStack,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Center,
  Container,
  Flex,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getStudents } from "../../utils/ApiConfig";
import { MAIN_COLOR } from "../../utils/constants";

const ReserveTutorClassPage = ({ match }) => {
  const { userInfo } = useSelector((state) => state.user);
  const [tutorId, setTutorId] = useState();
  //   let { tutorId } = useParams();
  const [tutor, setTutor] = useState();
  const [subjectMap, setSubjectMap] = useState({});
  const [subjectId, setSubjectId] = useState();
  const [subjects, setSubjects] = useState([]);

  // Represents the student that will receive the classes
  const [selectedStudent, setSelectedStudent] = useState({});
  const [selectedStudentId, setSelectedStudentId] = useState(); //to provoque re-render
  const [students, setStudents] = useState([]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setTutorId(params.get("id"));
    const getInfo = async () => {
      if (!userInfo) {
        return;
      }
      let tutorRes = await getTutorDetail(params.get("id"));
      let tutor = tutorRes?.data;
      console.log("tutor");
      // Set the subject options
      let subjects = await getFilteredSubjects({
        _id: { $in: tutor.subjects },
      });
      console.log("subjects found");
      console.log(subjects?.data);
      setSubjects(subjects?.data);
      console.log(
        subjects.data.map((x) => ({
          label: x.name,
          value: x._id,
        }))
      );
      setTutor(tutor);
      let map = {};
      for (let subject of subjects?.data) {
        map[subject._id] = subject;
      }
      setSubjectMap(map);

      // For parents, get all students
      if (userInfo.role === "Parent") {
        if (userInfo.students) {
          console.log(userInfo.students);
          let res = await getStudents({ _id: { $in: userInfo.students } });
          console.log(res);
          setStudents(res);
        }
      } else {
        setSelectedStudent(userInfo);
        setSelectedStudentId(userInfo._id);
      }
    };
    getInfo();
  }, [userInfo]);

  if (!tutorId || !userInfo) {
    return <Center alignItems="center">Cargando...</Center>;
  }
  const SPECIALIZED_TYPES = ["IB", "SAT", "Olimpiadas"];

  const isSpecialized = (subject) => {
    return SPECIALIZED_TYPES.includes(subject.service);
  };
  const getWhatsappLink = () => {
    // let admin_phone = "15551234567"
    // let admin_phone = "+51904509114"; //Xplain number. TODO: Put it on .env
    let admin_phone = "+51933516182";
    let text = `Hola, quiero registrar una clase con el tutor ${tutor.name}, pero no encuentro horas disponibles!`;
    let encodedText = encodeURIComponent(text);

    return `https://api.whatsapp.com/send?phone=${admin_phone}&text=${encodedText}`;
  };
  return (
    <div className="schedule__calendar">
      <div className="tutor-info">
        <ChakraProvider>
          <Center
            alignItems="center"
            fontSize={30}
            fontWeight="bold"
            margin={5}
            flexDirection={"column"}
          >
            Clases con {tutor?.name}
          </Center>
          <Flex flexDir="row" alignItems="center" justifyContent="center">
            {subjects.length > 0 && (
              <Flex
                flexDir="column"
                style={{ margin: "20px" }}
                alignItems={"center"}
              >
                <Text fontSize="80%">
                  * significa que el curso es una{" "}
                  <Text as="i" textStyle={"italic"}>
                    Tutoría Especializada
                  </Text>
                </Text>
                <SelectedComponent
                  // value={subjectId}
                  dataOptions={subjects.map((x) => ({
                    label: `${x.name} ${isSpecialized(x) ? "*" : ""}`,
                    value: x._id,
                  }))}
                  setSelectedOptions={(opt) => {
                    setSubjectId(opt.value);
                  }}
                  placeholder={"Seleccione un curso"}
                  style={{ margin: "20px", zIndex: 1000 }}
                />
              </Flex>
            )}
            {subjectId && students.length > 0 && (
              <SelectedComponent
                style={{ margin: "20px", zIndex: 1000 }}
                placeholder={"Quién tomará la clase?"}
                dataOptions={students.map((student) => ({
                  label: student.name,
                  value: student._id,
                }))}
                setSelectedOptions={(option) => {
                  let found = students.filter(
                    (student) => student._id === option.value
                  );
                  if (found && found.length > 0) {
                    setSelectedStudent(found[0]);
                    setSelectedStudentId(found[0]._id);
                  }
                }}
              />
            )}
          </Flex>
        </ChakraProvider>
      </div>
      {subjectId && selectedStudent._id && (
        <>
          {" "}
          <ChakraProvider>
            <Center
              alignItems="center"
              // fontSize={30}
              // fontWeight="bold"
              // margin={5}
              // flexDirection={"column"}
            >
              <Tooltip
                label=" Contáctanos para
            ayudarte"
              >
                {/* <Button href="/asdas">¿No ves el horario que buscabas?</Button> */}
                <Link
                  // href="https://www.google.com"
                  href={getWhatsappLink()}
                  target="_blank"
                  // bgColor="lightgray"
                  bgColor={MAIN_COLOR}
                  borderRadius={5}
                  padding={2}
                  color="white"
                >
                  ¿No ves el horario que buscabas?
                </Link>
              </Tooltip>
            </Center>
          </ChakraProvider>
          <ReserveTutorSchedule
            tutorId={tutorId}
            subjectId={subjectId}
            subjectDetail={subjectMap[subjectId]}
            tutorDetail={tutor}
            selectedStudent={selectedStudent}
            selectedStudentId={selectedStudent._id} //To provoque re-render
          />
        </>
      )}
    </div>
  );
};

export default ReserveTutorClassPage;
