import React, { useState } from "react";
import { Stack, VStack, Text, Box } from "@chakra-ui/react";
import "./Faq.css";
// import { NotionRendered } from "../../components";
import NotionRendered from "../../components/NotionRendered/NotionRendered";

// const accordionData = [
//   {
//     title: "Section 1",
//     content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
//     laborum cupiditate possimus labore, hic temporibus velit dicta earum
//     suscipit commodi eum enim atque at? Et perspiciatis dolore iure
//     voluptatem.`,
//   },
//   {
//     title: "Section 2",
//     content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
//     reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
//     quaerat iure quos dolorum accusantium ducimus in illum vero commodi
//     pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
//     quidem maiores doloremque est numquam praesentium eos voluptatem amet!
//     Repudiandae, mollitia id reprehenderit a ab odit!`,
//   },
//   {
//     title: "Section 3",
//     content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
//     quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
//     dolor ut sequi minus iste? Quas?`,
//   },
// ];

// const Accordion = ({ title, content }) => {
//   const [isActive, setIsActive] = useState(false);

//   return (
//     <div className="accordion-item">
//       <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
//         <div><Text color={isActive? '#09B98D':'#000'}>{title}</Text></div>
//         <div>{isActive ? '-' : '+'}</div>
//       </div>
//       {isActive && <div className="accordion-content">{content}</div>}
//     </div>
//   );
// };

const Faq = () => {
  const PageId = "e954291874c84c9bb1b1fcf9a416ee20";
  const mapPageUrl = (pageId) => `/recursos/info/${pageId}`;

  return (
    // <Stack p={10}>
    //   <Stack w={{base:'95vw', md:'60vw'}} alignSelf={'center'}>
    //     <Text textAlign={{base:'center', md:'start'}} fontSize={{base:'150%', md:'200%'}} fontWeight={500}>Preguntas frecuentes</Text>
    //     <div className="accordion">
    //     {accordionData.map(({ title, content }) => (
    //       <Accordion title={title} content={content} />
    //     ))}
    //   </div>
    //   </Stack>
    // </Stack>
    <VStack spacing={5}>
      <Box mt={10} width={{ base: "90%", md: "80%" }} mx={"auto"}>
        <NotionRendered notionId={PageId} mapPageUrl={mapPageUrl} />
      </Box>
    </VStack>
  );
};

export default Faq;
