import React from "react";
import { Button, HStack, Stack, Text, Image } from "@chakra-ui/react";
import { MAIN_COLOR } from "../../utils/constants";

const PaymentMethods = () => {
  return (
    <>
      <Stack p={10} w={{ base: "100vw", md: "70vw", lg: "70vw" }} h={"100vh"}>
        <Text
          textAlign={{ base: "center", md: "start" }}
          fontSize={"200%"}
          fontWeight={700}
        >
          Métodos de pago
        </Text>
        <Text textAlign={{ base: "center", md: "start" }}>
          Protegemos tu seguridad al encriptar tus datos
        </Text>
        <Stack paddingTop={5}>
          <Text fontWeight={500} fontSize={"110%"}>
            Tarjeta de crédito o débito
          </Text>
          <Stack
            justify={"space-between"}
            direction={{ base: "column", md: "row" }}
          >
            <Stack direction={"row"}>
              <Image src="#" alt="..." />
              <Text>Visa ****0830</Text>
            </Stack>
            <HStack>
              <Button color={"white"} bgColor={MAIN_COLOR}>
                Cambiar tarjeta
              </Button>
              <Button variant={"ghost"} color={"#8D8D8D"} bgColor={"#E8E8E8"}>
                Quitar
              </Button>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PaymentMethods;
