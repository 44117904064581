import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
  WrapItem,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import React, { useCallback, useState, useEffect } from "react";
import { Images } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AddIcon,
  ChevronRightIcon,
  DeleteIcon,
  InfoOutlineIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { BsCamera } from "react-icons/bs";
import TutorSubjectList from "./TutorSubjectList";
// import { SelectComponent } from "../../components";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import {
  FetchUniversidad,
  getAllSchool,
  updatePublicTutorProfile,
} from "../../utils/ApiConfig";
import TeacherProfile from "../../components/TeacherProfile";
import { getValidDriveLink } from "../../utils";
import { MAIN_COLOR } from "../../utils/constants";

const MyProfileTutor = () => {
  const { userInfo, isFetching } = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [subjectList, setSubjectList] = useState(userInfo?.subjects);
  const [editable, setEditable] = useState(false);
  const [showCameraIcon, setShowCameraIcon] = useState(false);
  const [email, setEmail] = useState(userInfo?.email);
  const [birthday, setBirthday] = useState(userInfo?.birthday || "");
  const [bandaText, setBandaText] = useState(
    "Banda 7 en Matemáticas HL, Inglés HL"
  );

  const [dropDown, setDropDown] = useState(false);
  // university text is the combination of Carrera, Universidad which is come from backend

  const [Carrera, setCarrera] = useState("Ciencia de la Computación ");
  const [Universidad, setUniversidad] = useState(null);
  const [UniversidadOptions, setUniversidadOtions] = useState(null);
  const [newUniversidadCheck, setNewUniversidadCheck] = useState(false);
  const [newUniversidad, setNewUniversidad] = useState(null); // this state
  const [description, setDescription] =
    useState(`      Lörem ipsum seng godat apregisk. Förysk ultraras. Matbil tigen har
  bloppa. Ikament monon triktigt. Fysisk cd mukbang relig läshund,
  så tredest.`);
  const [languageLevel, setLanguageLevel] = useState([
    { name: "Inglés", level: "Avanzado" },
    { name: "Francés", level: "Intermedio" },
    {
      name: "Español",
      level: "Avanzado",
    },
  ]);
  const show_email = false;

  const navigate = useNavigate();
  const EditarHandler = () => {
    setEditable(true);
  };
  const SaveChanges = () => {
    onOpen();
  };
  let embedUrl = "";
  if (userInfo?.tutorYoutubeLink) {
    const videoId = userInfo?.tutorYoutubeLink?.split("v=")[1].split("&")[0];
    embedUrl = `https://www.youtube.com/embed/${videoId}`;
  }

  const handleMouseEnter = () => {
    setShowCameraIcon(true);
  };

  const handleMouseLeave = () => {
    setShowCameraIcon(false);
  };

  const AceptarHandler = async () => {
    //change value send to the backend
    let tutorDescriptionList = [
      tutorDescriptionScores,
      tutorDescriptionAchievements,
      tutorDescriptionMajor,
      tutorDescriptionLanguage,
    ];
    // const formData = new FormData()

    // formData.append('tutorDescriptionList', JSON.stringify(tutorDescriptionList));
    // // console.log(tutorDescriptionList)
    // formData.append('id', user._id);
    // formData.append('photo', imageLink);
    // formData.append('linkedin', linkedinLink);
    // formData.append('tutorDescription', tutorDescription);
    // // formData.append('tutorDescriptionList', tutorDescriptionList);
    // if (photoObject){
    //   formData.append('photoObject', photoObject);
    // }
    // formData.append('tutorYoutubeLink', tutorYoutubeLink);
    // // let toUpdate = {
    // //     photo: imageLink,
    // //     resume: resumeLink,
    // //     tutorDescription: tutorDescription,
    // //     tutorDescriptionList: tutorDescriptionList,
    // // };
    // // logData(formData);
    // axios.patch(`${process.env.REACT_APP_BACKENDURL}/tutor/publicprofile`, formData).then((response) => window.location.reload(false)).catch(err => alert(err.response.data.message));

    let formData = {
      id: userInfo._id,
      // tutorDescriptionList: tutorDescriptionList,
      tutorSelfDescription: tutorSelfDescription,
    };
    // if (selectedFile) {
    //   formData.photoObject = selectedFile;
    // }
    let { success } = await updatePublicTutorProfile(
      formData,
      tutorDescriptionList,
      selectedFile
    );
    onClose();

    if (success) {
      onOpenConfirmation();
    } else {
      onOpenError();
    }
  };
  const VolverHandler = () => {
    onClose();
    // setEditable(false);
  };

  // language change handler
  const handleLanguageChange = (e, index) => {
    const newLanguage = [...languageLevel];
    newLanguage[index].name = e.target.value;
    setLanguageLevel(newLanguage);
  };
  const handleLanguageLevel = (e, index) => {
    const newLanguage = [...languageLevel];
    newLanguage[index].level = e.target.value;
    setLanguageLevel(newLanguage);
  };

  const addLanguageHanlder = () => {
    const newLanguage = [...languageLevel, { name: "", level: "" }];
    setLanguageLevel(newLanguage);
  };

  const handleDeleteLanguage = (index) => {
    const newLanguage = [...languageLevel];
    newLanguage.splice(index, 1);
    setLanguageLevel(newLanguage);
  };

  useEffect(() => {
    // Passing the default states
    if (userInfo.tutorDescriptionList) {
      setTutorDescriptionScores(userInfo.tutorDescriptionList[0] || "");
      setTutorDescriptionAchievements(userInfo.tutorDescriptionList[1] || "");
      setTutorDescriptionMajor(userInfo.tutorDescriptionList[2] || "");
      setTutorDescriptionLanguage(userInfo.tutorDescriptionList[3] || "");
    }
    setTutorSelfDescription(userInfo.tutorSelfDescription || "");

    async function getUniversidad() {
      try {
        const response = await FetchUniversidad();

        let allUniversidad = response?.map((item) => {
          return {
            id: item._id,
            value: item.name,
            label: item.name,
          };
        });
        allUniversidad = [
          { label: "Mi colegio no aparece", value: "Otro", id: "Otro" },
          ...allUniversidad,
        ];

        setUniversidadOtions(allUniversidad);
      } catch (err) {
        console.error(err);
      }
    }
    // getUniversidad();
  }, []);

  useEffect(() => {
    if (Universidad?.value === "Otro") {
      setNewUniversidadCheck(true);
    } else {
      setNewUniversidadCheck(false);
    }
  }, [Universidad]);

  const newUniversidadHandler = (e) => {
    setNewUniversidad({
      label: "My universidad",
      value: e.target.value,
      id: "Otro",
    });
  };
  console.log(Universidad, newUniversidad);

  // Only below is relevant (for now)

  // Description
  const [tutorDescriptionScores, setTutorDescriptionScores] = useState("");
  const [tutorDescriptionAchievements, setTutorDescriptionAchievements] =
    useState("");
  const [tutorDescriptionMajor, setTutorDescriptionMajor] = useState("");
  const [tutorDescriptionLanguage, setTutorDescriptionLanguage] = useState("");
  const [tutorYoutubeLink, setTutorYoutubeLink] = useState("");
  const [tutorSelfDescription, setTutorSelfDescription] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();
  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();

  return (
    <VStack
      marginTop={5}
      paddingBottom={{ base: "20", md: "0" }}
      width={{ base: "100%", md: "90%" }}
      marginLeft={"auto"}
      gap={4}
      marginRight={"auto"}
      alignSelf={"center"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        px={{ base: "10", md: "0" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"2xl"} fontWeight={"500"}>
          Mi perfil
        </Text>
        <Flex>
          {editable && (
            <Button
              onClick={() => {
                setEditable(false);
              }}
              backgroundColor={"red"}
              color={"white"}
              borderRadius={"lg"}
              size="md"
              marginRight={10}
            >
              Cancelar
            </Button>
          )}
          <Button
            onClick={editable ? SaveChanges : EditarHandler}
            backgroundColor={editable ? "#ABABAB" : MAIN_COLOR}
            color={"white"}
            borderRadius={"lg"}
            size="md"
          >
            {editable ? "Guardar cambios" : "Editar"}
          </Button>
        </Flex>
      </Box>

      <Box
        px={10}
        width={"100%"}
        boxShadow={" 0px 0px 26.7223px rgba(179, 194, 191, 0.2)"}
        minH={"70vh"}
        backgroundColor={{ base: "#F3F3F3", md: "#ffffff" }}
        p={{ base: "0", md: "10" }}
      >
        {!editable ? (
          <TeacherProfile tutorId={userInfo._id} />
        ) : (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={{ base: "column", md: "row" }}
              alignItems={{ base: "center" }}
            >
              <Box
                display={"flex"}
                width={{ base: "100%", md: "initial" }}
                justifyContent={"space-between"}
                alignItems={{ base: "center" }}
                gap={6}
                py={{ base: "5", md: "0" }}
                flexDirection={{ base: "column", md: "row" }}
                height={"max-content"}
                background={{
                  // base: "linear-gradient(107.45deg, #07A58E -16.34%, #0ED37B 104.33%)",
                  base: MAIN_COLOR,
                  md: "none",
                }}
                position={"relative"}
              >
                <Box
                  display={{ base: "flex", md: "none" }}
                  width={"100%"}
                  height={"100%"}
                  position={"absolute"}
                  backgroundImage={{
                    // base: `url(${require("../../assets/BackgroundImage.png")})`,
                    base: MAIN_COLOR,
                    md: "none",
                  }}
                  backgroundPosition={"center "}
                  backgroundRepeat={"repeat"}
                ></Box>
                <WrapItem>
                  <Avatar
                    border="3.64583px solid rgba(68, 68, 68, 0.25)"
                    size="2xl"
                    name={userInfo.name}
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : getValidDriveLink(userInfo.imageDriveLink)
                    }
                    position="relative"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    cursor={editable ? "pointer" : ""}
                  >
                    {editable && showCameraIcon && (
                      <BsCamera
                        style={{ zIndex: 1, position: "absolute" }}
                        size="30px"
                      />
                    )}
                    {editable && showCameraIcon && (
                      <Box
                        height="100%"
                        width="100%"
                        position="absolute"
                        background={"rgba(0, 0, 0, 0.47)"}
                        borderRadius={"50%"}
                      ></Box>
                    )}
                    {editable && (
                      <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        left="0"
                        opacity="0"
                        zIndex={5}
                        aria-hidden="true"
                        accept="image/*"
                        cursor={editable ? "pointer" : ""}
                        onChange={handleFileChange}
                      />
                    )}
                    {!editable && (
                      <Tooltip
                        label={"Presiona Editar para cambiar tu foto de perfil"}
                      >
                        <Text
                          height="100%"
                          width="100%"
                          position="absolute"
                          display={""}
                        ></Text>
                      </Tooltip>
                    )}
                    {/* <AvatarBadge boxSize=".7em" bg="green.500" /> */}
                  </Avatar>
                </WrapItem>
                <VStack
                  alignItems={{ base: "center", md: "flex-start" }}
                  display={"flex"}
                >
                  <Box display={"flex"} gap={4} alignItems={"center"}>
                    <Text
                      fontSize={"2xl"}
                      color={{ base: "white", md: "black" }}
                      fontWeight={500}
                    >
                      {`Tutor ${userInfo?.name} ${userInfo?.surname}`}{" "}
                    </Text>
                    {/**TODO: Add these rating calculation */}
                    {/* <Text display={{ base: "none", md: "flex" }}>
                      <StarIcon
                        display={{ base: "none", md: "initial" }}
                        color="#FFD800"
                      />
                      4.8(29)
                    </Text> */}
                  </Box>
                  {/* <Text
                    display={{ base: "flex", md: "none" }}
                    position={"absolute"}
                    top={5}
                    color={"white"}
                    right={10}
                  >
                    <StarIcon color="#FFD800" /> 4.8(29)
                  </Text> */}
                  {/* <Text
                    fontSize={"lg"}
                    color={{ base: "white", md: "black" }}
                    fontWeight={400}
                  >
                    {`${userInfo?.role} ${"Peru"}`}
                  </Text> */}
                  {/* {!editable && (
                    <Text
                      display={{ base: "none", md: "flex" }}
                      fontSize={"lg"}
                      color={"#07A58E"}
                      fontWeight={400}
                    >
                      {bandaText}
                    </Text>
                  )} */}
                  {editable && (
                    <Box
                      width={"100%"}
                      display={{ base: "none", md: "flex" }}
                      alignItems={"center"}
                      gap={3}
                    >
                      <Input
                        backgroundColor={"#F3F3F3"}
                        value={tutorDescriptionScores}
                        onChange={(e) =>
                          setTutorDescriptionScores(e.target.value)
                        }
                      />
                      <Popover placement="top-start" trigger="hover">
                        <PopoverTrigger>
                          <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                        </PopoverTrigger>
                        <PopoverContent w={"200px"}>
                          <PopoverBody>
                            {" "}
                            <Text fontSize={"70%"} textAlign={"start"}>
                              Aqui sses un buen lugar para que coloques notas en
                              IB y/o SAT
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                  )}
                  {editable && (
                    <Box
                      width={"100%"}
                      display={{ base: "none", md: "flex" }}
                      alignItems={"center"}
                      gap={3}
                    >
                      <Input
                        backgroundColor={"#F3F3F3"}
                        value={tutorDescriptionAchievements}
                        onChange={(e) =>
                          setTutorDescriptionAchievements(e.target.value)
                        }
                        type="text"
                      />
                      <Popover placement="top-start" trigger="hover">
                        <PopoverTrigger>
                          <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                        </PopoverTrigger>
                        <PopoverContent w={"200px"}>
                          <PopoverBody>
                            {" "}
                            <Text fontSize={"70%"} textAlign={"start"}>
                              Puedes poner cualquier logro académico que tengas
                              (de preferencia algo corto)
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                  )}
                  {/* {!editable && (
                    <Text
                      display={{ base: "none", md: "flex" }}
                      fontSize={"lg"}
                      fontWeight={400}
                    >
                      {`Estudiante de ${Carrera} en ${Universidad}`}
                    </Text>
                  )} */}

                  {/* Agrega esto luego eventualmente */}
                  {/* {editable && (
                    <Box
                      display={{ base: "none", md: "flex" }}
                      width={"100%"}
                      alignItems={"center"}
                      gap={3}
                    >
                      <Box display={"flex"} flexDir={"column"}>
                        <Text>Carrera</Text>
                        <Input
                          backgroundColor={"#F3F3F3"}
                          value={Carrera}
                          onChange={(e) => setCarrera(e.target.value)}
                          type="text"
                        />
                      </Box>
                      <Box display={"flex"} width={"69%"} flexDir={"column"}>
                        <Text>Universidad</Text>
                        <SelectComponent
                          Value={"Universidad"}
                          dataOptions={UniversidadOptions}
                          setSelectedOptions={setUniversidad}
                        />
                        {newUniversidadCheck && (
                          <Box>
                            <Text>Enter your University</Text>
                            <Input
                              backgroundColor={"#F3F3F3"}
                              value={newUniversidad?.value}
                              onChange={newUniversidadHandler}
                              type="text"
                            />
                          </Box>
                        )}
                      </Box>

                      <Popover placement="top-start" trigger="hover">
                        <PopoverTrigger>
                          <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                        </PopoverTrigger>
                        <PopoverContent w={"200px"}>
                          <PopoverBody>
                            {" "}
                            <Text fontSize={"70%"} textAlign={"start"}>
                              Estudiante de Ciencia de la Computación en UTEC,
                              Perú
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                  )} */}
                </VStack>
              </Box>
              <Box
                marginTop={{ base: "5", md: "0" }}
                display={"flex"}
                alignItems={"center"}
                gap={{ base: "4", md: "5" }}
                px={{ base: "10", md: "0" }}
                py={{ base: "5", md: "0" }}
                backgroundColor={"#FFFFFF"}
                width={{ base: "100%", md: "initial" }}
              >
                {/* <Image
                  width={{ base: "3rem", md: "initial" }}
                  height={{ base: "3rem", md: "initial" }}
                  src={Images.IB42_IMAGE}
                  alt="university.png"
                />
                <Image
                  display={{ base: "none", md: "flex" }}
                  width={{ base: "3rem", md: "initial" }}
                  height={{ base: "3rem", md: "initial" }}
                  src={Images.UTEC_IMAGE}
                  alt="university.png"
                /> */}
                <Box
                  width={"100%"}
                  display={{ base: "flex", md: "none" }}
                  flexDir={"column"}
                  gap={3}
                  alignItems={"flex-start"}
                >
                  {/* {!editable && (
                    <Text fontSize={"lg"} color={"#07A58E"} fontWeight={400}>
                      {" "}
                      {bandaText}
                    </Text>
                  )} */}

                  {editable && (
                    <Box
                      width={"100%"}
                      display={{ base: "flex", md: "none" }}
                      alignItems={"center"}
                      gap={3}
                    >
                      <Input
                        backgroundColor={"#F3F3F3"}
                        value={tutorDescriptionScores}
                        onChange={(e) =>
                          setTutorDescriptionScores(e.target.value)
                        }
                      />
                      <Popover placement="top-start" trigger="hover">
                        <PopoverTrigger>
                          <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                        </PopoverTrigger>
                        <PopoverContent w={"200px"}>
                          <PopoverBody>
                            {" "}
                            <Text fontSize={"70%"} textAlign={"start"}>
                              Aqui es un buen lugar para que coloques notas en
                              IB y/o SAT
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>

                    // <Box
                    //   width={"100%"}
                    //   display={{ base: "flex", md: "none" }}
                    //   alignItems={"center"}
                    //   gap={3}
                    // >
                    //   <Input
                    //     backgroundColor={"#F3F3F3"}
                    //     value={bandaText}
                    //     onChange={(e) => setBandaText(e.target.value)}
                    //     type="text"
                    //   />
                    //   <Popover placement="top-start" trigger="hover">
                    //     <PopoverTrigger>
                    //       <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                    //     </PopoverTrigger>
                    //     <PopoverContent w={"200px"}>
                    //       <PopoverBody>
                    //         {" "}
                    //         <Text fontSize={"70%"} textAlign={"start"}>
                    //           Banda 7 en Matemáticas HL, Inglés HL
                    //         </Text>
                    //       </PopoverBody>
                    //     </PopoverContent>
                    //   </Popover>
                    // </Box>
                  )}

                  {/* {!editable && (
                    <Text fontSize={"lg"} fontWeight={400}>
                      {`Estudiante de ${Carrera} en ${Universidad}`}
                    </Text>
                  )} */}

                  {editable && (
                    <Box
                      width={"100%"}
                      display={{ base: "flex", md: "none" }}
                      alignItems={"center"}
                      gap={3}
                    >
                      <Input
                        backgroundColor={"#F3F3F3"}
                        value={tutorDescriptionAchievements}
                        onChange={(e) =>
                          setTutorDescriptionAchievements(e.target.value)
                        }
                        type="text"
                      />
                      <Popover placement="top-start" trigger="hover">
                        <PopoverTrigger>
                          <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                        </PopoverTrigger>
                        <PopoverContent w={"200px"}>
                          <PopoverBody>
                            {" "}
                            <Text fontSize={"70%"} textAlign={"start"}>
                              Puedes poner cualquier logro académico que tengas
                              (de preferencia algo corto)
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                    // <Box
                    //   width={"100%"}
                    //   display={{ base: "flex", md: "none" }}
                    //   alignItems={"flex-Start"}
                    //   flexDir={"column"}
                    //   gap={3}
                    // >
                    //   <Box
                    //     width={"100%"}
                    //     gap={2}
                    //     display={"flex"}
                    //     flexDir={"column"}
                    //   >
                    //     <Text>
                    //       Carrera{" "}
                    //       <Popover
                    //         marginLeft={2}
                    //         placement="top-start"
                    //         trigger="hover"
                    //       >
                    //         <PopoverTrigger>
                    //           <InfoOutlineIcon
                    //             boxSize={"15px"}
                    //             color={"#07A58E"}
                    //           />
                    //         </PopoverTrigger>
                    //         <PopoverContent w={"200px"}>
                    //           <PopoverBody>
                    //             {" "}
                    //             <Text fontSize={"70%"} textAlign={"start"}>
                    //               Banda 7 en Matemáticas HL, Inglés HL
                    //             </Text>
                    //           </PopoverBody>
                    //         </PopoverContent>
                    //       </Popover>
                    //     </Text>
                    //     <Input
                    //       backgroundColor={"#F3F3F3"}
                    //       value={Carrera}
                    //       onChange={(e) => setCarrera(e.target.value)}
                    //       type="email"
                    //     />
                    //   </Box>
                    //   <Box
                    //     display={"flex"}
                    //     gap={2}
                    //     width={"100%"}
                    //     flexDir={"column"}
                    //   >
                    //     <Text>
                    //       Universidad{" "}
                    //       <Popover
                    //         marginLeft={2}
                    //         placement="top-start"
                    //         trigger="hover"
                    //       >
                    //         <PopoverTrigger>
                    //           <InfoOutlineIcon
                    //             boxSize={"15px"}
                    //             color={"#07A58E"}
                    //           />
                    //         </PopoverTrigger>
                    //         <PopoverContent w={"200px"}>
                    //           <PopoverBody>
                    //             {" "}
                    //             <Text fontSize={"70%"} textAlign={"start"}>
                    //               Banda 7 en Matemáticas HL, Inglés HL
                    //             </Text>
                    //           </PopoverBody>
                    //         </PopoverContent>
                    //       </Popover>
                    //     </Text>
                    //     <SelectComponent
                    //       Value="Universidad"
                    //       dataOptions={UniversidadOptions}
                    //       setSelectedOptions={setUniversidad}
                    //     />
                    //     {newUniversidadCheck && (
                    //       <Box display={"flex"} flexDir={"column"} gap={2}>
                    //         <Text>Enter your University</Text>
                    //         <Input
                    //           backgroundColor={"#F3F3F3"}
                    //           value={newUniversidad?.value}
                    //           onChange={newUniversidadHandler}
                    //           type="text"
                    //         />
                    //       </Box>
                    //     )}
                    //   </Box>
                    // </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider
              display={{ base: "none", md: "flex" }}
              marginTop={5}
              backgroundColor={"#44444440"}
              height={0.6}
            />
            {/* bottom */}
            <Box
              display={"flex"}
              marginTop={{ base: "0", md: "5" }}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              width={"100%"}
              flexDir={{ base: "column", md: "row" }}
            >
              <Box
                width={{ base: "100%", md: "50%" }}
                display={"flex"}
                gap={3}
                flexDir={"column"}
              >
                {/* Materias que ensena */}
                <Box
                  py={{ base: "5", md: "0" }}
                  px={{ base: "10", md: "0" }}
                  backgroundColor={"#FFFFFF"}
                  marginTop={{ base: "5", md: "0" }}
                  width={{ base: "100%", md: "100%" }}
                  display={"flex"}
                  gap={4}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  <Box
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() => setDropDown(!dropDown)}
                  >
                    <Text fontSize={"2xl"} fontWeight={500}>
                      Materias que enseña
                    </Text>{" "}
                    <ChevronRightIcon
                      transform={dropDown ? "rotate(90deg)" : "rotate(0deg)"}
                      fontWeight={600}
                      fontSize={"3xl"}
                      marginTop={1}
                    />
                  </Box>
                  {!dropDown && (
                    <>
                      <Box
                        width={"100%"}
                        gap={2}
                        display={"flex"}
                        flexWrap={"wrap"}
                      >
                        {subjectList?.map((subject) => {
                          return (
                            <Image
                              width={"1.5rem"}
                              height={"1.4rem"}
                              src={Images.RESOURCE_CALULATOR}
                            />
                          );
                        })}
                      </Box>
                    </>
                  )}
                  {dropDown && (
                    <Box display={"flex"} flexWrap={"wrap"} gap={3}>
                      {subjectList?.map((subject) => {
                        return (
                          <TutorSubjectList
                            subjectId={subject}
                            key={`${subject}`}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>

                {/* Datos */}
                <Box
                  py={{ base: "5", md: "0" }}
                  px={{ base: "10", md: "0" }}
                  backgroundColor={"#FFFFFF"}
                  marginTop={{ base: "5", md: "0" }}
                  width={{ base: "100%", md: "100%" }}
                  display={"flex"}
                  gap={4}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  <Text fontSize={"2xl"} fontWeight={500}>
                    Datos
                  </Text>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    gap={3}
                  >
                    <Image
                      width={"1.4rem"}
                      height={"1.4rem"}
                      src={Images.IDIOMAS_IMAGE}
                    />
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Text>
                        {" "}
                        Idiomas{" "}
                        <Popover placement="top-start" trigger="hover">
                          <PopoverTrigger>
                            <InfoOutlineIcon
                              boxSize={"15px"}
                              color={"#07A58E"}
                            />
                          </PopoverTrigger>
                          <PopoverContent w={"200px"}>
                            <PopoverBody>
                              {" "}
                              <Text fontSize={"70%"} textAlign={"start"}>
                                Por ejemplo: Inglés (avanzado), Español (nativo)
                              </Text>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Text>
                      {/* {!editable && (
                        <Box width={"100%"} display={"flex"} flexWrap={"wrap"}>
                          {languageLevel?.map((item, index) => {
                            return <Text key={index}>{item.name},</Text>;
                          })}
                        </Box>
                      )} */}
                      {editable && (
                        <Box
                          width={"100%"}
                          display={{ base: "none", md: "flex" }}
                          alignItems={"center"}
                          gap={3}
                        >
                          <Input
                            backgroundColor={"#F3F3F3"}
                            value={tutorDescriptionLanguage}
                            onChange={(e) =>
                              setTutorDescriptionLanguage(e.target.value)
                            }
                            type="text"
                          />
                        </Box>
                      )}
                      {/** TODO: Add this to get a better idea of the languages*/}
                      {editable && (
                        <Box
                          width={"100%"}
                          display={{ base: "flex", md: "none" }}
                          alignItems={"center"}
                          gap={3}
                        >
                          <Input
                            backgroundColor={"#F3F3F3"}
                            value={tutorDescriptionLanguage}
                            onChange={(e) =>
                              setTutorDescriptionLanguage(e.target.value)
                            }
                            type="text"
                          />
                        </Box>
                        // <Box
                        //   width={"100%"}
                        //   display={"flex"}
                        //   flexDir={"column"}
                        //   gap={2}
                        // >
                        //   <Box
                        //     display={"flex"}
                        //     justifyContent={"space-between"}
                        //   >
                        //     <Text fontSize={"md"}>Selecciona idioma</Text>
                        //     <Text fontSize={"md"}>Nivel</Text>
                        //   </Box>
                        //   {languageLevel?.map((idioma, index) => {
                        //     return (
                        //       <Box
                        //         key={index}
                        //         width={"100%"}
                        //         display={"flex"}
                        //         alignItems={"center"}
                        //         gap={1.5}
                        //       >
                        //         <Select
                        //           color={MAIN_COLOR}
                        //           value={idioma.name}
                        //           placeholder="Select Idiomas"
                        //           onChange={(e) =>
                        //             handleLanguageChange(e, index)
                        //           }
                        //         >
                        //           <option value="Inglés">Inglés</option>
                        //           <option value="Francés">Francés</option>
                        //           <option value="English">English</option>
                        //         </Select>
                        //         <Select
                        //           value={idioma.level}
                        //           onChange={(e) =>
                        //             handleLanguageLevel(e, index)
                        //           }
                        //         >
                        //           <option value="Principiante">
                        //             Principiante
                        //           </option>
                        //           <option value="Intermedio">Intermedio</option>
                        //           <option value="Avanzado">Avanzado</option>
                        //         </Select>

                        //         <DeleteIcon
                        //           cursor={"pointer"}
                        //           onClick={(index) =>
                        //             handleDeleteLanguage(index)
                        //           }
                        //         />
                        //       </Box>
                        //     );
                        //   })}
                        //   <Button
                        //     backgroundColor={MAIN_COLOR}
                        //     color={"white"}
                        //     display={"flex"}
                        //     alignItems={"center"}
                        //     alignSelf={"flex-end"}
                        //     gap={1}
                        //     cursor={"pointer"}
                        //     onClick={addLanguageHanlder}
                        //   >
                        //     <AddIcon />
                        //     <Text> Añadir</Text>
                        //   </Button>
                        // </Box>
                      )}
                    </Box>
                  </Box>
                  {show_email && (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      gap={3}
                    >
                      <Image
                        src={Images.RESOURCE_MAIL}
                        width={"1.4rem"}
                        height={"1.4rem"}
                      />
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                      >
                        <Text>E-mail</Text>
                        {!editable && <Text>{email}</Text>}
                        {editable && (
                          <Input
                            backgroundColor={"#F3F3F3"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                  {/* <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    gap={3}
                  >
                    <Image
                      width={"1.4rem"}
                      height={"1.4rem"}
                      src={Images.MIPERFIL_BALLONS}
                    />
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Text>Fecha de nacimiento</Text>
                      {!editable && <Text>{birthday}</Text>}
                      {editable && (
                        <Input
                          backgroundColor={"#F3F3F3"}
                          value={birthday}
                          onChange={(e) => setBirthday(e.target.value)}
                          type="date"
                          width={"100%"}
                        />
                      )}
                    </Box>
                  </Box> */}
                </Box>
              </Box>

              <Box
                borderRadius={8}
                display={{ base: "none", md: "initial" }}
                width={"500px"}
                height={"300px"}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={embedUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <Text textAlign="center" fontSize="md">
                  Si es que quieres cambiar el video, porfavor contacta con
                  Administración.
                </Text>
              </Box>
            </Box>{" "}
            <Divider
              display={{ base: "none", md: "flex" }}
              marginTop={5}
              marginBottom={5}
              backgroundColor={"#44444440"}
              height={0.6}
            />
            <Box
              marginBottom={20}
              py={{ base: "5", md: "0" }}
              px={{ base: "10", md: "0" }}
              backgroundColor={"#FFFFFF"}
              marginTop={{ base: "5", md: "0" }}
              width={{ base: "100%", md: "50%" }}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Text fontSize={"2xl"} fontWeight={500}>
                Sobre mí{" "}
                <Popover placement="top-start" trigger="hover">
                  <PopoverTrigger>
                    <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                  </PopoverTrigger>
                  <PopoverContent w={"200px"}>
                    <PopoverBody>
                      {" "}
                      <Text fontSize={"70%"} textAlign={"start"}>
                        Aquí puedes ir en más detalle sobre lo que desees :)
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Text>

              {/* {!editable && (
                <Text fontSize={"xl"} fontWeight={300}>
                  {description}
                </Text>
              )} */}
              {editable && (
                <Box
                  width={"100%"}
                  // display={{ base: "none", md: "flex" }}
                  alignItems={"center"}
                  gap={3}
                >
                  {" "}
                  <Textarea
                    backgroundColor={"#F3F3F3"}
                    minH="150px"
                    value={tutorSelfDescription}
                    onChange={(e) => setTutorSelfDescription(e.target.value)}
                    placeholder=""
                    size={{ base: "sm", md: "md" }}
                  />
                </Box>
              )}
              <Box
                borderRadius={8}
                display={{ base: "initial", md: "none" }}
                width={"100%"}
                height={"300px"}
                alignItems="center"
                justifyContent={"center"}
                marginTop={10}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={embedUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <Text textAlign="center" fontSize="md">
                  Si es que quieres cambiar el video, porfavor contacta con
                  Administración.
                </Text>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <SaveChangesModal
        VolverHandler={VolverHandler}
        AceptarHandler={AceptarHandler}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Modal isOpen={isOpenConfirmation} onClose={onCloseConfirmation}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>
            Cambio realizado correctamente 🎉
          </ModalHeader>

          <ModalFooter display={"flex"} justifyContent={"space-evenly"}>
            <Button
              onClick={() => {
                onCloseConfirmation();
                setEditable(false);
              }}
              backgroundColor={"#BCBCBC"}
              color={"white"}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenError} onClose={onCloseError}>
        <ModalOverlay />
        <ModalContent textAlign="center">
          <ModalHeader textAlign={"center"}>
            Hubo un error haciendo sus cambios 😩
          </ModalHeader>
          <Text>Por favor intente de nuevo.</Text>
          <ModalFooter display={"flex"} justifyContent={"space-evenly"}>
            <Button
              onClick={() => {
                onCloseError();
                // setEditable(false);
              }}
              backgroundColor={"#BCBCBC"}
              color={"white"}
            >
              Volver
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default MyProfileTutor;

function SaveChangesModal({ isOpen, onClose, AceptarHandler, VolverHandler }) {
  const [isLoading, setIsloading] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>
            ¿Deseas guardar los cambios?
          </ModalHeader>

          <ModalFooter display={"flex"} justifyContent={"space-evenly"}>
            <Button
              backgroundColor={MAIN_COLOR}
              color={"white"}
              mr={3}
              isLoading={isLoading}
              onClick={async () => {
                setIsloading(true);
                await AceptarHandler();
              }}
            >
              Aceptar
            </Button>
            <Button
              onClick={VolverHandler}
              backgroundColor={"#BCBCBC"}
              color={"white"}
            >
              Volver
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
