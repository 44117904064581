import {
  Stack,
  Text,
  Divider,
  Link,
  Wrap,
  Avatar,
  AvatarBadge,
  Button,
  HStack,
  VStack,
  Box,
  Center,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  StarIcon,
} from "@chakra-ui/icons";

import "./OwnTutors.css";
import { useNavigate } from "react-router-dom";
import TutorCard from "./TutorCard/TutorCard";
import { useSelector } from "react-redux";
import { getStudents } from "../../utils/ApiConfig";

const Profesores = () => {
  const [dropDown, setDropDown] = useState(true);
  const { userInfo, isFetching } = useSelector((state) => state.user);
  const [ownTutors, setOwnTutors] = useState([]);
  const [tutorsMap, setTutorsMap] = useState({}); // Relevant for parents. tutorId -> list of students

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    async function get_info() {
      if (userInfo.role === "Student") {
        setOwnTutors(userInfo?.tutors);
      } else if (userInfo.role === "Parent") {
        if (userInfo.students) {
          let students = await getStudents({ _id: { $in: userInfo.students } });
          // Combine all the student's tutors
          let tutorsMap = {};
          let tutors = [];
          for (let student of students) {
            for (let tutorId of student.tutors) {
              if (!tutorsMap[tutorId]) {
                tutorsMap[tutorId] = [];
                tutors.push(tutorId);
              }
              tutorsMap[tutorId].push(student);
            }
          }
          setOwnTutors(tutors);
          setTutorsMap(tutorsMap);
        }
      }
    }
    get_info();
  }, [userInfo]);

  const toggleTusProfesores = () => {
    setDropDown(!dropDown);
  };
  if (userInfo.role !== "Student" && userInfo.role !== "Parent") {
    return "Esta página no está disponible.";
  }
  const MESSGAGES_PER_ROLE = {
    header: {
      Student: "Tus profesores",
      Parent: "Los profesores de tus hijos!",
    },
    no_tutors: {
      Student: "No has tomado clases aún, pero puedes empezar hoy!",
      Parent:
        "Tus hijos no han tomado ninguna clase aún, pero eso puede cambiar hoy!",
    },
  };
  return (
    <>
      <Box
        display={"flex"}
        width={"max-content"}
        cursor={"pointer"}
        gap={3}
        onClick={toggleTusProfesores}
        alignItems={"center"}
      >
        <Text id="onTop" fontSize={{ base: "2xl", md: "3xl" }} fontWeight={500}>
          {MESSGAGES_PER_ROLE.header[userInfo.role]}
        </Text>
        <ChevronRightIcon
          transform={dropDown ? "rotate(90deg)" : "rotate(0deg)"}
          fontWeight={600}
          fontSize={"3xl"}
          marginTop={1}
        />
      </Box>
      {dropDown && ownTutors.length === 0 && (
        <Text> {MESSGAGES_PER_ROLE.no_tutors[userInfo.role]}</Text>
      )}
      {dropDown &&
        ownTutors.map((tutorId, index) =>
          userInfo.role === "Student" ? (
            <TutorCard id={tutorId} key={`tutor-${tutorId}`} />
          ) : (
            <TutorCard
              id={tutorId}
              key={`tutor-${tutorId}`}
              status={
                `Tutor de ` +
                tutorsMap[tutorId].map((student) => student.name).join(", ")
              }
            />
          )
        )}
    </>
  );
};

export default Profesores;
