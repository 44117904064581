import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState, useEffect } from "react";
import { Images, getValidDriveLink } from "../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AddIcon,
  ChevronRightIcon,
  DeleteIcon,
  InfoOutlineIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { BsCamera } from "react-icons/bs";
// import TutorSubjectList from "../pages/MyProfile/TutorSubjectList";
// import { SelectComponent } from ".";
import {
  FetchUniversidad,
  getAllSchool,
  getTutorDetail,
  getFilteredSubjects,
} from "../utils/ApiConfig";
import { getYoutubeEmbedLink } from "../utils/functions";
import { MAIN_COLOR } from "../utils/constants";

const TeacherProfile = ({ tutorId }) => {
  const [tutor, setTutor] = useState({});
  const [subjects, setSubjects] = useState([]);
  const show_email = false;
  useEffect(() => {
    async function get_info() {
      let res = await getTutorDetail(tutorId);
      if (!res || !res.data) {
        return;
      }
      let foundTutor = res.data;

      setTutor(foundTutor);

      let subjectRes = await getFilteredSubjects({
        _id: { $in: foundTutor.subjects },
      });
      if (!subjectRes || !subjectRes.data) {
        return;
      }
      let foundSubjects = subjectRes.data;
      setSubjects(foundSubjects);
    }
    get_info();
  }, []);

  const [dropDown, setDropDown] = useState(false);
  // university text is the combination of Carrera, Universidad which is come from backend

  const [Carrera, setCarrera] = useState("Ciencia de la Computación ");
  const [Universidad, setUniversidad] = useState(null);
  const [description, setDescription] =
    useState(`      Lörem ipsum seng godat apregisk. Förysk ultraras. Matbil tigen har
    bloppa. Ikament monon triktigt. Fysisk cd mukbang relig läshund,
    så tredest.`);
  const [languageLevel, setLanguageLevel] = useState([
    { name: "Inglés", level: "Avanzado" },
    { name: "Francés", level: "Intermedio" },
    {
      name: "Español",
      level: "Avanzado",
    },
  ]);

  const navigate = useNavigate();

  if (!tutor._id) {
    return (
      <Text>
        No se encontró a un tutor válido. Por favor vuelva a intentarlo.
      </Text>
    );
  }
  return (
    <VStack
      // marginTop={5}
      paddingBottom={{ base: "20", md: "0" }}
      width={{ base: "100%", md: "100%" }}
      marginLeft={"auto"}
      gap={4}
      marginRight={"auto"}
      alignSelf={"center"}
    >
      {/* <Box
        width={"100%"}
        display={"flex"}
        px={{ base: "10", md: "0" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"2xl"} fontWeight={"500"}>
          Perfil del tutor
        </Text>
      </Box> */}
      <Box
        px={10}
        width={"100%"}
        boxShadow={" 0px 0px 26.7223px rgba(179, 194, 191, 0.2)"}
        minH={"70vh"}
        backgroundColor={{ base: "#F3F3F3", md: "#ffffff" }}
        p={{ base: "0", md: "10" }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
        >
          <Box
            display={"flex"}
            width={{ base: "100%", md: "initial" }}
            justifyContent={"space-between"}
            alignItems={{ base: "center" }}
            gap={6}
            py={{ base: "5", md: "0" }}
            flexDirection={{ base: "column", md: "row" }}
            height={"max-content"}
            background={{
              // base: `linear-gradient(107.45deg, #07A58E -16.34%, #0ED37B 104.33%)`,
              base: MAIN_COLOR,
              md: "none",
            }}
            position={"relative"}
            // flex={1}
          >
            <Box
              display={{ base: "flex", md: "none" }}
              width={"100%"}
              height={"100%"}
              position={"absolute"}
              backgroundImage={{
                base: `url(${require("../assets/BackgroundImage.png")})`,
                md: "none",
              }}
              backgroundPosition={"center "}
              backgroundRepeat={"repeat"}
            ></Box>
            <WrapItem>
              <Avatar
                border="3.64583px solid rgba(68, 68, 68, 0.25)"
                size="2xl"
                name={tutor.name}
                src={getValidDriveLink(tutor.imageDriveLink)}
                position="relative"
              >
                {/* <AvatarBadge boxSize=".7em" bg="green.500" /> */}
              </Avatar>
            </WrapItem>
            <VStack
              alignItems={{ base: "center", md: "flex-start" }}
              display={"flex"}
              // flex={1}
              // width="100%"
              // bgColor="yellow"
            >
              <Box
                display={"flex"}
                gap={4}
                alignItems={"center"}
                // justifyContent={"space-around"}
                // flex={1}
                // bgColor="red"
                // width={"100%"}
                // w="100%"
              >
                <Text
                  fontSize={"2xl"}
                  color={{ base: "white", md: "black" }}
                  fontWeight={500}
                  // flex={1}
                >
                  {`Tutor ${tutor?.name} ${tutor?.surname}`}{" "}
                </Text>
                {/* <Text
                  fontSize={"2xl"}
                  color={{ base: "white", md: "black" }}
                  fontWeight={500}
                >
                  {`Estado: ${tutor.status}`}{" "}
                </Text> */}

                {/* <Text display={{ base: "none", md: "flex" }}>
                  <StarIcon
                    display={{ base: "none", md: "initial" }}
                    color="#FFD800"
                  />
                  4.8(29)
                </Text> */}
              </Box>
              {/* <Text
                display={{ base: "flex", md: "none" }}
                position={"absolute"}
                top={5}
                color={"white"}
                right={10}
              >
                <StarIcon color="#FFD800" /> 4.8(29)
              </Text> */}
              {/* <Text
                fontSize={"lg"}
                color={{ base: "white", md: "black" }}
                fontWeight={400}
              >
                {`${tutor?.role} ${"Peru"}`}
              </Text> */}
              <Text
                display={{ base: "none", md: "flex" }}
                fontSize={"lg"}
                // color={"#07A58E"}
                color={MAIN_COLOR}
                fontWeight={400}
              >
                {tutor.tutorDescriptionList[0]}
              </Text>
              {/* <Box
                width={"100%"}
                display={{ base: "none", md: "flex" }}
                alignItems={"center"}
                gap={3}
              >
                <Popover placement="top-start" trigger="hover">
                  <PopoverTrigger>
                    <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                  </PopoverTrigger>
                  <PopoverContent w={"200px"}>
                    <PopoverBody>
                      {" "}
                      <Text fontSize={"70%"} textAlign={"start"}></Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box> */}
              <Text
                display={{ base: "none", md: "flex" }}
                fontSize={"lg"}
                fontWeight={400}
              >
                {tutor.tutorDescriptionList[1]}

                {/* {`Estudiante de ${Carrera} en ${Universidad}`} */}
              </Text>
            </VStack>
          </Box>
          <Box
            marginTop={{ base: "5", md: "0" }}
            display={"flex"}
            alignItems={"center"}
            gap={{ base: "4", md: "5" }}
            px={{ base: "10", md: "0" }}
            py={{ base: "5", md: "0" }}
            backgroundColor={"#FFFFFF"}
            width={{ base: "100%", md: "initial" }}
          >
            {/* <Image
              width={{ base: "3rem", md: "initial" }}
              height={{ base: "3rem", md: "initial" }}
              src={Images.IB42_IMAGE}
              alt="university.png"
            />
            <Image
              display={{ base: "none", md: "flex" }}
              width={{ base: "3rem", md: "initial" }}
              height={{ base: "3rem", md: "initial" }}
              src={Images.UTEC_IMAGE}
              alt="university.png"
            /> */}
            <Box
              width={"100%"}
              display={{ base: "flex", md: "none" }}
              flexDir={"column"}
              gap={3}
              alignItems={"flex-start"}
            >
              <Text fontSize={"lg"} color={MAIN_COLOR} fontWeight={400}>
                {tutor.tutorDescriptionList[0]}
              </Text>

              {/* <Box
                width={"100%"}
                display={{ base: "flex", md: "none" }}
                alignItems={"center"}
                gap={3}
              >
                <Popover placement="top-start" trigger="hover">
                  <PopoverTrigger>
                    <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
                  </PopoverTrigger>
                  <PopoverContent w={"200px"}>
                    <PopoverBody>
                      <Text fontSize={"70%"} textAlign={"start"}>
                        {tutor.tutorDescriptionList[0]}
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box> */}

              <Text fontSize={"lg"} fontWeight={400}>
                {tutor.tutorDescriptionList[1]}

                {/* {`Estudiante de ${Carrera} en ${Universidad}`} */}
              </Text>
            </Box>
          </Box>
        </Box>
        <Divider
          display={{ base: "none", md: "flex" }}
          marginTop={5}
          backgroundColor={"#44444440"}
          height={0.6}
        />
        {/* bottom */}
        <Box
          display={"flex"}
          marginTop={{ base: "0", md: "5" }}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          width={"100%"}
          flexDir={{ base: "column", md: "row" }}
        >
          <Box
            width={{ base: "100%", md: "50%" }}
            display={"flex"}
            gap={3}
            flexDir={"column"}
          >
            {/* Materias que ensena */}
            <Box
              py={{ base: "5", md: "0" }}
              px={{ base: "10", md: "0" }}
              backgroundColor={"#FFFFFF"}
              marginTop={{ base: "5", md: "0" }}
              width={{ base: "100%", md: "100%" }}
              display={"flex"}
              gap={4}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Box
                cursor={"pointer"}
                display={"flex"}
                alignItems={"center"}
                onClick={() => setDropDown(!dropDown)}
              >
                <Text fontSize={"2xl"} fontWeight={500}>
                  Materias que enseña
                </Text>{" "}
                <ChevronRightIcon
                  transform={dropDown ? "rotate(90deg)" : "rotate(0deg)"}
                  fontWeight={600}
                  fontSize={"3xl"}
                  marginTop={1}
                />
              </Box>
              {!dropDown && (
                <>
                  <Box
                    width={"100%"}
                    gap={2}
                    display={"flex"}
                    flexWrap={"wrap"}
                  >
                    {subjects?.map((subject) => {
                      return (
                        <Image
                          key={subject._id}
                          width={"1.5rem"}
                          height={"1.4rem"}
                          src={Images.RESOURCE_CALULATOR}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
              {dropDown && (
                <Box display={"flex"} flexWrap={"wrap"} gap={3}>
                  {subjects?.map((subject) => (
                    <Box
                      display={"flex"}
                      gap={3}
                      alignItems={"center"}
                      key={subject._id}
                    >
                      <Image
                        width={"1.4rem"}
                        height={"1.4rem"}
                        src={Images.RESOURCE_CALULATOR}
                      />
                      <Text>{subject?.name}</Text>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            {/* Datos */}
            <Box
              py={{ base: "5", md: "0" }}
              px={{ base: "10", md: "0" }}
              backgroundColor={"#FFFFFF"}
              marginTop={{ base: "5", md: "0" }}
              width={{ base: "100%", md: "100%" }}
              display={"flex"}
              gap={4}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Text fontSize={"2xl"} fontWeight={500}>
                Datos
              </Text>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"flex-start"}
                gap={3}
              >
                <Image
                  width={"1.4rem"}
                  height={"1.4rem"}
                  src={Images.IDIOMAS_IMAGE}
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  <Text> Idiomas</Text>
                  <Text>{tutor.tutorDescriptionList[3]}</Text>
                  {/* <Box width={"100%"} display={"flex"} flexWrap={"wrap"}>
                    {languageLevel?.map((item, index) => {
                      return <Text key={index}>{item.name},</Text>;
                    })}
                  </Box> */}
                </Box>
              </Box>
              {show_email && (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  gap={3}
                >
                  <Image
                    src={Images.RESOURCE_MAIL}
                    width={"1.4rem"}
                    height={"1.4rem"}
                  />
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text>E-mail</Text>
                    <Text>{tutor.email}</Text>
                  </Box>
                </Box>
              )}
              {/* <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"flex-start"}
                gap={3}
              >
                <Image
                  width={"1.4rem"}
                  height={"1.4rem"}
                  src={Images.MIPERFIL_BALLONS}
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  <Text>Fecha de nacimiento</Text>
                  <Text>{birthday}</Text>
                </Box>
              </Box> */}
            </Box>
          </Box>
          {/* <Box
            display={{ base: "none", md: "initial" }}
            as="iframe"
            src={getYoutubeEmbedLink(tutor.tutorYoutubeLink)}
            width="100%"
            sx={{
              aspectRatio: "16/9",
            }}
          /> */}

          <Box
            borderRadius={8}
            display={{ base: "none", md: "initial" }}
            width={"500px"}
            height={"300px"}
            sx={16 / 9}
          >
            <iframe
              width="100%"
              height="100%"
              // src={embedUrl}
              src={getYoutubeEmbedLink(tutor.tutorYoutubeLink)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Box>{" "}
        <Divider
          display={{ base: "none", md: "flex" }}
          marginTop={5}
          marginBottom={5}
          backgroundColor={"#44444440"}
          height={0.6}
        />
        {tutor.tutorSelfDescription && (
          <Box
            marginBottom={20}
            py={{ base: "5", md: "0" }}
            px={{ base: "10", md: "0" }}
            backgroundColor={"#FFFFFF"}
            marginTop={{ base: "5", md: "0" }}
            width={{ base: "100%", md: "50%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text fontSize={"2xl"} fontWeight={500}>
              Sobre mí
            </Text>
            <Text fontSize={"xl"} fontWeight={300}>
              {/* {description} */}
              {tutor.tutorSelfDescription}
            </Text>
          </Box>
        )}
        <Box
          display={{ base: "initial", md: "none" }}
          as="iframe"
          src={getYoutubeEmbedLink(tutor.tutorYoutubeLink)}
          width="100%"
          height={"100%"}
          // maxWidth="500px"
          sx={{
            aspectRatio: "16/9",
          }}
          borderRadius={8}
          alignItems="center"
          justifyContent={"center"}
        />
        {/* <Box
          borderRadius={8}
          display={{ base: "initial", md: "none" }}
          width={"100%"}
          height={"100%"}
          alignItems="center"
          justifyContent={"center"}
          // marginTop={10}
          sx={{
            aspectRatio: "16/9",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={getYoutubeEmbedLink(tutor.tutorYoutubeLink)}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box> */}
      </Box>
    </VStack>
  );
};

export default TeacherProfile;
