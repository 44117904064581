import React, { useEffect, useState } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { NotionRenderer } from "react-notion-x";
// import { Code } from "react-notion-x/build/third-party/code";
// import { Collection } from "react-notion-x/build/third-party/collection";
// import { Equation } from "react-notion-x/build/third-party/equation";
// import { Modal } from "react-notion-x/build/third-party/modal";
// import { Pdf } from "react-notion-x/build/third-party/pdf";

import "./NotionRendered.css";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
const NotionRendered = ({ notionId, mapPageUrl }) => {
  const [recordMap, setRecordMap] = useState({ block: {} });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchNotionData() {
      const { data } = await axios.get(
        `https://notion-api.splitbee.io/v1/page/${notionId}`
      );
      setRecordMap({ block: data });
      setLoading(false);
      console.log(data);
    }
    fetchNotionData();
  }, [notionId]);

  if (loading) {
    return (
      <VStack spacing={5}>
        <Box mt={10} width={{ base: "90%", md: "80%" }} mx={"auto"}>
          <Spinner />
        </Box>
      </VStack>
    );
  }
  return (
    <>
      <NotionRenderer
        recordMap={recordMap}
        fullPage={true}
        // darkMode={true}
        mapPageUrl={mapPageUrl}
        className="notion-renderer"
      />
    </>
  );
};

export default NotionRendered;
