import React, { useState, useEffect, useReducer } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  VStack,
  Tooltip,
  Box,
  Tag,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from "@chakra-ui/react";
import {
  ChatIcon,
  CloseIcon,
  HamburgerIcon,
  CalendarIcon,
  ExternalLinkIcon,
  CheckIcon,
  InfoOutlineIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
// import ModalRating from "../../components/ModalRating";
import RateClassModal from "./RateClassModal";
import { useNavigate } from "react-router-dom";
import CalendarClasses from "./CalendarClasses.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import SelectStudent from "./SelectStudent";
import {
  getClasses,
  getSubjectById,
  getTutorDetail,
  getStudent,
  acceptClass,
  rejectClass,
} from "../../utils/ApiConfig.js";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
  feedbackToData,
} from "../../utils/functions.js";
import {
  PENDING_CLASS_COLOR,
  PAST_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
  MAIN_COLOR,
} from "../../utils/constants";
import CancelClassModal from "./CancelClassModal";
import StarRating from "../../components/StarRating";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import SendTutorFeedbackModal from "./SendTutorFeedbackModal";
import ViewTutorFeedbackModal from "./ViewTutorFeedbackModal";

const AcceptClassModal = ({
  isOpen,
  onClose,
  subjectDescription,
  timeDescription,
  participantMessage,
  foundClass,
  onSuccess,
}) => {
  const {
    isOpen: isOpenAcceptConfirmation,
    onOpen: onOpenAcceptConfirmation,
    onClose: onCloseAcceptConfirmation,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    // await new Promise((r, rej) => setTimeout(r, 2000));
    await acceptClass(foundClass._id);
    setIsLoading(false);
    onSuccess();
    onClose();
    onOpenAcceptConfirmation();
  };
  return (
    <>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          onClick={onClose}
          backgroundColor={"rgba(65, 65, 65, 0.3)"}
        />
        <ModalContent>
          <ModalBody w={"100%"}>
            <Stack p={10} align={"center"} gap={5}>
              <Text fontSize={"120%"} fontWeight={500}>
                ¿Deseas confirmar esta clase?
              </Text>
              <Stack
                direction={{ base: "column", md: "row" }}
                align={"center"}
                gap={5}
              >
                <Avatar size={"xl"} name={participantMessage}>
                  {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                </Avatar>
                <Stack>
                  <Text fontSize={"85%"}>{subjectDescription}</Text>
                  <Text fontSize={"70%"}>{`con ${participantMessage}`}</Text>
                  <Text fontSize={"80%"} color={MAIN_COLOR}>
                    {timeDescription}
                  </Text>
                  <Text fontSize={"60%"}>
                    {foundClass.prevInfo
                      ? `Comentario del estudiante: ${foundClass.prevInfo}`
                      : `Estudiante no dejó ningún comentario.`}
                  </Text>
                </Stack>
              </Stack>
              <Stack direction gap={5}>
                <Button
                  bgColor={MAIN_COLOR}
                  color={"white"}
                  onClick={handleConfirm}
                  isLoading={isLoading}
                >
                  Sí 😎
                </Button>{" "}
                <Button onClick={onClose} isDisabled={isLoading}>
                  Volver
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenAcceptConfirmation}
        onClose={onCloseAcceptConfirmation}
      >
        <ModalOverlay
          onClick={onCloseAcceptConfirmation}
          backgroundColor={"rgba(65, 65, 65, 0.3)"}
        />
        <ModalContent p={5}>
          <ModalCloseButton />
          <ModalHeader alignSelf={"center"}>
            Se confirmó la clase 🤩 Buen trabajo!
          </ModalHeader>
          {/* <ModalBody>
            <Text textAlign="center">Buen trabajo!</Text>
          </ModalBody> */}

          <ModalFooter alignSelf={{ base: "flex-start", md: "center" }}>
            <Button onClick={onCloseAcceptConfirmation}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AcceptClassModal;
