import React, { useState, useEffect, useReducer } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  VStack,
  Tooltip,
  Box,
  Tag,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from "@chakra-ui/react";
import {
  ChatIcon,
  CloseIcon,
  HamburgerIcon,
  CalendarIcon,
  ExternalLinkIcon,
  CheckIcon,
  InfoOutlineIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
// import ModalRating from "../../components/ModalRating";
import RateClassModal from "./RateClassModal";
import { useNavigate } from "react-router-dom";
import CalendarClasses from "./CalendarClasses.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import SelectStudent from "./SelectStudent";
import {
  getClasses,
  getSubjectById,
  getTutorDetail,
  getStudent,
  acceptClass,
  rejectClass,
} from "../../utils/ApiConfig.js";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
  feedbackToData,
} from "../../utils/functions.js";
import {
  PENDING_CLASS_COLOR,
  PAST_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
} from "../../utils/constants";
import CancelClassModal from "./CancelClassModal";
import StarRating from "../../components/StarRating";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import SendTutorFeedbackModal from "./SendTutorFeedbackModal";
import ViewTutorFeedbackModal from "./ViewTutorFeedbackModal";
import AcceptClassModal from "./AcceptClassModal";
import RejectClassModal from "./RejectClassModal";

const FeedbackComponent = ({ sender, rating, text }) => {
  const [foundUser, setFoundUser] = useState({});

  useEffect(() => {
    async function get_info() {
      let res = await getStudent(sender);
      setFoundUser(res.data);
    }
    get_info();
  }, []);
  return (
    <Stack style={{ borderWidth: 2, padding: 15, borderRadius: 10 }}>
      <Text>
        Feedback de{" "}
        <Text as="span" fontWeight={"bold"}>
          {foundUser.name}
        </Text>
      </Text>
      <StarRating
        size={{ base: 4, md: 4, lg: 5 }}
        icon="star"
        scale={5}
        fillColor="gold"
        strokeColor="grey"
        rating={rating}
        setRating={(val) => {}} //static
      />
      {feedbackToData(text).length === 1
        ? feedbackToData(text).map(([question, answer]) => (
            // This is the new format. It only has one answer
            <Text fontSize="lg">{answer}</Text>
          ))
        : feedbackToData(text).map(([question, answer]) => (
            <Text>
              <Text fontSize="lg" fontWeight={"bold"}>
                {question}
              </Text>
              <Text fontSize="lg" as="span">
                {answer}
              </Text>
            </Text>
          ))}
      {}
    </Stack>
  );
};

const ClassFeedbackModal = ({ foundClass, isOpen, onOpen, onClose }) => {
  console.log(foundClass);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        onClick={onClose}
        backgroundColor={"rgba(65, 65, 65, 0.3)"}
      />
      <ModalContent p={5}>
        <ModalCloseButton />
        <ModalHeader alignSelf={"center"}>
          Número de estudiantes que dieron su feedback:{" "}
          {foundClass.individualStudentFeedback.length} de{" "}
          {foundClass.studentIds.length}
        </ModalHeader>
        <ModalBody>
          {foundClass.individualStudentFeedback.map(
            ({ sender, rating, text }) => (
              <FeedbackComponent sender={sender} rating={rating} text={text} />
            )
          )}
        </ModalBody>

        <ModalFooter alignSelf={{ base: "flex-start", md: "center" }}>
          <Button onClick={onClose}>Volver</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClassFeedbackModal;
