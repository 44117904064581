import { useEffect, useState } from "react";
import {
  Button,
  Spinner,
  Text,
  Flex,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { getClass } from "../../utils/ApiConfig";
import { extractMoreInfoFromClass, getValidDriveLink } from "../../utils";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useSelector } from "react-redux";
import ClassWithCountdown from "../Clases/ClassWithCountdown";
import RateClassModal from "../Clases/RateClassModal";
import SendTutorFeedbackModal from "../Clases/SendTutorFeedbackModal";
import { useNavigate } from "react-router-dom";
import {
  notifyAdminNoStudents,
  notifyAdminNoTutor,
} from "../../utils/ApiConfig";

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;
const SECONDS_TO_SHOW_LINK = SECONDS_PER_MINUTE * 5; //5 minutes
const SECONDS_TO_SHOW_WARNING = SECONDS_PER_MINUTE * 5; //5 minutes
const SECONDS_TO_SHOW_NOTIFY_ADMIN_BUTTON = SECONDS_PER_MINUTE * 5; //10 minutes
const SECONDS_TO_HIDE_NOTIFY_ADMIN_BUTTON = SECONDS_PER_MINUTE * 10; //15 minutes

const get_times = (diff) => {
  diff = Math.floor(diff);
  let days = Math.floor(diff / SECONDS_PER_DAY);
  diff %= SECONDS_PER_DAY;
  let hours = Math.floor(diff / SECONDS_PER_HOUR);
  diff %= SECONDS_PER_HOUR;
  let minutes = Math.floor(diff / SECONDS_PER_MINUTE);
  diff %= SECONDS_PER_MINUTE;
  let seconds = diff;
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
const pad = (n) => {
  return n.toString().padStart(2, "0");
};
const ClassPage = () => {
  const navigate = useNavigate();

  // const {userInfo} = u
  const [classId, setClassId] = useState();
  const [fullClass, setFullClass] = useState({});
  const [valid, setValid] = useState(true);
  const { userInfo } = useSelector((state) => state.user);
  const [pressedExtended, setPressedExtended] = useState(false);
  const [enableClass, setEnableClass] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [waitToEndMessage, setWaitToEndMessage] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [userHideNotify, setUserHideNotify] = useState(false);
  const [showNotifyAdmin, setShowNotifyAdmin] = useState(false);
  const [sentToAdmin, setSentToAdmin] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  async function get_latest_info(cls) {
    if (!cls?._id) {
      setValid(false);
      return;
    }
    let full = await extractMoreInfoFromClass(cls);
    setFullClass(full);
  }
  useEffect(() => {
    async function get_info() {
      let params = new URLSearchParams(window.location.search);
      let id = params.get("id");
      setClassId(id);
      let cls = await getClass(id);
      get_latest_info(cls);
    }
    get_info();
  }, []);
  useEffect(() => {
    if (!fullClass?.class) {
      return;
    }
    const countdown = setInterval(() => {
      // Setup class counters
      let now = new Date();
      let classStart = new Date(fullClass.class.start);
      let classEnd = new Date(fullClass.class.end);
      let start_diff = (now - classStart) / 1000;
      let end_diff = (classEnd - now) / 1000;
      start_diff = Math.round(start_diff);
      end_diff = Math.round(end_diff);
      if (classStart <= now && now <= classEnd) {
        // Calculate countdown
        setEnableClass(true);
        if (start_diff > SECONDS_TO_HIDE_NOTIFY_ADMIN_BUTTON) {
          setShowNotifyAdmin(false);
        } else if (start_diff > SECONDS_TO_SHOW_NOTIFY_ADMIN_BUTTON) {
          setShowNotifyAdmin(true);
        }
        if (end_diff === 0) {
          setShowFeedbackModal(true);
          if (userInfo.role === "Student" || userInfo.role === "Tutor") {
            setShowFeedbackModal(true);
          } else {
            setShowFeedbackModal(false);
          }
        }
        if (end_diff < SECONDS_TO_SHOW_WARNING) {
          let { days, hours, minutes, seconds } = get_times(end_diff);
          let wait_time =
            days > 1
              ? `${days} días`
              : days == 1
              ? `1 día`
              : `${pad(minutes)}m: ${pad(seconds)}s`;
          let message;
          if (userInfo.role === "Student") {
            message = `La clase se terminará en ${wait_time}. Para que no se cierre, pídele al tutor que la extienda.`;
          } else {
            message = `La clase se terminará en ${wait_time}`;
          }
          if (end_diff === SECONDS_TO_SHOW_WARNING - 5 && !pressedExtended) {
            alert("La clase se terminará en 5 minutos");
          }
          setWaitToEndMessage(message);
        }
      }
      if (start_diff < -SECONDS_TO_SHOW_LINK) {
        setEnableClass(false);
        setErrorMessage("Aún falta tiempo para la clase!");
      } else if (start_diff < 0) {
        let { days, hours, minutes, seconds } = get_times(-start_diff);
        let wait_time =
          days > 1
            ? `${days} días`
            : days == 1
            ? `1 día`
            : `${pad(hours)}h: ${pad(minutes)}m: ${pad(seconds)}s`;
        setErrorMessage(`Clase empezará en ${wait_time}`);
        setEnableClass(false);
      }
      if (end_diff < 0) {
        let { days, hours, minutes, seconds } = get_times(-end_diff);
        let wait_time =
          days > 1
            ? `${days} días`
            : days == 1
            ? `1 día`
            : `${pad(hours)}h: ${pad(minutes)}m: ${pad(seconds)}s`;

        // If it already ended
        // Open feedback modal
        setErrorMessage(`Esta clase ya se acabó hace ${wait_time}`);
        setEnableClass(false);
        if (userInfo.role === "Student" || userInfo.role === "Tutor") {
          setShowFeedbackModal(true);
        } else {
          setShowFeedbackModal(false);
        }
      }
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [fullClass, enableClass]);
  // useEffect(() => {
  //   if (!enableClass) {
  //     return;
  //   }
  //   if (!fullClass?.class) {
  //     return
  //   }
  //   // Record user entering the room
  //   if (fullClass.class.enteredClassroom.includes(userInfo._id)){
  //     try {
  //       let newClass = await recordEnteredClassroom(fullClass.class._id, userInfo._id);
  //       fullClass.class.enteredClassroom = newClass.enteredClassroom;
  //       setFullClass(fullClass);
  //     } catch(e){
  //       console.log(`Error`);
  //       console.log(e);
  //     }
  //   }
  // }, [enableClass]);
  if (!valid) {
    return "Link de clase inválido!";
  }
  if (!classId || !fullClass.class) {
    return "Cargando...";
  }
  if (fullClass?.class?.pending) {
    return `Esta clase no ha sido confirmada por nuestro tutor ${fullClass.info.tutorInfo.name}`;
  }
  let api;
  if (!userInfo?._id) {
    return "Buscando su usuario (tienes que haber iniciado sesión para poder entrar a la clase)";
  }
  const getUserFeedback = (user) => {
    let feedback = fullClass.class.individualStudentFeedback.filter(
      (feedback) => feedback.sender._id === user._id
    );
    if (feedback && feedback.length > 0) {
      return feedback[0];
    }
    return null;
  };
  const extendEndTime = () => {
    setPressedExtended(true);
    const copyFullClass = { ...fullClass };
    const date = new Date(copyFullClass.class.end);
    date.setMinutes(date.getMinutes() + 5); //change minutes here
    copyFullClass.class.end = date.toISOString();
    setFullClass(copyFullClass);
  };
  const finishTime = () => {
    const copyFullClass = { ...fullClass };
    const date = new Date();
    copyFullClass.class.end = date.toISOString();
    setFullClass(copyFullClass);
  };
  const getVideoCallComponent = () => {
    let use_jitsi = false;
    return (
      <>
        {use_jitsi ? (
          <JitsiMeeting
            spinner={Spinner}
            configOverwrite={{
              startWithAudioMuted: true,
            }}
            roomName={classId}
            getIFrameRef={(node) => {
              node.style.height = "1000px";
            }}
            // onApiReady={(externalApi) => {
            //   console.log("Jitsi Meet External API");
            //   console.log(externalApi);
            //   api = externalApi;
            //   api.on("videoConferenceJoined", ({ id }) => {
            //     if (userInfo.role === "Tutor") {
            //       api.executeCommand("grantModerator", id);
            //     }
            //   });
            //   api.on("readyToClose", () => {
            //     alert("Page has been closed");
            //   });
            // }}
          />
        ) : (
          <iframe
            allow="camera; microphone; display-capture; fullscreen; clipboard-read; clipboard-write; autoplay"
            // src={`https://p2p.mirotalk.com/join?room=ibmasters-${classId}$name=&audio=0&video=0&screen=0&notify=0`}
            src={`https://p2p.mirotalk.com/join/ibmasters-${classId}`}
            // src={`https://p2p.mirotalk.com/join/${classId}`}
            // style="height: 100%; width: 100%; border: 0px;"
            style={{ height: "80%", width: "100%", border: "0px" }}
          ></iframe>
        )}
      </>
    );
  };
  const handleNotifyAdminNoStudents = async () => {
    try {
      await notifyAdminNoStudents(fullClass.class._id, userInfo._id);
      setSentToAdmin(true);
    } catch (err) {
      console.log(
        `Hubo un error comunicando sobre no estudiantes: ${err.message}`
      );
      console.log(err);
    }
  };
  const handleNotifyAdminNoTutor = async () => {
    try {
      await notifyAdminNoTutor(fullClass.class._id, userInfo._id);
      setSentToAdmin(true);
    } catch (err) {
      console.log(`Hubo un error comunicando sobre no tutor: ${err.message}`);
      console.log(err);
    }
  };
  return (
    <>
      <Flex justifyContent="space-between" alignSelf={"center"} margin={5}>
        <Text fontWeight={500} textAlign={"center"}>
          {fullClass.info.subjectDescription} con{" "}
          {fullClass.info.studentsInfo
            .map((student) => student.name)
            .join(", ")}
        </Text>
        {waitToEndMessage && (
          <Text fontWeight={"bold"} fontSize="2xl" color="red">
            {waitToEndMessage}
          </Text>
        )}
        {waitToEndMessage && userInfo.role === "Tutor" && (
          <>
            <Tooltip label="Al hacer click se extenderá el final de la clase por 5 minutos.">
              <Button onClick={extendEndTime} bgColor="blue" color="white">
                Extender 5 minutos
              </Button>
            </Tooltip>
            <Tooltip label="Al hacer click se terminará la clase y tú y el estudiante podrán llenar sus reportes.">
              <Button onClick={finishTime} bgColor="black" color="white">
                Terminar clase
              </Button>
            </Tooltip>
          </>
        )}
      </Flex>

      {/* <ClassWithCountdown
        name={fullClass.info.subjectDescription}
        participantMessage={fullClass.info.participantMessage}
        schedule={fullClass.info.timeDescription}
        tutorName={fullClass.info.tutorInfo.name}
        tutorImage={getValidDriveLink(fullClass.info.tutorInfo.imageDriveLink)}
        startTime={fullClass.class.start}
        endTime={fullClass.class.end}
        classId={fullClass.class._id}
        isTutor={true}
      /> */}
      {enableClass ? (
        <>
          {!showNotifyAdmin && //Time to show TODO revert it
            !userHideNotify && //User has not yet clicked to hide it
            (userInfo.role === "Tutor" || userInfo.role === "Student") && //parents don't get to call it
            (userInfo.role === "Tutor" ? (
              <>
                {sentToAdmin ? (
                  <Text>Se envió mensaje al admin!</Text>
                ) : (
                  <Tooltip label="Al hacer click se le enviará una notificación al admin">
                    <Button
                      onClick={handleNotifyAdminNoStudents}
                      bgColor="red"
                      color="white"
                      margin={1}
                    >
                      Faltan estudiante(s) 😩
                    </Button>
                  </Tooltip>
                )}
                <Tooltip label="Dejar de ver el boton">
                  <Button
                    onClick={() => {
                      setUserHideNotify(true);
                    }}
                  >
                    Esconder
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                {sentToAdmin ? (
                  <Text>Se envió mensaje al admin!</Text>
                ) : (
                  <Tooltip label="Al hacer click se le enviará una notificación al admin">
                    <Button
                      onClick={handleNotifyAdminNoTutor}
                      bgColor="red"
                      color="white"
                      margin={1}
                    >
                      El tutor no llega 😨
                    </Button>
                  </Tooltip>
                )}
                <Tooltip label="Dejar de ver el boton">
                  <Button
                    onClick={() => {
                      setUserHideNotify(true);
                    }}
                  >
                    Esconder
                  </Button>
                </Tooltip>
              </>
            ))}

          {getVideoCallComponent()}
        </>
      ) : (
        <Text textAlign={"center"} color={"red"}>
          {errorMessage}
        </Text>
      )}
      {showFeedbackModal &&
        (userInfo.role === "Tutor" ? (
          fullClass.class.tutorFeedback ? (
            <Text textAlign={"center"}>Feedback de tutor recibido!</Text>
          ) : (
            <SendTutorFeedbackModal
              foundClass={fullClass.class}
              isOpen={true}
              onOpen={onOpen}
              onClose={onClose}
              onFeedbackSent={() => {
                navigate("/clases");
              }}
            />
          )
        ) : userInfo.role === "Student" ? (
          getUserFeedback(userInfo) ? (
            <Text textAlign={"center"}>Feedback de estudiante recibido!</Text>
          ) : (
            <RateClassModal
              startOpen={true}
              subjectInfo={fullClass.info.subjectInfo}
              tutorInfo={fullClass.info.tutorInfo}
              classInfo={fullClass.class}
              subjectDescription={fullClass.info.subjectDescription}
              timeDescription={fullClass.info.timeDescription}
              senderInfo={userInfo}
              onSubmitFeedback={() => {
                navigate("/clases");
              }}
            />
          )
        ) : (
          <Text></Text>
        ))}
    </>
  );
  return (
    <Flex flexDir="column">
      <Text fontWeight={500}>
        {fullClass.info.subjectDescription} con{" "}
        {fullClass.info.studentsInfo.map((student) => student.name).join(", ")}
      </Text>
      {/* <Button onClick={handleFinish}>Finish</Button> */}
    </Flex>
  );
};

export default ClassPage;
