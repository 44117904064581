import {
  Stack,
  // Divider,
  extendTheme,
  Image,
  Text,
  VStack,
  Flex,
  useDisclosure,
  Button,
  Input,
  Checkbox,
  InputGroup,
  InputRightElement,
  Link,
  HStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { dividerTheme } from "./../components/Divider.jsx";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import LoginModal from "./../components/LoginModal.jsx";
import google from "../assets/Group 6.png";
import { useLocation, useNavigate } from "react-router-dom";
import divide from "./../assets/Group 108.png";
import underline2 from "./../assets/Group 115.png";
import underline1 from "./../assets/Group 114.png";
// import RegisterModal from "./RegisterModal.jsx";
import { useToast } from "@chakra-ui/react";
import { sendGoogleLoginToServer } from "../utils/ApiConfig.js";
import { Spinner } from "@chakra-ui/react";
import UserInfoModal from "./../components/UserInfoModal/UserInfoModal";
import { useDispatch, useSelector } from "react-redux";
import {
  RegisterTemp,
  clearState,
  loginUser,
  loginUserGoogle,
  RegisterTempUsingGoogle,
} from "../store/userSlice.js";
import RegisterModal from "./../components/RegisterModal.jsx";
import { validateEmail } from "../utils/functions.js";
import { MAIN_COLOR } from "../utils/constants.js";
import { useGoogleLogin } from "@react-oauth/google";
import logo from "../assets/IB_MASTERS_LOGO_WHITE.png";

export const theme = extendTheme({
  components: { Divider: dividerTheme },
});

const Login = ({ userLogin }) => {
  const loginGoogleButton = useGoogleLogin({
    onSuccess: async (response) => {
      console.log(`Response from google`);
      console.log(response);
      dispatch(loginUserGoogle(response));
    },
    flow: "auth-code",
    scope: [],
  });
  const registerGoogleButton = useGoogleLogin({
    onSuccess: async (response) => {
      console.log(`Response from google`);
      console.log(response);
      dispatch(RegisterTempUsingGoogle(response));
    },
    flow: "auth-code",
    scope: [],
  });

  const location = useLocation();
  const [joinFamilyId, setJoinFamilyId] = useState("");
  const [isFromJoinLink, setIsFromJoinLink] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const join_family_id = searchParams.get("join_family_id");
    if (join_family_id) {
      setJoinFamilyId(join_family_id);
      setIsFromJoinLink(true);
    }
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenReg,
    onOpen: onOpenReg,
    onClose: onCloseReg,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const [login, setLogin] = useState(userLogin);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [family, setFamily] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [wantNews, setWantNews] = useState(false);
  // const [statePassword, setStatePassword] = useState(false);
  const onLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onRegister = useCallback(() => {
    navigate("/register");
  }, [navigate]);

  const {
    isFetching,
    isSuccess,
    isNewRegister,
    isLogged,
    userInfo,
    isError,
    errorMessage,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // console.log(error.response.data.message);
  // login Handler
  const LoginHandler = async () => {
    const Email = email.trim();
    const isEmail = validateEmail(Email);
    const Password = password.trim();

    if (!Email || !Password) {
      toast({
        title: "All field are required",
        status: "error",
        position: "top-center",
        duration: 4000,
        isClosable: true,
      });
    }
    if (!isEmail && Email !== "") {
      toast({
        title: "Please Enter a Valid Email",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }

    if (!!Email && isEmail && !!Password) {
      const loginObj = {
        email: Email,
        password: Password,
      };
      dispatch(loginUser(loginObj));

      setEmail("");
      setPassword("");
    }
  };

  useEffect(() => {
    if (isLogged) {
      if (userInfo.role === "TempUser") {
        onOpen();
        toast({
          description: "Danos un minuto para configurar tu cuenta",
          status: "success",
          position: "top-center",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Se ingresó correctamente",
          status: "success",
          position: "top-center",
          duration: 5000,
          isClosable: true,
        });
        navigate("/");
      }

      dispatch(clearState());
    }
    if (isNewRegister) {
      onOpen();
      dispatch(clearState());
    }
    if (isError) {
      console.log("error");
      toast({
        title: errorMessage,
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
      dispatch(clearState());
    }
  }, [isLogged, isError, errorMessage, isNewRegister]);

  const setJoinFamilyIdHandler = (e) => {
    console.log(e.target.value);
    setJoinFamilyId(e.target.value);
  };

  const loginButtonHandler = (event) => {
    if (event.code === "Enter" && event.keyCode === 13) {
      LoginHandler();
    }
  };

  // register handler

  const RegisterHandler = () => {
    // onOpen();
    const Email = email.trim();
    const isEmail = validateEmail(Email);
    const Password = password.trim();
    const ConfirmPassword = confirmPassword.trim();

    const isPasswordMatch = Password === ConfirmPassword ? true : false;
    if (!Email || !Password || !ConfirmPassword) {
      toast({
        title: "Todos los campos son obligatorios",
        status: "error",
        position: "top-center",
        duration: 4000,
        isClosable: true,
      });
    }
    if (!isEmail && Email !== "") {
      toast({
        title: "Porfavor entre un email valido",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!isPasswordMatch) {
      toast({
        title: "Las contraseñas no coinciden",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }

    if (!!Email && isEmail && !!Password && !!isPasswordMatch) {
      const registerObj = {
        email: Email,
        password: Password,
        joinFamilyId,
        wantNews,
      };
      console.log(registerObj);
      dispatch(RegisterTemp(registerObj));
      setEmail("");
      setJoinFamilyId("");
      setConfirmPassword("");
      setPassword("");
    }
  };

  const RegisterButtonHandler = (event) => {
    if (event.code === "Enter" && event.keyCode === 13) {
      RegisterHandler();
    }
  };

  // const onRegstrateAquClick = useCallback(() => {
  //   navigate("/register");
  // }, [navigate]);

  // const onGroupButton1Click = useCallback(() => {
  //   // Please sync "Homepage alumnos" to the project
  // }, []);

  // const onRegstrateTextClick = useCallback(() => {
  //   navigate("/register");
  // }, [navigate]);

  const onTabChangeHandler = () => {
    setLogin(!login);
    if (login === true) {
      onRegister();
    } else {
      onLogin();
    }
  };
  const goNewPassword = () => {
    navigate("/newpassword");
  };
  return (
    <Flex
      display={{ md: "block", lg: "flex" }}
      justify={"space-evenly"}
      alignItems={"center"}
    >
      <Stack
        display={{ base: "block", md: "none", lg: "block" }}
        align={"center"}
        textAlign={"center"}
      >
        <Text
          color={"black.400"}
          fontSize={{ base: 50, md: 70 }}
          fontWeight={650}
        >
          Educación
        </Text>
        <Text
          color={MAIN_COLOR}
          fontSize={{ base: 50, md: 70 }}
          fontWeight={650}
        >
          a <u>tu</u> medida
        </Text>
      </Stack>
      <Stack>
        {login ? (
          <>
            <VStack
              alignSelf={"center"}
              display={{ base: "none", md: "flex" }}
              width={{ base: "75%", md: "50%", lg: "75%" }}
              height={"50%"}
              bgColor={"#FFFF"}
              rounded={"lg"}
              boxShadow={"xl"}
              p={"10"}
              margin={"20"}
            >
              <HStack gap={4} justifyContent={"space-evenly"}>
                <Stack>
                  <Text fontSize={"125%"}>Iniciar sesión</Text>
                </Stack>
                <Stack>
                  <Text
                    cursor={"pointer"}
                    fontSize={"125%"}
                    onClick={onTabChangeHandler}
                  >
                    ¡Regístrate aquí!
                  </Text>
                </Stack>
              </HStack>
              <Image maxW={"320px"} src={underline2} />
              <Text fontSize={"23"} color={"black.400"} fontWeight={500}>
                ¡Bienvenido a{" "}
                <Text color={MAIN_COLOR} as="span">
                  IB Masters
                </Text>
                !
              </Text>
              {/* <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              /> */}
              {userLogin && (
                <Button
                  mb={10}
                  bgColor={"#2A99FF"}
                  color={"whiteAlpha.900"}
                  fontWeight={500}
                  fontSize={23}
                  p={7}
                  onClick={loginGoogleButton}
                >
                  <Image src={google} />
                  &nbsp; | Continúa con Google
                </Button>
              )}
              <Stack mt={10}></Stack>
              <Image maxW={"320px"} src={divide} />
              <Flex textAlign={"left"} display={"block"} p={5}>
                <Text fontWeight={500}>E-mail</Text>
                <Input
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  width={"270px"}
                  border={"1px solid gray"}
                  onKeyDown={loginButtonHandler}
                ></Input>
                <Text fontWeight={500} mt={3}>
                  Contraseña
                </Text>
                <PasswordInput
                  KeyDownButtonHandler={loginButtonHandler}
                  setPassword={setPassword}
                  password={password}
                />
                <Text textAlign={"right"} color={MAIN_COLOR} fontSize={12}>
                  <u>
                    <Link href="/newpassword">¿Olvidaste tu contraseña?</Link>
                  </u>
                </Text>
                <Checkbox defaultChecked fontWeight={500} mb={10} mt={10}>
                  <Text fontSize={13}>Recordarme</Text>
                </Checkbox>
              </Flex>
              <Button
                leftIcon={isFetching ? <Spinner /> : null}
                onClick={LoginHandler}
                bgColor={MAIN_COLOR}
                p={3}
                fontWeight={500}
                color={"whiteAlpha.900"}
                boxShadow={5}
              >
                Iniciar sesión
              </Button>
              {/* <Text color={"black.400"} fontSize={12}>
                ¿Aún no tienes una cuenta?
              </Text> */}
              {/* <Text color={MAIN_COLOR} fontSize={12}>
                <u>
                  <Link href="#">¿Olvidaste tu contraseña?</Link>
                </u>
              </Text> */}
            </VStack>
          </>
        ) : (
          <>
            <VStack
              alignSelf={"center"}
              display={{ base: "none", md: "flex" }}
              width={{ base: "75%", md: "50%", lg: "75%" }}
              height={"50%"}
              bgColor={"#FFFF"}
              rounded={"lg"}
              boxShadow={"xl"}
              p={"10"}
              m={"20"}
            >
              <HStack gap={4} justifyContent={"space-evenly"}>
                <Stack>
                  <Text
                    cursor={"pointer"}
                    fontSize={"125%"}
                    onClick={onTabChangeHandler}
                  >
                    Iniciar sesión
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={"125%"}>¡Regístrate aquí!</Text>
                </Stack>
              </HStack>
              <Image maxW={"320px"} src={underline1} />
              <Text fontSize={"23"} color={"black.400"} fontWeight={500}>
                ¡Bienvenido a{" "}
                <Text color={MAIN_COLOR} as="span">
                  IB Masters
                </Text>
                !
              </Text>
              {!userLogin && (
                <>
                  <Button
                    mb={10}
                    bgColor={"#2A99FF"}
                    color={"whiteAlpha.900"}
                    fontWeight={500}
                    fontSize={23}
                    p={7}
                    onClick={registerGoogleButton}
                  >
                    <Image src={google} />
                    &nbsp; | Regístrate con Google
                  </Button>
                  <Stack mt={10}></Stack>
                  <Image maxW={"320px"} src={divide} />
                </>
              )}

              <Flex textAlign={"left"} display={"block"} p={5}>
                <Text fontWeight={500}>E-mail</Text>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  width={"270px"}
                  border={"1px solid gray"}
                  onKeyDown={RegisterButtonHandler}
                ></Input>
                <Text fontWeight={500} mt={3}>
                  Contraseña
                </Text>
                <PasswordInput
                  KeyDownButtonHandler={RegisterButtonHandler}
                  setPassword={setPassword}
                  password={password}
                />
                <Stack maxW={"270px"}>
                  <Text textAlign={"start"} color={"#6C6C6C"} fontSize={8}>
                    Crea una contraseña segura usando al menos 8 caracteres,
                    mayúsculas y símbolos.
                  </Text>
                </Stack>
                <Text fontWeight={500} mt={3}>
                  Confirmar contraseña
                </Text>
                <PasswordInput
                  KeyDownButtonHandler={RegisterButtonHandler}
                  setPassword={setConfirmPassword}
                  password={confirmPassword}
                />
                <Text fontWeight={500} mt={3}>
                  Código de familia {!isFromJoinLink && `(Opcional)`}
                </Text>
                <Input
                  disabled={isFromJoinLink}
                  type="text"
                  onKeyDown={RegisterButtonHandler}
                  value={joinFamilyId}
                  onChange={setJoinFamilyIdHandler}
                  placeholder="Código (Opcional)"
                  width={"270px"}
                  border={"1px solid gray"}
                ></Input>
                {/* {statePassword ? (
                  <>
                    <Text fontSize={"60%"} color={"red"}>
                      Password doesn't match
                    </Text>
                  </>
                ) : (
                  <></>
                )} */}
                <Stack maxW={"270px"}>
                  <Checkbox defaultChecked fontWeight={500} mt={5}>
                    <Text fontSize={13}>Acepto los términos y condiciones</Text>
                  </Checkbox>
                  <Checkbox
                    value={wantNews}
                    onChange={() => setWantNews(!wantNews)}
                    fontWeight={500}
                    mb={5}
                  >
                    <Text fontSize={13}>
                      Quiero recibir promociones y novedades sobre IB Masters
                    </Text>
                  </Checkbox>
                </Stack>
              </Flex>

              <Button
                leftIcon={isFetching ? <Spinner /> : null}
                onClick={RegisterHandler}
                bgColor={MAIN_COLOR}
                p={3}
                fontWeight={500}
                color={"whiteAlpha.900"}
                boxShadow={5}
                // disabled={statePassword}
              >
                Registarme
              </Button>
            </VStack>
          </>
        )}

        <VStack
          display={{ base: "flex", md: "none" }}
          alignSelf={"center"}
          w={"80vw"}
          bgColor={"#FCFCFC"}
          rounded={"lg"}
          boxShadow={"lg"}
          p={"10"}
          m={"20"}
        >
          <Text fontSize={"15"} color={"black.400"} fontWeight={500}>
            ¡Bienvenido a{" "}
            <Text color={MAIN_COLOR} as="span">
              IB Masters
            </Text>
            !
          </Text>
          {/* <Button
            mb={10}
            bgColor={"#2A99FF"}
            color={"whiteAlpha.900"}
            fontWeight={500}
            fontSize={15}
            p={3}
          >
            <Image src={google} w={"20px"} />
            &nbsp; | Continúa con Google
          </Button> */}
          <div
            style={{
              borderTop: "1px solid gray",
              paddingLeft: "10em",
              marginLeft: 20,
              marginRight: 20,
            }}
          ></div>
          {/* <HStack gap={4} justifyContent={'space-evenly'}> 
                          <Stack> 
                          <Text fontSize={'100%'}>Iniciar sesión</Text>
                          </Stack>
                          <Stack>
                          <Text 
                            cursor={'pointer'}
                            fontSize={'100%'}
                            // onClick={onRegstrateAquClick}
                            >
                            ¡Regístrate aquí!</Text>
                            </Stack>
                        </HStack>
                        <Image maxW={'280px'} src={underline2} />                     */}
          <HStack>
            <LoginModal />
            <RegisterModal
              isOpen={isOpenReg}
              onClose={onCloseReg}
              onOpen={onOpenReg}
            />
            <UserInfoModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          </HStack>
        </VStack>

        {/* <VStack display={{base: 'none', md: 'flex', lg:'none'}} width={'90vw'} height={'60vh'} bgColor={'#FCFCFC'} rounded={'lg'} boxShadow={'lg'} p={'10'} m={'20'}>
                        <HStack gap={4} justifyContent={'space-evenly'}> 
                          <Stack> 
                          <Text fontSize={'125%'}>Iniciar sesión</Text>
                          </Stack>
                          <Stack>
                          <Text 
                            cursor={'pointer'}
                            fontSize={'125%'}
                            onClick={onRegstrateAquClick}>
                            ¡Regístrate aquí!</Text>
                            </Stack>
                        </HStack>
                        <Image maxW={'320px'} src={underline2} />
                      <HStack alignSelf={'center'} justifyContent={'space-around'}>
                          <VStack>
                            <Text fontSize={'23'} color={'black.400'} fontWeight={500}>¡Bienvenido a IB Masters!</Text>
                            <Button mb={10} bgColor={'#2A99FF'} color={'whiteAlpha.900'} fontWeight={500} fontSize={23} p={7}>
                                <Image src={google} />
                                &nbsp; | Continúa con Google</Button>
                          </VStack>      

                          <Divider orientation="vertical" />
                          
                          <VStack>
                            <Flex textAlign={'left'} display={'block'} p={5}>
                                <Text fontWeight={500}>E-mail</Text>
                                <Input placeholder='Your email' width={'270px'} border={'1px solid gray'}></Input>
                                <Text fontWeight={500}>Contraseña</Text>
                                <PasswordInput />
                                <Text textAlign={'right'} color={MAIN_COLOR} fontSize={12}><u><Link href='#'>¿Olvidaste tu contraseña?</Link></u></Text>
                                <Checkbox defaultChecked fontWeight={500}>Recordarme</Checkbox>
                            </Flex>
                            
                            <Button bgColor={MAIN_COLOR} p={3} fontWeight={500} color={'whiteAlpha.900'} boxShadow={5}>Iniciar sesión</Button>
                            <Text color={'black.400'} fontSize={12}>¿Aún no tienes una cuenta?</Text>
                            <Text color={MAIN_COLOR} fontSize={12}><u><Link href='#'>¿Olvidaste tu contraseña?</Link></u></Text>
                          </VStack>
                      </HStack>
                    </VStack> */}
      </Stack>
    </Flex>
  );
};

function PasswordInput({ setPassword, KeyDownButtonHandler, password }) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        onKeyDown={KeyDownButtonHandler}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        w={"270px"}
        pr="4.5rem"
        type={show ? "text" : "password"}
        placeholder="Enter password"
        border={"1px solid gray"}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}

function PasswordSmInput() {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <VStack display={"block"}>
      <InputGroup size="md">
        <Input
          fontSize={9}
          pr="4.5rem"
          type={show ? "text" : "password"}
          border={"1px solid gray"}
        />
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
      </InputGroup>
    </VStack>
  );
}

export default Login;
