import React, { useState, useEffect } from "react";
import {
  Button,
  Text,
  Flex,
  Stack,
  FormLabel,
  Input,
  VStack,
  InputGroup,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import { MAIN_COLOR, LIGHT_GREEN } from "../utils/constants";
import { sendResetPasswordEmail, resetPassword } from "../utils/ApiConfig";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const SendEmail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [sent, setSent] = useState(false);
  const handleSend = async () => {
    setLoading(true);
    try {
      await sendResetPasswordEmail(email);
    } catch (error) {
      setError(error?.response?.data?.message);
    }
    setSent(true);
    setLoading(false);
  };
  return (
    <Flex flex={1} display={"flex"} justifyContent={"center"} marginTop={10}>
      <VStack
        width={"60%"}
        borderWidth={2}
        p={10}
        borderRadius={10}
        bgColor="white"
      >
        {!sent ? (
          <>
            <FormLabel>Escribe el email de tu cuenta</FormLabel>
            <Input
              w={"70%"}
              value={email}
              placeholder={"raul@gmail.com"}
              isDisabled={loading}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Button
              isLoading={loading}
              onClick={handleSend}
              bgColor={MAIN_COLOR}
              color="white"
            >
              Enviar link para nueva contraseña
            </Button>
          </>
        ) : error ? (
          <Text>Hubo un error: {error}</Text>
        ) : (
          <Text>
            Se envió un email a <Text fontWeight="bold">{email}</Text>
          </Text>
        )}
      </VStack>
    </Flex>
  );
};

function PasswordSmInput({ setPassword, password }) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <VStack>
      <InputGroup size="lg">
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder=""
          pr="4.5rem"
          type={show ? "text" : "password"}
          //   border={"1px solid gray"}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
}
const NewPassword = ({ token }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const handleNewPassword = async () => {
    setLoading(true);
    try {
      await resetPassword(token, password);
      await new Promise((resolve, reject) => setTimeout(resolve, 1000)); //To give a visual feedback (loading)
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.message);
    }
    setLoading(false);
  };
  return (
    <Flex flex={1} display={"flex"} justifyContent={"center"} marginTop={10}>
      <VStack
        width={"60%"}
        borderWidth={2}
        p={10}
        borderRadius={10}
        bgColor="white"
      >
        {success ? (
          <Text>
            Se cambió su contraseña! Ahora puede{" "}
            <Link
              as="span"
              onClick={() => {
                navigate("/login");
              }}
              color={MAIN_COLOR}
            >
              iniciar sesión nuevamente
            </Link>
          </Text>
        ) : (
          <>
            <FormLabel>Escribe tu nueva contraseña</FormLabel>
            <PasswordSmInput password={password} setPassword={setPassword} />
            <FormLabel>Confirma la contraseña</FormLabel>
            <PasswordSmInput
              password={confirmPassword}
              setPassword={setConfirmPassword}
            />
            {confirmPassword && password !== confirmPassword && (
              <Text color="red">Contraseñas no coinciden!</Text>
            )}
            {error && <Text color="red">Hubo un error: {error}</Text>}
            <Button
              isLoading={loading}
              isDisabled={!password || password != confirmPassword}
              color={"white"}
              bgColor={LIGHT_GREEN}
              onClick={handleNewPassword}
            >
              Confirmar nueva contraseña!
            </Button>
          </>
        )}
      </VStack>
    </Flex>
  );
};
const ChangePassword = () => {
  const [token, setToken] = useState("");
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let tok = params.get("token");
    if (tok) {
      setToken(tok);
    }
  }, []);
  return !token ? <SendEmail /> : <NewPassword token={token} />;
};

export default ChangePassword;
