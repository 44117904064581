import { Avatar, Box, Spinner, Text, WrapItem } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getParent, getStudent } from "../../../utils/ApiConfig";

const FamilyMember = ({ FamilyMembers }) => {
  const [getFamilyList, setFamilyList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = FamilyMembers.map(async (item) => {
          if (item.role === "Student") {
            const response = await getStudent(item.studentId);
            return {
              name: response.data.name,
              surname: response.data.surname,
              role: "Estudiante",
            };
          } else {
            const response = await getParent(item.parentId);
            return {
              name: response.data.name,
              surname: response.data.surname,
              role: "Padre de familia",
            };
          }
        });
        const allMembers = await Promise.all(promises);
        setFamilyList(allMembers);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [FamilyMembers]);
  console.log(getFamilyList);
  if (!getFamilyList.length) {
    return <Spinner />;
  }
  return (
    <>
      {getFamilyList?.map((item, index) => {
        return (
          <Box key={`item-${index}`} display={"flex"} gap={"5"}>
            <WrapItem>
              <Avatar
                size={"md"}
                name={`${item.name} ${item.surname}`}
                backgroundColor={"gray.300"}
              />
            </WrapItem>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Text fontSize={"lg"} fontWeight={500}>
                {`${item.name} ${item.surname}`}
              </Text>
              <Text fontSize={"md"}>{item.role}</Text>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default FamilyMember;
