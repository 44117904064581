import React, { useEffect, useState } from "react";
import {
  Stack,
  Text,
  ChakraProvider,
  Divider,
  HStack,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Center,
  Container,
  Flex,
} from "@chakra-ui/react";

import { useMediaQuery } from "@chakra-ui/react";

import "./SearchTutors.css";
import SelectedComponent from "../../components/SelectComponent/SelectComponent.jsx";
import {
  getSubjectById,
  getSubjects,
  createNewClass,
  getTutorDetail,
  getTutors,
  getFilteredSubjects,
} from "../../utils/ApiConfig.js";

import TutorCard from "./TutorCard/TutorCard";
import { useSelector } from "react-redux";

const ReserveClass = () => {
  const { userInfo } = useSelector((state) => state.user);
  const [width] = useMediaQuery("(max-width: 650px)");
  const [loading, setLoading] = useState(true);
  const [allSubjects, setAllSubjects] = useState([]);
  const [allTutors, setAllTutors] = useState([]);
  const [validTutors, setValidTutors] = useState([]);

  const [subjectId, setSubjectId] = useState();

  useEffect(() => {
    // Get all the info
    const getInfo = async () => {
      let subjectRes = await getFilteredSubjects({ status: "Activo" });
      let tutorRes = await getTutors({ status: "Activo" });

      setAllTutors(tutorRes.data);
      setAllSubjects(subjectRes.data);

      setLoading(false);
    };
    getInfo();
  }, []);
  useEffect(() => {
    if (subjectId) {
      let valid = allTutors.filter((tutor) =>
        tutor.subjects.includes(subjectId)
      );
      console.log("valid tutors:");
      console.log(valid);
      setValidTutors(valid);
    }
  }, [subjectId]);

  if (loading) {
    return <Text>Cargando...</Text>;
  }
  if (!userInfo) {
    return <Text>Cargando usuario...</Text>;
  }
  const ROLE_TO_MESSAGE = {
    learn: {
      Student: "Hoy quiero aprender 🤓",
      Parent: "Hoy quiero que mi hijo/a aprenda",
    },
  };
  const SPECIALIZED_TYPES = ["IB", "SAT", "Olimpiadas"];
  const isSpecialized = (subject) => {
    return SPECIALIZED_TYPES.includes(subject.service);
  };
  return (
    <>
      <ChakraProvider>
        <Stack
          // p={10}
          // alignItems={"flex-start"}
          // width={{ base: "90%", md: "80%" }}
          // marginLeft={"auto"}
          // marginRight={"auto"}
          marginBottom={5}
        >
          <Text mt={10} fontSize={{ base: "xl", md: "3xl" }} fontWeight={500}>
            Nuestros profesores
          </Text>
          <HStack
            px={5}
            py={5}
            width={"100%"}
            backgroundColor={"#ffffff"}
            boxShadow={"0px 0px 26.7223px rgba(179, 194, 191, 0.2)"}
            display={"flex"}
            flexDir={{ base: "column", md: "row" }}
            gap={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* <Center
              alignSelf={"center"}
              display={{ base: "none", md: "flex" }}
              height="80px"
            >
              <Divider orientation="vertical" />
            </Center> */}
            {allSubjects.length > 0 && (
              <Box
                width={{ base: "100%", md: "80%" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                // flexDir={"column"}
              >
                <Text textAlign="center" marginRight={"5"}>
                  {ROLE_TO_MESSAGE.learn[userInfo.role]}
                </Text>
                <Flex flexDir="column">
                  <SelectedComponent
                    loading={loading}
                    // value={valueSubject}?]
                    dataOptions={allSubjects.map((x) => ({
                      label: `${x.name} ${isSpecialized(x) ? "*" : ""}`,
                      value: x._id,
                    }))}
                    setSelectedOptions={(opt) => {
                      setSubjectId(opt.value);
                    }}
                    placeholder={"Seleccione un curso"}
                  />
                  <Text fontSize="80%">
                    * significa que el curso es una{" "}
                    <Text as="i" textStyle={"italic"}>
                      Tutoría Especializada
                    </Text>
                  </Text>
                </Flex>
              </Box>
            )}
            {/* <Center
              alignSelf={"center"}
              display={{ base: "none", md: "flex" }}
              height="80px"
            >
              <Divider orientation="vertical" />
            </Center> */}
          </HStack>
          {subjectId && (
            <>
              <Text fontSize={"100%"} fontWeight={700}>
                {validTutors.length === 0
                  ? "No se encontró tutores para este curso. Por favor intente otro."
                  : validTutors.length === 1
                  ? "Este tutor es perfecto para ti!"
                  : "Estos tutores son perfectos para ti..."}
              </Text>
              {validTutors.map((tutor) => (
                <TutorCard key={tutor._id} id={tutor._id} hideStar={true} />
              ))}
            </>
          )}
        </Stack>
      </ChakraProvider>
    </>
  );
};

export default ReserveClass;
