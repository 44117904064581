import { Stack, Text, Divider, useMediaQuery, Spinner } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import MonthPayInfo from "../Teacher/MonthPayInfo";
import { useSelector } from "react-redux";

const Historial = () => {
  const { userInfo } = useSelector((state) => state.user);
  const [allMonths, setAllMonths] = useState([]);
  useEffect(() => {
    if (!userInfo) {
      return;
    }
    console.log(new Date(userInfo.created));
    let createdYear = new Date(userInfo.created).getFullYear();
    let createdMonth = new Date(userInfo.created).getMonth();
    let todayYear = new Date().getFullYear();
    let todayMonth = new Date().getMonth();

    let currYear = todayYear;
    let currMonth = todayMonth;
    let res = [];
    //Adding all months the tutors has existed
    while (true) {
      res.push([currYear, currMonth]);
      if (currYear === createdYear && currMonth === createdMonth) {
        break;
      }
      if (currMonth > 0) {
        currMonth -= 1;
      } else {
        // January
        currYear -= 1;
        currMonth = 11; //december
      }
    }
    setAllMonths(res);
  }, [userInfo]);

  const [media] = useMediaQuery("(max-width: 767px)");
  if (!userInfo) {
    return (
      <Text>
        Cargando <Spinner />
      </Text>
    );
  }
  return (
    <>
      <Stack p={10} w={{ base: "100vw", md: "70vw", lg: "70vw" }} h={"100%"}>
        <Text
          textAlign={{ base: "center", md: "start" }}
          fontSize={"200%"}
          fontWeight={700}
        >
          Historial
        </Text>
        <Stack gap={3}>
          {allMonths.map((x) => (
            <MonthPayInfo key={`${x[0]}-${x[1]}`} year={x[0]} month={x[1]} />
          ))}
          {/* <MonthPayInfo year={2023} month={1} />
          <MonthPayInfo year={2023} month={2} />
          <Text>Marzo 2023</Text>
          <Stack px={3}>
            <Stack direction={"row"} gap={5}>
              <Text fontSize={"85%"}>Clases pendientes:</Text>
              <Text fontSize={"85%"}>0</Text>
            </Stack>
            <Stack direction={"row"} gap={5}>
              <Text fontSize={"85%"}>Reportes pendientes:</Text>
              <Text fontSize={"85%"}>0</Text>
            </Stack>
            <Stack direction={"row"} gap={5}>
              <Text fontSize={"85%"}>Horas programadas:</Text>
              <Text fontSize={"85%"}>0</Text>
            </Stack>
            <Stack direction={"row"} gap={5}>
              <Text fontSize={"85%"}>Pago previsto:</Text>
              <Text fontSize={"85%"}>S/0.00</Text>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            flexWrap={"wrap"}
            gap={{ base: 3, md: 10 }}
            direction={{ base: "column", md: "row" }}
          >
            <Stack>
              <Text>Febrero 2023</Text>
              <Stack px={3}>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Clases pendientes:</Text>
                  <Text fontSize={"85%"}>0</Text>
                </Stack>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Reportes pendientes:</Text>
                  <Text fontSize={"85%"}>0</Text>
                </Stack>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Horas programadas:</Text>
                  <Text fontSize={"85%"}>0</Text>
                </Stack>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Pago previsto:</Text>
                  <Text fontSize={"85%"}>S/0.00</Text>
                </Stack>
              </Stack>
            </Stack>
            {media ? (
              <>
                <Divider />
              </>
            ) : (
              <></>
            )}
            <Stack>
              <Text>Enero 2023</Text>
              <Stack px={3}>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Clases pendientes:</Text>
                  <Text fontSize={"85%"}>0</Text>
                </Stack>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Reportes pendientes:</Text>
                  <Text fontSize={"85%"}>0</Text>
                </Stack>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Horas programadas:</Text>
                  <Text fontSize={"85%"}>0</Text>
                </Stack>
                <Stack direction={"row"} gap={5}>
                  <Text fontSize={"85%"}>Pago previsto:</Text>
                  <Text fontSize={"85%"}>S/0.00</Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack> */}
        </Stack>
        <Divider size="10" />
        <Text>Tu cuenta se creó aquí</Text>
      </Stack>
    </>
  );
};

export default Historial;
